import { useState } from "react";
import { useEffect } from "react";
// import Categories from "../../pages/gigs/categories";
import "./recommendedGigs.css";
// import { Link } from "react-router-dom";
// import { FaStar } from "react-icons/fa";
import RenderThumbnail from "../../gig/thumbnail/getThumbnail";


function RecommendedGigs({ gigs, header, empty }) {
    const [recommendation, setRecommendation] = useState([]);

    useEffect(() => {
        setRecommendation(
            gigs
        )
    }, [gigs]);

    return (
        <div className="recommended-gigs-wrapper">
            <h1 className="recommended-gig-header">{ header }</h1>
            <section className="recommendation-section-wrapper">
                {
                    (recommendation && recommendation.length > 0) ?
                        <div className="recommended-section">
                            <div className="recommended-setion-div">
                                {/* <Slider minWidth={250}> */}
                                {
                                    recommendation && recommendation.map((data, index) =>
                                        <RenderThumbnail
                                            key={index}
                                            data={data}
                                        />
                                        // <Link key={index} to={data.gigLink} className="recommended-section-link">
                                        //     <div className="gig-thumbnail-recommendation" key={index}>
                                        //         <img className="gig-thumbnail-img" src={data.ProfilePic} alt="gig-thumbnail-img" />
                                        //         <h5>{data.Title}</h5>
                                        //         <div className="gig-thumbnail-info-section">
                                        //             <section className="gig-thumbnail-user-section">
                                        //                 <img className="gig-consultant-pic" src={data?.CProfilePic} alt="consultant-profile-pic" />
                                        //                 <p className='gig-profile-name'>{data?.CName}</p>
                                        //             </section>
                                        //             <section className="gig-thumbnail-rating-section">
                                        //                 <p className='gig-rating-p'>{data?.Rating}</p>
                                        //                 {/* <p>&#11088;</p> */}
                                        //                 <FaStar style={{color: '#faaf00'}}/>
                                        //             </section>
                                        //         </div>
                                        //     </div>
                                        // </Link>
                                    )
                                }
                            </div>
                            <hr />
                        </div>
                        : <div className="empty-recommendation-container">
                            <img src={empty} />
                            <p>No Liked Gigs.</p>
                        </div>
                }
            </section>
        </div>
    );
}

export default RecommendedGigs;