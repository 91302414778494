import "./popup.css";
const { useEffect, useRef, useState } = require("react")

function Popup(props) {
    const popupRef = useRef();
    const [opened, setOpened] = useState(false);

    useEffect(() => {
        setOpened(props.isOpen);
        if (props.isOpen) {
            setTimeout(
                () => document.addEventListener("click", addListener)
                , 10);
        }
        else {
            removeListener();
        }
    }, [props]);

    function addListener(e) {
        if (!popupRef?.current?.contains(e.target)) {
            // setOpened(false);
            if (props.onClose)
                props.onClose();
            removeListener();
        }
        else {
            // setOpened(true);
        }
    }

    function removeListener() {
        document.removeEventListener("click", addListener);
    }

    return (
        <div ref={popupRef} className={"popup " + props?.className}>
            {(!props?.hideElements || opened) && props.children}
        </div>)

}

export default Popup;