import { GoogleLogin, GoogleLogout } from 'react-google-login';

const clientId = "285858835730-tksfurle24ouvjdqcmb142t4jvt1rn9g.apps.googleusercontent.com"

function Login() {
    function onSuccess(response) {
        console.log("login Success", response);
    }

    function onFailure(response) {
        console.log("Login Failed", response);
    }

    return (
        <div>
            <GoogleLogin 
                clientId = {clientId}
                buttonText='Login'
                onSuccess={onSuccess}
                onFailure = {onFailure}
                cookiePolicy={'single_host_origin'}
                isSignedIn={true}
            />
        </div>
    )
}

function Logout() {
    function onSuccess(response) {
        console.log("logout Success", response);
    }

    function onFailure(response) {
        console.log("Logout Failed", response);
    }

    return (
        <div>
            <GoogleLogout 
                clientId = {clientId}
                buttonText='Logout'
                onSuccess={onSuccess}
            />
        </div>
    )
}

const GLoginOut = () => {
    return <div>
        <Login></Login>
        <Logout></Logout>
    </div>
}

export default GLoginOut;