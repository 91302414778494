// import { useRef } from "react"
import SetupPage from "./components/setupPage";
import { useState, useEffect } from "react";
import Dropdown from 'react-dropdown';

// import KeywordInput from "../../keywordsContainer/keywordContainer";
// import KeywordInput from '../../../components/keywordsContainer/keywordContainer';
import { useData } from "../../../DataContext/DataContext";


export default function GigSetupFirstPage(props) {
    // const [title, setTitle] = useState(props.data.Title || '');
    const { categories } = useData();

    const [categoriesList, setCategoriesList] = useState([Object.keys(categories)]);
    const [tempCategories, setTempCategories] = useState([null, null, null]);

    const [FirstPageList, setFirstPageList] = useState({
        Title: props.data.Title,
        EnterpriseID: props.data.EnterpriseID,
        CategoryID: props.data.CategoryID,
        Category1: props.data.Category1,
        Category2: props.data.Category2,
        Category3: props.data.Category3
        // keywords: props.data.keywords || [],
    });

    useEffect(() => {
        // setTitle(props.data.Title || '');
        setFirstPageList({
            Title: props.data.Title,
            CategoriesID: props.data.CategoriesID,
            EnterpriseID: props.data.EnterpriseID
        })
        // if data was sent set them
        if (props.data.category) {
            handleTitleChange({ target: { value: props.data.title } });
            handleCategoriesInputChange({ value: props.data.category[0] }, 0);
            handleCategoriesInputChange({ value: props.data.category[1] }, 1);
            handleCategoriesInputChange({ value: props.data.category[2] }, 2);
        }
        const tempList = [Object.keys(categories)];
        if (props.data.Category2) {

            tempList.push(Object.keys(categories[props.data.Category1]));
            if (props.data.Category3) {
                tempList.push(Object.keys(categories[props.data.Category1][props.data.Category2]));
            }
        }
        setTempCategories([props.data.Category1, props.data.Category2, props.data.Category3]);
        
        setCategoriesList(tempList);
    }, [props.data]);

    function onTypeChange(e) {
        setFirstPageList({
            ...FirstPageList,
            EnterpriseID: e.target.value
        })
    }

    // function keywordChanged(keywords) {
    //     FirstPageList.keywords = keywords;
    // }

    async function handleTitleChange(event) {
        setFirstPageList({
            ...FirstPageList,
            Title: event.target.value
        })
    }

    async function handleCategoriesInputChange(event, depth) {
        var value = event.value;
        const temp = [...tempCategories];
        temp[depth] = value;

        for (let i=depth+1; i<3; i++) temp[i] = null;
        let tempVal = categories;
        for (let i=0; i<=depth; i++) tempVal = tempVal[temp[i]];
        
        // if leaf node
        if (typeof(tempVal) === 'number') {
            setFirstPageList({
                ...FirstPageList,
                CategoryID: tempVal,
                Category1: temp[0],
                Category2: temp[1],
                Category3: temp[2],
            })
        }

        if (depth === 0) {
            // FirstPageList.category[0] = value;
            // FirstPageList.category[1] = null;
            // FirstPageList.category[2] = null;

            setTempCategories([value, null, null]);

            setCategoriesList([
                Object.keys(categories),
                Object.keys(categories[value]),
            ])
        }
        else if (depth === 1) {
            // FirstPageList.category[1] = value;
            // FirstPageList.category[2] = null;
            let tmp = categories[tempCategories[0]];
            if (typeof(tmp[value])==='object') {
                setCategoriesList([
                    Object.keys(categories),
                    Object.keys(tmp),
                    Object.keys(categories[tempCategories[0]][value]),
                ])
                setTempCategories([tempCategories[0], value, null])
            }
            else {
                setCategoriesList([
                    Object.keys(categories),
                    Object.keys(tmp),
                ])
            }
        }
    }

    function nextPage(e) {
        e.preventDefault();
        // if (FirstPageList.Title !== "" && FirstPageList.category[0] && FirstPageList.keywords.length > 0) {
        if (FirstPageList.Title !== "") {
            props.nextPage(e, FirstPageList);
        }
        else {
            alert("Please fill all the fields");
        }
    }

    return (
        <SetupPage next={nextPage} nextText={"Next"}>
            <div className="gig-form-row">
                <label>Gig Title</label>
                <p>Make sure it stands out from the crowd!</p>
                <input
                    className="gig-form-title"
                    value={FirstPageList.Title || ""}
                    name="title"
                    onInput={handleTitleChange}
                    required
                />
            </div>
            {
                props.consData[0]?.EName && 
                <div className="gig-form-row gig-type-selection">
                    <p>Which type of gig are you making</p>
                    <label>
                        <input 
                            type="radio"
                            name="owner-type-selection"
                            value='NULL'
                            onChange={ onTypeChange }
                            checked={ !FirstPageList.EnterpriseID || FirstPageList.EnterpriseID === "NULL" }
                        />
                        Personal
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="owner-type-selection"
                            value={ props.consData[0].EnterpriseID }
                            onChange={ onTypeChange }
                            checked={ FirstPageList.EnterpriseID && FirstPageList.EnterpriseID!=="NULL" }
                        />
                        { props.consData[0].EName }
                    </label>
                </div>
            }
            <div className="gig-form-row categories">
                <label>Category</label>
                <p>Choose the best category and sub-category most suitable with your gig.</p>
                {categoriesList && categoriesList.map((list, depth) =>
                    <Dropdown className="gig-form-row-dropdown" key={depth} value={tempCategories[depth] || "Select one"}
                        options={list}
                        name={`CategoriesDepth${depth + 1}`}
                        onChange={(e) => handleCategoriesInputChange(e, depth)}
                    />
                )}
            </div>
            {/* <div className="gig-form-row">
                <label>Keywords</label>
                <p>Suitable keywords will help your gig show up first on search.</p> <br></br>
                <KeywordInput keywords={props.data.keywords} onInput={keywordChanged} />
            </div> */}
        </SetupPage>
    )
}