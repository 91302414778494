import React from 'react'
import rightArrow from '../../../assets/right-arrow.svg'
import leftArrow from '../../../assets/left-arrow.svg'

const SixthStep = ({ formData, setFormData, nextStep, prevStep }) => {
    return (
        <>
            <h3 className='step-header'> Currently, I think that :</h3>
            <div className='form-container'>
                <div>
                    <input className='step-input' type="radio" id="form-nth-abroad-study" name="drone" value="form-nth-abroad-study" />
                    <label className='step-label' for="form-nth-abroad-study">There is no difference in studying abroad and Nepal</label>
                </div>
                <div>
                    <input className='step-input' type="radio" id="form-few-abroad-study" name="drone" value="form-few-abroad-study" />
                    <label className='step-label' for="form-few-abroad-study"> Studying abroad would excel my career </label>
                </div>
                <div>
                    <input className='step-input' type="radio" id="form-few-abroad-study" name="drone" value="form-few-abroad-study" />
                    <label className='step-label' for="form-few-abroad-study"> I am confused on this </label>
                </div>
            </div>
            <div className='step-img-container'>
                <img onClick={prevStep} className='step-img' src={leftArrow} alt='right' />
                <img onClick={nextStep} className='step-img' src={rightArrow} alt='right' />
            </div>
        </>
    );
}

export default SixthStep;