import "./style.css";
import { useNavigate, useParams } from "react-router-dom";
// import { DecryptPhoneNumber } from "../../decrypters";
import { useState, useEffect } from "react";
// import { firebaseTools } from "../../../firebase1";
import NotFound from '../../Error/404Page';
import GigSetupFirstPage from "./pages/firstPage";
import GigSetupSecondPage from "./pages/secondPage";
import GigSetupThirdPage from "./pages/thirdPage";
import GigSetupFourthPage from "./pages/fourthPage";
import GigSetupFifthPage from "./pages/fifthPage";

// import algoliasearch from 'algoliasearch';
// import { api_key, app_id } from "../../../Algolia/algolia_1";
// import Preloader from "../../../preloader";
import Preloader from "../../components/Preloader/preloader";
// import Cancelicon from "../../../assets/cancel.svg"

import NavBar from "../../components/navbar/navbar";
import { getRequest, postRequest } from "../../requestAndLocalStorage/requestAndLocalStorage";
import { GTime } from "../../tools";
import { useData } from "../../DataContext/DataContext";
// const algoliaClient = algoliasearch(app_id, api_key);
// const consultantsIndex = algoliaClient.initIndex('search-items');

var GIGRef, CONRef;
export default function DraftGigPage() {
    // const navigator = useNavigate();
    const { gigID } = useParams();   // encrypted text fetched from path
    // const [pathFirstName, pathLastName, pathPhoneNumber] = DecryptPhoneNumber(encrypted);

    const [pageFound, setPageFound] = useState(false);
    const [loading, setLoading] = useState(true);

    const [gigData, setGigData] = useState({});
    const [consData, setConsData] = useState({})
    const [pageIndex, setPageIndex] = useState(0);
    const [gigPostedStatus, setGigPostedStatus] = useState(false);

    const navigate = useNavigate();
    const { categories } = useData();

    async function cancel() {
        if (gigPostedStatus) {

            navigate(-1);
        }
        else {
            console.log('to be deleted');
            // postRequest()
            navigate(-1);
        }
        // await firebaseTools.deleteGig(gigID);
    }

    function parseTime(time) {
        return `${time.substring(0, 2)}:${time.substring(2)}`;
    }

    useEffect(() => {
        const getRef = async () => {
            // GIGRef = await firebaseTools.getGIGDocUsingDocID(gigID);
            // CONRef = (await (await firebaseTools.getGIGDocUsingDocID(gigID)).get()).data();
        }
        // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= Verification =-=-=-=-=-=-=-=-=-=-=-=-= //
        const verification = async () => {
            postRequest('/api/data/gig/' + gigID)
                .then((response) => {
                    console.log(response.data);
                    var gigData = response;
                    if (response.data.length !== 1) {
                        setPageFound(false);
                        setLoading(false);
                        return;
                    }
                    setPageFound(true);
                    const temp = [
                        parseTime(response.data[0].AvailableTime.substring(0, 4)),
                        parseTime(response.data[0].AvailableTime.substring(4))
                    ]
                    gigData.data[0].AvailableTime = temp;
                    setGigData(gigData.data[0]);
                    setGigPostedStatus(gigData.data[0].Status == "1");
                    setLoading(false);


                    // for consultant data
                    getRequest('/api/data/consultant/enterprise/', { consID: gigData.data[0].ConsultantID })
                        .then(response => {
                            console.log(response.data);
                            setConsData(response.data)
                        })
                        .catch(err => {
                            console.error(err);
                        })
                })
                .catch((error) => {
                    setPageFound(false);
                    setLoading(false);
                });


            return;
            // await getRef();
            // if ((await GIGRef.get()).exists === false) {
            //     setPageFound(false);
            //     return;
            // }
            // var gigData = (await GIGRef.get()).data();
            // setGigData(gigData);
            // var ConsultantID = gigData.ConsultantID;
            // var CONData = (await (await firebaseTools.getCONDocUsingDocID(ConsultantID)).get()).data();

            // setGigPostedStatus(gigData.PostStatus);

            // if (CONData.FirstName.toLowerCase() !== pathFirstName.toLowerCase() ||
            //     CONData.LastName.toLowerCase() !== pathLastName.toLowerCase() ||
            //     CONData.PhoneNumber !== pathPhoneNumber ||
            //     CONData.Email !== firebaseTools.getAuthorizedUser().email
            // ) setPageFound(false);
            // else setPageFound(true);
        }
        verification();

        // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= //

    }, []);


    // next page logic
    async function nextPage(e, data) {
        e.preventDefault();
        // update data
        // to post
        if (pageIndex === 4) {
            if (gigData.Status === '0') {
                const time = new GTime();
                data.CreationDate = await time.getTime();
            }
            data.Status = 1;
        }
        updateValues(data)
            .then(setPageIndex(pageIndex + 1))
            .catch(error => console.error(error));

        // if (pageIndex === 3) {
        //     data.PostStatus = true;
        //     await GIGRef.update(data).then(() => console.log('updated')).catch((e) => console.log(e));
        //     setGigData({ ...gigData, ...data });
        //     // console.log('1', gigData);
        //     await consultantsIndex.saveObject({
        //         objectID: GIGRef.id,
        //         type: 'gig',
        //         ...gigData,
        //         PostStatus: 'true',
        //     });
        //     navigate(-1);
        // }
        // else {
        //     await GIGRef.update(data).then(() => console.log('updated')).catch((e) => console.log(e));
        //     setGigData({ ...gigData, ...data });
        // }
    }
    async function previousPage(e, data) {
        e.preventDefault();
        // return;
        updateValues(data)
            .then(setPageIndex(pageIndex-1))
            .catch(error => console.error(error));

        // update data
        // await GIGRef.update(data).then(() => console.log('updated')).catch((e) => console.log(e));
        // setGigData({ ...gigData, ...data });
    }

    async function updateValues(data) {
        // add new data or update existing data
        for (const [key, value] of Object.entries(data)) {
            gigData[key] = value;
        }
        delete data.Category1;
        delete data.Category2;
        delete data.Category3;
        if (pageIndex==2) {
            return postRequest('/api/data/gig/update_prerequisites', { Data: data, ID: gigID })
                .then((info) => console.log("updated"))
                .catch((error) => console.log(error));
        }
        return postRequest('/api/data/gig/update', { Data: data, ID: gigID })
            .then((info) => {
                console.log("updated");
            })
            .catch((error) => {
                console.error(error);
            });

    }

    function setupPage(index) {
        switch (index) {
            case 0: return <GigSetupFirstPage data={gigData} consData={consData} nextPage={nextPage}></GigSetupFirstPage>;
            case 1: return <GigSetupSecondPage data={gigData} nextPage={nextPage} gigID={gigID} previousPage={previousPage}></GigSetupSecondPage>
            case 2: return <GigSetupThirdPage data={gigData} nextPage={nextPage} previousPage={previousPage}></GigSetupThirdPage>
            case 3: return <GigSetupFourthPage data={gigData} nextPage={nextPage} previousPage={previousPage}></GigSetupFourthPage>
            case 4: return <GigSetupFifthPage data={gigData} nextPage={nextPage} previousPage={previousPage}></GigSetupFifthPage>
            default: navigate('/')
        }

    }

    if (loading) return <Preloader />
    if (!pageFound) return <NotFound></NotFound>

    // const progress = (pageIndex + 1) / 4 * 100;
    return (
        <>
            <NavBar />
            <div className="gig-setup-layout">
                <p className="gig-setup-layout-header">Greetings! {"HELLO"}</p>
                <p className="gig-setup-layout-subheader">Let's get started with your new gig.</p>
                <p className="gig-setup-layout-paragraph">Head over to the FAQ section for additional help with making your gig standout</p>
                <p onClick={cancel} className="gig-setup-cancel">Cancel Setup</p>
            </div>
            <form className="gig-setup-layout-form">
                {setupPage(pageIndex)}
                {/* <div className="progress-bar">
                    <div className="progress" style={{ width: `${progress}%` }}>
                    </div>
                </div> */}
                {/* <GitSetupFirstPage data={gigData} nextPage={nextPage}></GitSetupFirstPage> */}
            </form>
        </>
    );
}
