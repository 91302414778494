import { useEffect, useState } from "react";
import "./passwordSetter.css"
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { getPasswordStrength, getPasswordStrengthColor } from "../../tools";

const PasswordSetter = ({ setPasswordCallback, setPasswordConfirmCallback, showLabel, resetTrigger }) => {
    const [showPasswordCheckbox, setShowPasswordCheckbox] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [strengthColor, setStrengthColor] = useState();
    const [comment, setComment] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordOK, setPasswordOK] = useState(false);

    useEffect(() => {
        if (resetTrigger)
            resetTrigger(reset);
    }, []);

    const reset = () => {
        setPassword('');
        setPasswordStrength(0);
        setStrengthColor('');
        setComment('');
        setConfirmPassword('');
        setPasswordOK(false);
    }

    const handleShowPasswordToggle = () => {
        setShowPasswordCheckbox(temp => !temp);
    }

    useEffect(() => {
        handlePasswordChange(password);
        // handleConfirmPasswordChange(confirmPassword);
    }, [password, confirmPassword]);

    const handlePasswordChange = (password) => {
        const strength = getPasswordStrength(password);
        if (strength === 0) setComment("Select a strong password");
        else if (strength < 0.25) setComment("Very Weak");
        else if (strength < 0.50) setComment("Weak");
        else if (strength < 0.75) setComment("Medium");
        else if (strength < 1) setComment("Strong");
        else setComment("Very Strong");
        // setPassword(password);
        setPasswordCallback(confirmPassword);
        setPasswordStrength(strength);
        setStrengthColor(getPasswordStrengthColor(strength));

        // console.log(confirmPassword, password, strength);
        setPasswordCallback(confirmPassword);
        setPasswordOK((confirmPassword === password) && (strength > 0));
        setPasswordConfirmCallback((confirmPassword === password) && (strength > 0));
    }
    // const handleConfirmPasswordChange = (confirmPassword) => {
    //     // setConfirmPassword(confirmPassword);
    // }


    return  <div>
        <div className="password-field-container">
            <div className="password-field">
                <div className='password-field-wrapper'>
                    { showLabel && <h3>New Password</h3> }
                    <div className="password-input-field">
                        <input
                            type={showPasswordCheckbox ? 'text' : 'password'}
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className='password-field-strength' style={{ backgroundColor: strengthColor, width: `${passwordStrength*100}%`}}/>
                    </div>
                </div>
                { !showLabel &&
                    (showPasswordCheckbox ? (<FaEye onClick={handleShowPasswordToggle} />) : (<FaEyeSlash onClick={handleShowPasswordToggle} />))
                }
            </div>
            <div style={{color: strengthColor}}>{comment}</div>
        </div>
        <div>
            <div className="password-field">
                <div className="password-field-wrapper">
                    { showLabel && <h3>Confirm Password</h3> }
                    <div className="password-input-field">
                        <input
                            type={showPasswordCheckbox ? 'text' : 'password'}
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        { (password.length!==0 && confirmPassword.length!==0) ? <div className={"correct-password-" + (passwordOK ? "yes" : "no")}></div> : <></>}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default PasswordSetter;