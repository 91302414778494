import logo from "../../assets/navbar/logo.png";
import './preloader.css'

const Preloader = () => {
    return (
        <>
            <div className="preloader-overlay">
                <div className="progress-circle"></div>
                <img className="loader" src={logo} alt="preloader-img" />
            </div>
        </>
    );
};
export default Preloader;
