import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { postRequest, setRefreshToken } from "../../requestAndLocalStorage/requestAndLocalStorage";
import { toast } from "react-toastify";
import Preloader from "../../components/Preloader/preloader";
import { useData } from "../../DataContext/DataContext";

const ConfirmRegistration = (props) => {
    const params = useParams();
    const navigator = useNavigate();
    const { load } = useData();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        postRequest('/api/auth/confirmation/'+params.token)
            .then((response) => {
                setRefreshToken(response.data.refreshToken);
                load()
                    .then(() => {
                        toast.success("Account successfully created");
                        navigator('/', { replace: true });
                    })
                    .catch(() => {
                        toast.error("Something went wrong");
                        navigator('/404', { replace: true });
                    })

            })
            .catch((err) => {
                console.error(err);
                toast.error("Something went wrong");
                navigator('/404', { replace: true });
            })
    }, []);

    return <Preloader />
}

export default ConfirmRegistration;