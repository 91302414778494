import React from 'react'
import rightArrow from '../../../assets/right-arrow.svg'
import leftArrow from '../../../assets/left-arrow.svg'

const SeventhStep = ({ formData, setFormData, nextStep, prevStep }) => {
    return (
        <>
            <h3 className='step-header'>The most difficult step is: </h3>
            <div className='form-container'>
                <div>
                    <input className='step-input' type="radio" id="form-yes" name="drone" value="enterprise" />
                    <label className='step-label' for="form-yes">Processing legal papers</label>
                </div>
                <div>
                    <input className='step-input' type="radio" id="form-no" name="drone" value="form-high-school" />
                    <label className='step-label' for="form-no">Attending different exams</label>
                </div>
                <div>
                    <input className='step-input' type="radio" id="form-secondary-school" name="drone" value="form-secondary-school" />
                    <label className='step-label' for="form-secondary-school">Managing Student Loan </label>
                </div>
                <div>
                    <input className='step-input' type="radio" id="form-secondary-school" name="drone" value="form-secondary-school" />
                    <label className='step-label' for="form-secondary-school">Seeking Career Guidance </label>
                </div>
            </div>
            <div className='step-img-container'>
                <img onClick={prevStep} className='step-img' src={leftArrow} alt='right' />
                <img onClick={nextStep} className='step-img' src={rightArrow} alt='right' />
            </div>
        </>
    );
}

export default SeventhStep;