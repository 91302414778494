import { useEffect, useState } from "react"
import { MdOutlineTimer } from "react-icons/md";

import "./countDownTimer.css";

const CountDownTimer = ({ timeStamp, deltaTimeCallback=()=>{}, show=true }) => {
    const [days, setDays] = useState('00');
    const [hours, setHours] = useState('00');
    const [minutes, setMinutes] = useState('00');
    const [seconds, setSeconds] = useState('00');
    const [deltaTime, setDeltaTime] = useState(0);
    const [joinEnable, setJoinEnable] = useState(false);
    const [rescheduleLabel, setRescheduleLabel] = useState(false);

    useEffect(() => {
        const current = new Date();
        var deltaTime = (parseInt(timeStamp) - current.getTime()) / 1000;
        setDeltaTime(deltaTime);
        deltaTimeCallback(deltaTime);
        
        const interval = setInterval(() => {
            var tempDeltaTime = deltaTime;
            if (tempDeltaTime < 15*60) {
                if (tempDeltaTime > -5*60) {
                    setJoinEnable(true);
                }
                else {
                    setRescheduleLabel(true);
                    setJoinEnable(true);
                }
            }
            setDays((parseInt(deltaTime / (60*60*24))).toString().padStart(2, '0'));
            setHours(Math.abs(parseInt(deltaTime / (60*60) % 24)).toString().padStart(2, '0'));
            setMinutes(Math.abs(parseInt(deltaTime / 60) % 60).toString().padStart(2, '0'));
            setSeconds(Math.abs(parseInt(deltaTime) % 60).toString().padStart(2, '0'));
            deltaTime--;
            deltaTimeCallback(tempDeltaTime);
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    }, [timeStamp]);

    if (show)
        return <div className="countdown-timer-container">
            { joinEnable ?
                (rescheduleLabel ? 
                    <span>RESCHEDULE</span> :
                    <span>JOIN</span>)
                : <div className="count-down-timer">
                    <div className="count-down-timer-timer">
                        <p>{days}</p>:<p>{hours}</p>:<p>{minutes}</p>:<p>{seconds}</p>
                    </div>
                    <MdOutlineTimer className="timer-clock-icon"/>
                </div>
            }
        </div>
}


export default CountDownTimer;