import NavBar from "../../components/navbar/navbar";
import "./booking.css";
import { useEffect, useState } from "react";
import { getRequest, postRequest } from "../../requestAndLocalStorage/requestAndLocalStorage";
import { Link, useParams } from "react-router-dom";
import { decryptUrl } from "../../tools";
import Calendar from "./components/calendar";
import Timeline from "./components/timeline";
import Popup from "../../components/Popup/popup";
import BookingPopup from "./bookingPopup";
import { firebaseTools } from "../../DataContext/firebase1";
import { useData } from "../../DataContext/DataContext";
import Footer from "../../components/footer/footer";
import Preloader from "../../components/Preloader/preloader";

export default function Booking() {
    const {encrypted} = useParams();
    const [marker, setMarker] = useState();
    const [sessions, setSessions] = useState();
    const [date, setDate] = useState();
    const [bookingPopup, setBookingPopup] = useState(false);
    const [selectedTime, setSelectedTime] = useState();
    const [isLoading, setIsLoading] = useState(true);
    
    const [consultantProfile, setConsultantProfile] = useState();
    const [consultantName, setConsultantName] = useState();
    const [consultantLink, setConsultantLink] = useState();
    const [gigs, setGigs] = useState([]);
    
    const [staticCalendar, setStaticCalendar] = useState(true);
    const [verticalTimeline, setVerticalTimeline] = useState(false);

    const { currentUser } = useData();
    
    useEffect(() => {
        const decrypted = decryptUrl(encrypted);
        setConsultantLink('/consultant/' + encrypted);
        const ConsultantName = decrypted.slice(0, decrypted.length-1).join(' ');
        const ConsultantID = parseInt(decrypted[2]);
        
        setConsultantName(ConsultantName);

        firebaseTools.getCONProfilePic(ConsultantID)
            .then((response) => setConsultantProfile(response))
            .catch((err) => console.error(err) );
        
        const consSessionData = getRequest('/api/data/session/getConsData', { ConsultantID: ConsultantID, ConsultantName: ConsultantName })
            .then(response => {
                const takenTime = [];
                setSessions(response.data);
                for (let session of response.data) {
                    takenTime.push(session.SessionTime);
                }
                setMarker(takenTime);
            })
            .catch(error => {
                console.log(error);
            })
        
        const consGigData = getRequest('/api/data/gigs', { ConsultantID: ConsultantID })
            .then(async response => {
                const gigs = response.data;
                for (const gig of gigs) {
                    gig.ProfilePic = await firebaseTools.getGIGProfilePic(gig.ID);
                    gig.ConsultantID = ConsultantID;
                }
                // setGigs(gigs);
                if (currentUser && currentUser.Source==='user') {
                    postRequest('/api/data/session/get_incomplete_gig', { userID: currentUser.UID })
                        .then(response => {
                            filterGigs(gigs, response.data);
                        })
                        .catch(error => console.error(error));
                }
                else {
                    filterGigs(gigs, []);
                }
            })
            .catch(error => {
                console.log(error);
            })

        Promise.all([consSessionData, consGigData]).then(() => {
            setIsLoading(false);
        });

        window.addEventListener("resize", resized);
        resized();
        return () => {
            window.removeEventListener("resize", resized);
        }
    }, [])


    const resized = () => {
        const width = window.innerWidth;
        if (width < 768) {
            setVerticalTimeline(true);
        }
        else {
            setVerticalTimeline(false);
        }
        if (width < 1100) {
            setStaticCalendar(false);
        }
        else {
            setStaticCalendar(true);
        }
    }

    const filterGigs = (gigs, filters) => {
        for (const filter of filters) {
            for (let i=0; i<gigs.length; i++) {
                if (gigs[i].ID === filter.GigID) {
                    gigs[i].SessionID = filter.ID;
                    gigs[i].SessionStatus = filter.Status;
                }
            }
        }
        setGigs(gigs);
    }

    const onClick = (time) => {
        setSelectedTime(time);
        setBookingPopup(true);
    }

    return <div className="booking-page">
        { isLoading && <Preloader /> }
        <NavBar  />
        <div className={`booking-wrapper ` + (staticCalendar ? "" : "booking-wrapper-vertical")}>
            <div className="booking-calendar-wrapper">
                <div className="booking-consultant-details">
                    <Link to={consultantLink}>
                        { consultantProfile ? 
                            <img className="booking-consultant-profile-pic" src={consultantProfile} />
                            : <div className="booking-consultant-profile-pic image-preloader"></div>
                        }
                    </Link>
                    <Link to={consultantLink}><h3>{consultantName}</h3></Link>
                </div>
                <Calendar markers={marker} dateChanged={setDate} staticCalendar={staticCalendar}/>
            </div>
            <Timeline sessions={sessions} date={date} onClickCallback={onClick} verticalTimeline={verticalTimeline}/>
            { bookingPopup && <>
                <Popup isOpen={bookingPopup} onClose={() => setBookingPopup(false)} className="booking-popup">
                    <BookingPopup time={selectedTime} gigs={gigs} onClose={() => setBookingPopup(false)}/>
                </Popup>
                <div className="background-overlay"></div>
            </>
            }
        </div>
        <Footer hideTop />
    </div>
}