import React, { useState } from 'react'
import './Landingpage.css'
import Navigation from './components/Navigation'
import FirstStep from './Registration/FirstStep'
import SecondStep from './Registration/SecondStep'
import { useNavigate } from 'react-router-dom'
import ThirdStep from './Registration/ThirdStep'
import FourthStep from './Registration/FourthStep'
import SixthStep from './Registration/SixthStep'
import SeventhStep from './Registration/SeventhStep'
import FifthStep from './Registration/FifthStep'
import Preloader from '../../components/Preloader/preloader'


const UserGuide = () => {
    const navigate = useNavigate();

    const [step, setStep] = useState(1)
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
    })

    const nextStep = () => {
        if (step===7) navigate('/registration/user');
        setStep((prevStep) => prevStep + 1);
    }
    const prevStep = () => setStep((prevStep) => prevStep - 1);
    const totalSteps = 3

    return (
        <>
            {/* <div className="blur-overlay" /> */}
            <Navigation showGetStarted={false}/>
            <div className='registration-container-wrapper'>                
                <div className='registration-container'>
                    <div className='registration-content'>
                        <h2 className='registration-header'>Get started with the registration!</h2>
                        <p style={{ color: '#002147' }}>Tell us a little bit about yourself</p>
                        <progress className="form-progress" max={8} value={step} />
                        {/* <ProgressBar currentStep={step} totalStep={totalSteps} /> */}
                        {(() => {
                            switch (step) {
                                case 1:
                                    return <FirstStep formData={formData} setFormData={setFormData} nextStep={nextStep} />
                                case 2:
                                    return <SecondStep formData={formData} setFormData={setFormData} nextStep={nextStep} prevStep={prevStep} />
                                case 3:
                                    return <ThirdStep formData={formData} setFormData={setFormData} nextStep={nextStep} prevStep={prevStep} />
                                case 4:
                                    return <FourthStep formData={formData} setFormData={setFormData} nextStep={nextStep} prevStep={prevStep} />
                                case 5:
                                    return <FifthStep formData={formData} setFormData={setFormData} nextStep={nextStep} prevStep={prevStep} />
                                case 6:
                                    return <SixthStep formData={formData} setFormData={setFormData} nextStep={nextStep} prevStep={prevStep} />
                                case 7:
                                    return <SeventhStep formData={formData} setFormData={setFormData} nextStep={nextStep} prevStep={prevStep} />
                                default:
                                    return <Preloader />
                            }
                        })()}
                        <form method='dialog'>
                            {/* <button className='registration-button' onClick={() => navigate("/")}>Close</button> */}
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserGuide;