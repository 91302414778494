import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const Landing = () => {
    const navigate = useNavigate();

    const [navbarToggle, setNavbarToggle] = useState(false);
    const [scrollY, setScrollY] = useState(0);
    const root = document.getElementById('root');

    function logit() {
        // console.dir(root);
        setScrollY(document.scrollingElement.scrollTop);
    }
    
    useEffect(() => {
        function watchScroll() {
          window.addEventListener("scroll", logit);
        }
        watchScroll();

        // Remove listener (like componentWillUnmount)
        return () => {
          window.removeEventListener("scroll", logit);
        };
    }, []);

    const handleToggle = () => {
        if (!navbarToggle) {
            window.location.href = 'https://enterprise.tackoom.com';
        }
        setNavbarToggle(!navbarToggle)
    }
    return (
        <>
            <div className='landing-container' style={{top: scrollY / 2}}>
                <div>
                    <h1 className='landing-header'>Your Career, Your Choice</h1>
                    <button onClick={() => navigate("/userguide")} className='landing-button'>Explore</button>
                    <div className='landing-toggle'>
                        <label className="switch">
                            <input onClick={handleToggle} type="checkbox" />
                            <span className="slider round"></span>
                        </label>
                        {navbarToggle ? <span className='navbar-span'>Enterprise</span> : <span className='navbar-span'>Student</span>}
                    </div>
                </div>
            </div>
            <div className='landing-filler'></div>
        </>
    )
}

export default Landing