import React from 'react'
import GigSort from "./components/GigSort";
import Landing from "./components/Landing";
import Navigation from "./components/Navigation";
// import Footer from './Footer';
// import Footer from '../../components/footer/footer';
import Footer from '../../components/footer/footer';
// import Testimonial from './Testimonial';
import FAQ from './components/FAQ'
import { FaArrowUp } from 'react-icons/fa';
import Commitment from './components/Commitment';

import "./Landingpage.css";

const LandingPage = () => {
    return (
        <>
            <div id='nav-top' />
            <Navigation />
            <Landing />
            <GigSort />
            {/* <Testimonial /> */}
            <FAQ />
            <Commitment />
            <Footer />
            <a className="scroll-to-top" href='#nav-top'><FaArrowUp /></a>
        </>
    )
}

export default LandingPage