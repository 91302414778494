import { firebaseTools } from "../../../DataContext/firebase1";
import SimplePeer from 'simple-peer';
import { postRequest } from "../../../requestAndLocalStorage/requestAndLocalStorage";

class SessionDBTools {
    constructor() {
        this.callBack = ()=>{};
        this.callBackType = "";
        this.roomRef= null;

        this.stream = null;

        this.peer = null;
        this.streamObj = {};

        this.isHost = null;
        this.client = {};

        this.initiated = false;

        this.hasOffer = false;
        this.hasAnswer = false;

        this.timerInterval = null;
        this.timeElapsed = 0;

        this.localStream = new MediaStream();
        this.joined = false;
        
        this.balance = 0;
        this.balancePerSecond = 0;
        // this.videoMediaStream = new
        
        this.screenTrackId = "";
        this.connected = false;

        this.presenceCheckDelta = 5000; // check for presence at 5 intervals
        this.presenceInterval = null;
        this.presenceTimeout = null;

        this.sessionID = null;

    }
    
    // ENTRY POINT
    async makeNewRoom({
        docRef,
        id,
        // stream,
        streamCallBack,
        messageListener,
        timerObj,
        onConnection,
        clientRef,
        lowBalanceCallBack,
        hangUp,
        price,
    }) {
    // async makeNewRoom(docRef, id, stream, streamCallBack, messageListener, timerObj, onConnection) {
        if (this.initiated) return; // making sure that this part runs only once
        var exists;
        this.sessionID = docRef.id;
        // this.localStream = stream;
        // this.addStream(true, true);
        this.lastQuery = {};
        this.hangUp = hangUp;
        this.balancePerSecond = price / 3600;

        // client's balance
        // let temp = await clientRef.get()
        // this.balance = temp.data().Balance;
        // this.lowBalanceCallBack = lowBalanceCallBack;

        // let docRef = await (await firebaseTools.getCSECDocUsingDocID(name)).get();
        if (docRef.exists === false) return;

        let sessionData = docRef.data;

        this.streamCallBack = streamCallBack;
        this.messageListener = messageListener;
        this.timerObj = timerObj;

        this.isHost = sessionData.ConsultantID === id;
        this.onConnectionCallBack = onConnection;

        // unauthorized user
        if (sessionData.UserID !== id && !this.isHost){
            console.log("bruh get lost different account");
            return "unauthorized";
        }

        // peer1 = HOST, peer2 = client
        [this.peer1Ref, exists] = await firebaseTools.makeNewRoom(docRef.id + '-H');
        [this.peer2Ref, exists] = await firebaseTools.makeNewRoom(docRef.id + '-C');

        // creating this.peer
        await this.makeNewPeer(this.isHost);


        (this.isHost?this.peer1Ref:this.peer2Ref).onSnapshot(async (snapShot) => {
            if (!this.isHost && !this.joined) {
                this.lastQuery = snapShot.data();
                return;
            }

            if (!this.joined) return;

            if (Object.keys(snapShot.data()).length !== 0) {
                let snapShotData = snapShot.data().new;
                if (snapShotData.length) {
                    this.peer.signal(snapShot.data().new);
                }
                (this.isHost?this.peer1Ref:this.peer2Ref).set({})
            }
        });



        // event listners
        
        this.peer.on("signal", async (data)=>{
            if (this.isHost && !this.joined) {
                this.lastQuery = JSON.stringify(data);
                // return true;
            }
            else
            if (!this.joined) {}
            else {
                if (this.isHost) await this.hostSignal(JSON.stringify(data));
                else await this.clientSignal(JSON.stringify(data));
            }
            
        })
        
        
        this.peer.on("data", (data) => {
            let current = JSON.parse(data.toString());
            if (current.type === "empty-stream-warning") {
                this.streamCallBack(new MediaStream());
            }
            else if (current.type === "message") {
                this.messageListener(current.message);
            }
            else if (current.type === "presence-check") {
                this.resetPresenceInterval();
            }
        })



        // Event listener to check when the connection is closed
        this.peer.on('close', () => {
            console.log('Peer disconnected!');
            clearInterval(this.presenceInterval);
            clearInterval(this.timerInterval);
            this.hangUp();
            this.peer1Ref.set({});
            this.peer2Ref.set({});

            postRequest('/api/data/session/complete', {
                sessionID: this.sessionID,
                duration: this.timeElapsed
            })
                .then((response) => console.log(response))
                .catch((error) => console.log(error));
        });
        
        // Event listener to check for errors
        this.peer.on('error', (err) => {
            console.error('Peer error:', err);
        });
        
        // Event listener to check when connection is established
        this.peer.on('connect', () => {
            this.connected = true;
            this.peer.addStream(new MediaStream());
            this.onConnectionCallBack();
            // for timer
            setTimeout(()=>
                this.timerInterval = setInterval(() => {
                    this.timeElapsed += 1;
                    // if (this.balance)
                    //     clientRef.update({
                    //         Balance: this.balance
                    //     })
                    // this.balance -= this.balancePerSecond;

                    // if (this.balance < 20 && this.balance + this.balancePerSecond >= 20) {
                    //     this.lowBalanceCallBack();
                    // }
                    // if (this.balance - this.balancePerSecond <= 0) {
                    //     this.hangUp();
                    // }

                    if (this.timerObj)
                        this.timerObj.innerHTML = this.parseTime(this.timeElapsed);
                }, 1000)
            , 5000);

            // start check intervals
            this.presenceInterval = setInterval(()=>this.checkPresenceInterval(this.peer), this.presenceCheckDelta);
            this.resetPresenceInterval();
            console.log('Peer connected!');
        });
        
        this.initiated = true;
    }

    // presence interval functions
    checkPresenceInterval(peer) {
        peer.send(JSON.stringify({type:"presence-check", message:"check"}))
    }
    resetPresenceInterval() {
        clearTimeout(this.presenceTimeout);
        this.presenceTimeout = setTimeout(() => {
            console.log("should have ended call");
            console.log(this);
            this.leaveInterrupt();
        }, this.presenceCheckDelta * 1.25);
    }

    setTimerObj(timerObj) {
        this.timerObj = timerObj;
    }
    parseTime(timeIn) {
        let time = parseInt(timeIn);
        let hours = (parseInt(time / 3600)).toString().padStart(2,'0');
        let minutes = (parseInt(time / 60) - hours*60).toString().padStart(2,'0');
        let seconds = (parseInt(time - minutes * 60 - hours * 3600)).toString().padStart(2,'0');

        return `${hours}:${minutes}:${seconds}`
    }

    async join() {
        this.joined = true;
        if (this.isHost) {
            if (this.lastQuery !== "{}") {
                this.hostSignal(this.lastQuery);
            }
        }
        else {
            if (Object.keys(this.lastQuery || {}).length !== 0) {
                this.peer.signal(this.lastQuery.new);
                this.peer2Ref.set({})
            }

        }
    }
    

    async sendMsg(trackID) {
        if (this.peer)
            this.peer.send(JSON.stringify({message: trackID, type:"message"}));
    }
    
    // sets offer status and offer sdp
    async hostSignal(signal) {
        await this.peer2Ref.set({
            new: signal,
        });
    }

    // gets offer and offer status
    async getHostSignal() {
        let offer = await this.peer2.get();
        let data = offer.data();
        let answer = data.new || {};
        if (Object.keys(answer).length !== 0) {
            this.hasOffer = true;
        }
        return answer;
    };

    // sets anwer status and answer sdp
    async clientSignal(signal) {
        await this.peer1Ref.set({
            new: signal,
        });
    }   
    
    
    // gets answer and answer status
    async getClientSignal() {
        let offer = await this.peer1.get();
        let data = offer.data();
        let answer = data.new || {};
        if (Object.keys(answer).length !== 0) {
            this.hasAnswer = true;
        }
        return answer;
    }
    
    async addStream(video, audio) {
        if (this.localStream) 
            this.localStream?.getTracks().forEach(track => {
                track.stop();
                this.localStream.removeTrack(track);
            });
        else this.localStream = new MediaStream();

        if (video || audio) {
            this.localStream = await navigator.mediaDevices.getUserMedia({ video: video, audio: audio });

            
            if (!this.peer) return this.localStream;
            // for (let track of this.localStream.getAudioTracks()) {
            //     console.log("sending", track);
            // }
            // console.log("sending stream", this.localStream);
            this.peer.addStream(this.localStream);
        }
        else {
            this.localStream = new MediaStream();
            this.removeStream();
        }
        return this.localStream;
    }
    
    // async addStream(stream) {
    //     console.log("testing", this.localStream);
    //     this.localStream?.getTracks().forEach(track => {
    //         track.stop();
    //         this.localStream.removeTrack(track);
    //     });
    //     this.localStream = stream;
    //     if (!this.peer) return;
    //     console.log("sending stream" , stream);

        
    //     this.peer.addStream(this.localStream);
    //     // stream.getTracks().forEach(track => {
    //     //     this.peer.addTrack(track, this.peer.streams[0]);
    //     // });
    //     // this.peer.addStream(stream);
    // }

    async removeStream() {
        this.localStream.getTracks().forEach(track => {
            track.stop();
            this.localStream.removeTrack(track);
        });
        this.localStream = new MediaStream();
        if (!this.peer) return;
        this.peer.addStream(this.localStream);

        if (this.connected)
            this.peer.send(JSON.stringify({type:"empty-stream-warning"}));
    }
    
    leaveRoom() {
        if (this.peer) {
            this.peer.destroy();
        }
        this.peer1Ref.set({});
        this.peer2Ref.set({});
    }

    leaveInterrupt() {
        if (this.peer) {
            this.peer.destroy();
        }
        if (this.peer1Ref)
            this.peer1Ref.set({});
        if (this.peer2Ref)
            this.peer2Ref.set({});
    }


    async makeNewPeer(isHost) {
        this.peer = new SimplePeer({
            initiator: isHost,
            trickle: false,
            stream: this.localStream,
            config: {
                iceServers: [
                    {
                        urls: "stun:stun.relay.metered.ca:80",
                    },
                    {
                        urls: "turn:a.relay.metered.ca:80",
                        username: "0363085ede1be31dc93b0bb1",
                        credential: "85qpP4ACb6eb3kdY",
                    },
                    {
                        urls: "turn:a.relay.metered.ca:80?transport=tcp",
                        username: "0363085ede1be31dc93b0bb1",
                        credential: "85qpP4ACb6eb3kdY",
                    },
                    {
                        urls: "turn:a.relay.metered.ca:443",
                        username: "0363085ede1be31dc93b0bb1",
                        credential: "85qpP4ACb6eb3kdY",
                    },
                    {
                        urls: "turn:a.relay.metered.ca:443?transport=tcp",
                        username: "0363085ede1be31dc93b0bb1",
                        credential: "85qpP4ACb6eb3kdY",
                    },
                ],
            }
        });
        this.peer.on("stream", (stream) => {
            // console.log("incomingStream", stream);
            this.streamCallBack(stream);
            // this.streamObj.current.srcObject = stream;
        });
    }
}

export default SessionDBTools;