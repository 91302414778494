import SetupPage from "./components/setupPage";
import { useState } from "react";

const FourthPageList = {
    Price: 20,
};

export default function GigSetupFifthPage(props) {
    const [price, setPrice] = useState(props.data.Price || 0);
    FourthPageList.Price = price;

    function priceChanged(e) {
        setPrice(parseInt(e.target.value));
        FourthPageList.Price = parseInt(e.target.value);
    }

    function nextPage(e) {
        e.preventDefault();
        if (FourthPageList.Price >= 0) {
            props.nextPage(e, FourthPageList);
        }
        else {
            alert("Please fill all the fields");
        }
    }
    function previousPage(e) {
        e.preventDefault();
        if (FourthPageList.Price >= 0) {
            props.previousPage(e, FourthPageList);
        }
        else {
            alert("Please fill all the fields");
        }
    }

    return (
        <SetupPage next={nextPage} previous={previousPage} nextText={"Submit"}>
            <div className="gig-form-row">
                <label>Set Pricing</label>
                <input className="price"
                    placeholder="enter price here"
                    type="number"
                    name="price"
                    value={ price.toString() }
                    onInput={priceChanged}
                    min={20}
                />
            </div>
        </SetupPage>);
}