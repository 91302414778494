// import './userProfile.css'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
// import TopEnt from './App/functions/TopEnt';
import { firebaseTools } from '../../DataContext/firebase1';
import NavBar from '../../components/navbar/navbar';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faEye, faEyeSlash, faArrowRight, faArrowLeft, faWallet, faCalendar, faFile } from '@fortawesome/free-solid-svg-icons'
import { decryptUrl } from '../../tools';
import NotFound from '../../Error/404Page';
import RecommendedGigs from '../user/components/recommendedGigs';
import Footer from '../../components/footer/footer';
// assets
// import walletIcon from '../assets/homepage/wallet.svg'
// import calendarIcon from '../assets/homepage/calandarIcon.svg'
// import statementIcon from '../assets/homepage/statementIcon.svg'
// import downloadIcon from '../assets/homepage/downloadIcon.svg'
import Slider from '../user/components/slider';
// import ConstructionPopup from '../constructionPopup';
import Preloader from '../../components/Preloader/preloader';
import { useData } from '../../DataContext/DataContext';
import { getRequest, postRequest } from '../../requestAndLocalStorage/requestAndLocalStorage';
import CountDownTimer from '../../components/CountDownTimer/countDownTimer';
import GetGigComponent from '../../components/SessionContainer/sessionContainer';
// import { useAuth } from '../AuthContext';

import './userSession.css';

const UserSession = () => {
    // const { encrypted } = useParams()
    const { currentUser } = useData();

    const [valid, setValid] = useState(true);

    const [startIndex, setStartIndex] = useState(0);
    const [showPopup, setShowPopup] = useState(true);
    const [showPreloader, setShowPreloader] = useState(true);


    // authorized data & state
    const [authorizedData, setAuthorizedData] = useState(false);
    const [isAuthorizedFB, setIsAuthorizedFB] = useState(true);
    const [gigData, setGigData] = useState([]);

    const [pendingSessions, setPendingSessions] = useState([]);
    const [confirmedSessions, setConfirmedSessions] = useState([]);
    const [completedSessions, setCompletedSessions] = useState([]);
    const [rejectedSessions, setRejectedSessions] = useState([]);
    const [sessionInfo, setSessionInfo] = useState({});

    const navigate = useNavigate();

    var userID = '';


    useEffect(() => {
        if (currentUser.Source !== 'user') {
            setValid(false);
            return;
        }
        const temp = currentUser.Link.split('/');

        const sessionNumberReq = postRequest('/api/data/session/get_number_session', { userID: currentUser.UID })
            .then(response => setSessionInfo(response.data[0]))
            .catch(err => console.error(err));

        const recommendedGigs = getRequest('/api/data/gigs/random', { size: 20 })
            .then(async response => {
                const tempGigs = [];
                for (let i = 0; i < response.data.length; i++) {
                    tempGigs.push({
                        ...response.data[i],
                    })
                }
                setGigData(tempGigs);
            })
            .catch(error => {
                console.error(error);
            });

        // pending gigs
        const pendingGigs = postRequest('/api/data/session/get_all', { userID: currentUser.UID })
            .then(async (response) => {
                const pendingList = [], confirmedList = [], completedList = [], rejectedList = [];

                for (let i = 0; i < response.data.length; i++) {
                    response.data[i].ProfilePic = await firebaseTools.getGIGProfilePic(response.data[i].ID);
                    switch (response.data[i].SStatus) {
                        case '0': pendingList.push(response.data[i]); break;
                        case '1': confirmedList.push(response.data[i]); break;
                        case '3': completedList.push(response.data[i]); break;
                        case '4': rejectedList.push(response.data[i]); break;
                    }
                }

                setPendingSessions(pendingList);
                setConfirmedSessions(confirmedList);
                setCompletedSessions(completedList);
                setRejectedSessions(rejectedList);
            })
            .catch((error) => console.error(error))

        // completed gigs
        const completedGigs = postRequest('/api/data/session/get_completed', { userID: currentUser.UID })
            .then(async (response) => {
                for (let i = 0; i < response.data.length; i++) {
                    response.data[i].ProfilePic = await firebaseTools.getGIGProfilePic(response.data[i].ID);
                }
                setCompletedSessions(response.data);

            })
            .catch((error) => console.error(error))
        
        Promise.all([recommendedGigs, pendingGigs, completedGigs, sessionNumberReq])
            .then(() => {
                setShowPreloader(false);
            })
            .catch(() => {
                setShowPreloader(false);
            })
    }, [])


    const handleSessionClick = (gig) => {
        navigate(`/session/${gig.SID}`);
    }

    return (
        <>
            {!valid ? (
                <NotFound></NotFound>
            ) : (
                <div className="homepage-main">
                    {showPreloader && <Preloader />}
                    <NavBar></NavBar>


                    <div className="homepage-content">

                    <div className='user-session-description-wrapper'>
                            <h1 className='recommended-gig-header'>Session Details</h1>
                            <div className='user-session-description-container'>
                                <a href="#pending-sessions" className='user-session-description-div'>
                                    <p>{sessionInfo.Pending}</p>
                                    <h2>Pending</h2>
                                </a>
                                <a href="#confirmed-sessions" className='user-session-description-div'>
                                    <p>{sessionInfo.Confirmed}</p>
                                    <h2>Confirmed</h2>
                                </a>
                                <a href="#completed-sessions" className='user-session-description-div'>
                                    <p>{sessionInfo.Completed}</p>
                                    <h2>Completed</h2>
                                </a>
                                <a href="#rejected-sessions" className='user-session-description-div'>
                                    <p>{sessionInfo.Rejected}</p>
                                    <h2>Rejected</h2>
                                </a>
                            </div>
                        </div>


                        {pendingSessions?.length ? (
                            <div className='recent-sessions-wrapper' id='pending-sessions'>
                                <div className='recent-sessions'>
                                    <h1 className='recommended-gig-header recommended-gig-header-session'>Pending Sessions</h1>
                                    <div className='sessions-container'>
                                        <Slider minWidth={200}>
                                            {pendingSessions.sort((a,b)=>b.Time - a.Time).map((gig, index) =>
                                                <GetGigComponent gig={gig} handleSessionClick={(gig) => handleSessionClick(gig)} key={index}></GetGigComponent>
                                            )}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        ) : <></>}
                        {confirmedSessions?.length ? (
                            <div className='recent-sessions-wrapper' id='confirmed-sessions'>
                                <div className='recent-sessions'>
                                    <h1 className='recommended-gig-header recommended-gig-header-session'>Confirmed Sessions</h1>
                                    <div className='sessions-container'>
                                        <Slider minWidth={200}>
                                            {confirmedSessions.map((gig, index) =>
                                                <GetGigComponent gig={gig} handleSessionClick={(gig) => handleSessionClick(gig)} key={index}></GetGigComponent>
                                            )}
                                        </Slider>
                                    </div>
                                </div>

                            </div>
                        ) : <></>}
                        {completedSessions?.length ? (
                            <div className='recent-sessions-wrapper' id='completed-sessions'>
                                <div className='recent-sessions'>
                                    <h1 className='recommended-gig-header recommended-gig-header-session'>Completed Sessions</h1>
                                    <div className='sessions-container'>
                                        <Slider minWidth={200}>
                                            {completedSessions.map((gig, index) =>
                                                <GetGigComponent gig={gig} handleSessionClick={(gig) => handleSessionClick(gig)} key={index}></GetGigComponent>
                                            )}
                                        </Slider>
                                    </div>
                                </div>

                            </div>
                        ) : <></>}
                        {rejectedSessions?.length ? (
                            <div className='recent-sessions-wrapper' id='rejected-sessions'>
                                <div className='recent-sessions'>
                                    <h1 className='recommended-gig-header recommended-gig-header-session'>Rejected Sessions</h1>
                                    <div className='sessions-container'>
                                        <Slider minWidth={200}>
                                            {rejectedSessions.map((gig, index) =>
                                                <GetGigComponent gig={gig} handleSessionClick={(gig) => handleSessionClick(gig)} key={index}></GetGigComponent>
                                            )}
                                        </Slider>
                                    </div>
                                </div>

                            </div>
                        ) : <></>}
                        <div className='recommended-gigs'>
                            <RecommendedGigs gigs={gigData} header="Recommended Gigs" />
                        </div>

                        <Footer className="user-session-footer" />
                    </div>
                </div>
            )}
            {/* <ConstructionPopup /> */}
        </>
    );
}
export default UserSession;