import React from 'react'
import './ProgressCheck.css'

const ProgressCheck = () => {
    return (
        <>
            <div className='progresscheck-step'>
                <p className='progresscheck-step-header'>Step 1: Categorize your gig</p>
                <p className='progresscheck-step-count'>1. Give your gig a title.</p>
                {/* <p className='progresscheck-step-count'>2. Select the best category for you.</p>
                <p className='progresscheck-step-count'>3. Select your keywords.</p> */}
            </div>
            <div className='progresscheck-step'>
                <p className='progresscheck-step-header'>Step 2: Describe your gig</p>
                <p className='progresscheck-step-count'>1. Describe whats special about your gig.</p>
                <p className='progresscheck-step-count'>2. Upload an image for your gig.</p>
            </div>
            <div className='progresscheck-step'>
                <p className='progresscheck-step-header'>Step 3: Time your gig</p>
                <p className='progresscheck-step-count'>1. Adjust your timing to your preference.</p>
            </div>
            <div className='progresscheck-step'>
                <p className='progresscheck-step-header'>Step 4: Price your gig</p>
                <p className='progresscheck-step-count'>1. Best price for your gig.</p>
            </div>
        </>
    )
}

export default ProgressCheck