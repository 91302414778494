import { SHA256 } from "crypto-js";

export function decryptUrl(encoded) {
    const temp = encoded.split('-');
    if (temp.length < 2) return false;
    temp[temp.length-1] = (parseInt(temp[temp.length-1])-960)/89;
    
    return temp;
}

export function encryptUrl(id) {
    return parseInt(id)*89 + 960;
    // return parseInt(phone).toString(32);
}



export async function getRemainingTimeString(unixTime) {
    const currentTime = await new GTime().getTime();
    const date = new Date(unixTime);
    let dayAndPostfix = date.getDate();
    // get the day postFix
    if (dayAndPostfix % 10 > 3 || (dayAndPostfix > 10 && dayAndPostfix < 14) || dayAndPostfix % 10 === 0) dayAndPostfix += "th ";
    else if (dayAndPostfix % 10 === 1) dayAndPostfix += "st ";
    else if (dayAndPostfix % 10 === 2) dayAndPostfix += "nd ";
    else if (dayAndPostfix % 10 === 3) dayAndPostfix += "rd ";
  
    // get month
    switch (date.getMonth()) {
      case 0: dayAndPostfix += "January"; break;
      case 1: dayAndPostfix += "February"; break;
      case 2: dayAndPostfix += "March"; break;
      case 3: dayAndPostfix += "April"; break;
      case 4: dayAndPostfix += "May"; break;
      case 5: dayAndPostfix += "June"; break;
      case 6: dayAndPostfix += "July"; break;
      case 7: dayAndPostfix += "August"; break;
      case 8: dayAndPostfix += "September"; break;
      case 9: dayAndPostfix += "October"; break;
      case 10: dayAndPostfix += "November"; break;
      case 11: dayAndPostfix += "December"; break;
      default: break;
    }
  
    // get year
    dayAndPostfix += ", " + date.getFullYear();
    
    return dayAndPostfix + ` ( ${getParsedDeltaTime(currentTime - date)} )`
    
}

function getParsedDeltaTime(deltaTime) {
    function getDeltaTime(deltaTime) {
        const deltaYears = Math.floor(deltaTime / (1000*60*60*24*365))
        const deltaMonths = Math.floor(deltaTime / (1000*60*60*24*30))
        const deltaDays = Math.floor(deltaTime / (1000*60*60*24));
        const deltaHours = Math.floor(deltaTime / (1000*60*60));
        const deltaMinutes = Math.floor(deltaTime / (1000*60));
        const deltaSeconds = Math.floor(deltaTime / (1000));
        
        if (deltaYears!==0) return [deltaYears, "year"]
        if (deltaMonths!==0) return [deltaMonths, "month"]
        if (deltaDays!==0) return [deltaDays, "day"]
        if (deltaHours!==0) return [deltaHours,"hour"]
        if (deltaMinutes!==0) return [deltaMinutes, "minute"]
        return [deltaSeconds, "second"]
    }
    let [delta, label] = getDeltaTime(deltaTime);
    if (delta===1) return delta + " " + label + " ago"
    return delta + " " + label + "s ago"
}





export class GTime {
    constructor() {
        this.unixTime = null;
        this.dateTime = null;
        this.time = null;
        this.promise = this.getTimeData();
    }

    async getTimeData() {
        let url = "https://worldtimeapi.org/api/timezone/Asia/Kathmandu";
        let time = await(await fetch(url)).json();

        let tempDateTimeSeconds = parseInt((time.datetime.split(":")[2].split("+")[0])%1 * 1000);
        this.unixTime = time.unixtime*1000 + tempDateTimeSeconds;
        this.dateTime = time.datetime;
        this.time = time;
        return time;
    }
    
    async getTime() {
        await this.promise;
        return this.unixTime;
    }
}






// PASSWORD tools
export const encryptPassword = (password) => {
    const hash = SHA256(password)
    return hash.toString();
}

export const getPasswordStrengthColor = (value) => {
    const colorLeft = [0xff, 0x82, 0x5B], colorRight = [0x8A, 0x9A, 0x5B];
    const final = [];
    for (let i = 0; i < 3; i++) final.push(parseInt(colorLeft[i] * (1 - value) + colorRight[i] * value).toString(16).padStart(2, '0'));
    return '#'+final.join('');
}

export const getPasswordStrength = (password) => {
    var lower=0, upper=0, number=0, special=0;
    if (!password) return 0;
    for (let i of password) {
        if (i >= '0' && i <= '9') number++;
        else if (i >= 'a' && i <= 'z') lower++;
        else if (i >= 'A' && i <= 'Z') upper++;
        else special++;
    }
    if (lower * upper * number * special === 0) return 0;
    return Math.min(lower + upper + number + special - 3, 10) / 10;
}