import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Register.css';
// Phone Number
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
// // Logo
import logo from '../../assets/navbar/logo.png';

// // Icons
import { Icon } from 'react-icons-kit';
import { loading } from 'react-icons-kit/ikons/loading'
// // Encrypt
import { SHA256 } from 'crypto-js';

// // api manager
import { postRequest } from '../../requestAndLocalStorage/requestAndLocalStorage';
// import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import PasswordSetter from '../../components/passwordSetter/passwordSetter';


// main component starts here
const RegisterEnterprise = () => {
  // fields
  const [name, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState(false);
  const [password, setPassword] = useState('');
  const [description, setDescription] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  // Errros
  const [showPasswordCheckbox, setShowPasswordCheckbox] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [hasErrors, setHasErrors] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setFieldErrors({});
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setFieldErrors({});
  };

  function encryptPassword(password) {
    const hash = SHA256(password)
    return hash.toString();
  }

  useEffect(() => {
    setHasErrors(validateForm());
  }, [name, email, password, phoneNumber])

  const validateForm = () => {

    const errors = {};

    // Check for empty fields
    if (name.trim() === '') {
      errors.firstName = 'First Name is required';
    }

    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email.trim())) {
      errors.email = 'Email is required';
      setConfirmEmail(false);
    }
    else {
      setConfirmEmail(true);
    }

    if (password.trim() === '') {
      errors.password = 'Password is required';
    }

    if (phoneNumber === '') {
      errors.phoneNumber = 'Phone Number is required';
    }

    // Password validation rules
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,}$/;
    if (!passwordRegex.test(password)) {
      errors.password = 'Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one numerical digit, and one special character (@#$%^&+=)';
    }

    // Password and Confirm Password must match
    if (!confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }

    // Phone number validation
    if (phoneNumber && phoneNumber.length !== 0 && !isPossiblePhoneNumber(phoneNumber) && !isValidPhoneNumber(phoneNumber)) {
      errors.phoneNumber = 'Invalid phone number';
    }

    // if (!isValidPhoneNumber(phoneNumber)) {
    //   errors.phoneNumber = 'Phone number is not valid';
    // }


    if (Object.keys(errors).length > 0) {
      // setFieldErrors(errors);
      return false;
    }
    return true;
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    const errors = {};

    // registration stuff
    try {
      setIsLoading(true);

      postRequest('/api/auth/registration/enterprise', {
        Email: email,
        Password: encryptPassword(password),
        Name: name,
        // Description: description.trim(),
        Phone: phoneNumber.substring(4)
      })
        .then(response => {
          setIsLoading(false);
          toast.info('Check your inbox for the verification link!');
          navigate('/');
        })
        .catch(error => {
          const err = {};
          if (error.response.data === "Email is already in use") {
            err.email = "This email is already in use";
            setFieldErrors(err);
          }
          setIsLoading(false);
        });

    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        errors.email = 'Email is already in use';
      } else if (error.code === 'auth/invalid-email') {
        errors.email = "Enter a valid email address"
      } else {
        console.error('Registration error', error);
        // Handle other registration errors
      }

      setFieldErrors(errors);
    }
  };

  const handleShowPasswordToggle = () => {
    setShowPasswordCheckbox((prevShowPassword) => !prevShowPassword);
  };


  return (
    <div className="register-container">

      <div className="register-form-container">
        <div className="logo-container">
          <img onClick={() => navigate('/')} src={logo} alt="Logos" className="logo" />
        </div>
        <h2>Create an Enterprise Account</h2>
        <form onSubmit={handleFormSubmit}>
          <div className="name-fields">
            <input
              type="text"
              placeholder="Enterprise Name"
              value={name}
              onChange={handleFirstNameChange}
            />
            {fieldErrors.firstName && <p className="error">{fieldErrors.firstName}</p>}
            {/* <input
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={handleLastNameChange}
            />
            {fieldErrors.lastName && <p className="error">{fieldErrors.lastName}</p>} */}
          </div>
          <div className='registry-email-field'>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
            />
            {(email.length !== 0) ? <div className={"correct-password-" + (confirmEmail ? "yes" : "no")}></div> : <></>}
          </div>
          {fieldErrors.email && <p className="error">{fieldErrors.email}</p>}
          <PasswordSetter
            setPasswordCallback={(e) => setPassword(e)}
            setPasswordConfirmCallback={(e) => setConfirmPassword(e)}
          />
          {fieldErrors.password && <p className="error">{fieldErrors.password}</p>}
          {fieldErrors.confirmPassword && <p className="error">{fieldErrors.confirmPassword}</p>}

          <div className="phone-number-field">
            <PhoneInput
              className='phone-input-select'
              defaultCountry='NP'
              country="NP"
              type="tel"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={setPhoneNumber}
            />
          </div>
          {fieldErrors.phoneNumber && <p className="error">{fieldErrors.phoneNumber}</p>}
          <button className="register-button" type="submit" disabled={isLoading || !hasErrors}>
            {isLoading ? (
              <Icon className='loading-icon' icon={loading} size={20} />
            ) : (
              'Register'
            )
            }
          </button>
        </form>
        <p>
          Already have an account? <Link to="/login">Sign in</Link>
        </p>
      </div>
    </div>
  );
};


export default RegisterEnterprise;
