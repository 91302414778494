import { useEffect, useRef, useState } from "react";
import "../../pages/gigRegistration/pages/thirdPage.css";

export default function DaysSelector({ data, setAvailabilityDayFnc, className, editable }) {
    // const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const [initIndex, setInitIndex] = useState(-1);
    const [finalIndex, setFinalIndex] = useState(-1);
    const [availableDays, setAvailableDays] = useState(['', '']);
    const [readHover, setReadHover] = useState(false);
    const mainContainer = useRef();

    const days = [
        {
            val: "Sunday",
            ind: 1,
            selection: '',
            ref: useRef(),
        },
        {
            val: "Monday",
            ind: 2,
            selection: '',
            ref: useRef(),
        },
        {
            val: "Tuesday",
            ind: 3,
            selection: '',
            ref: useRef(),
        },
        {
            val: "Wednesday",
            ind: 4,
            selection: '',
            ref: useRef(),
        },
        {
            val: "Thursday",
            ind: 5,
            selection: '',
            ref: useRef(),
        },
        {
            val: "Friday",
            ind: 6,
            selection: '',
            ref: useRef(),
        },
        {
            val: "Saturday",
            ind: 7,
            selection: '',
            ref: useRef(),
        },
    ];

    useEffect(() => {
        if (!data.AvailableDay)
            data.AvailableDay = '46';
        if (!data.AvailableTime)
            data.AvailableTime = ["07:30", "17:00"];
        let startIndex = -1, endIndex = -1;

        for (let i = 0; i < days.length; i++) {
            if (days[i].ind == data.AvailableDay[0]) { availableDays[0] = days[i].ind; startIndex = i; }
            if (days[i].ind == data.AvailableDay[1]) { availableDays[1] = days[i].ind; endIndex = i; }
        }

        setInitIndex(startIndex);
        setFinalIndex(endIndex);

        if (startIndex !== -1 && endIndex !== -1) {
            addClassNames(startIndex, endIndex);
        }

        resized();
        window.addEventListener('resize', resized);
        return () => {
            window.removeEventListener('resize', resized);
        }
    }, [])

    const resized = () => {
        const offSetWidth = mainContainer.current.offsetWidth;
        if (offSetWidth > 500) {
            mainContainer.current.className = "days-selector";
        }
        else if (offSetWidth > 300) {
            mainContainer.current.className = "days-selector days-selector-mobile";
        }
        else {
            mainContainer.current.className = "days-selector days-selector-mobile-small";
        }
        mainContainer.current.className += " " + className;
    }

    function isHighlighted(index, init, final) {
        if (init <= final) {
            return index >= init && index <= final;
        }
        return index <= final || index >= init;
    }

    function dayClick(e, day, index) {
        e.preventDefault();
        if (initIndex === -1 && finalIndex === -1) {
            day.ref.current.className = "gig-setup-day-select";
            setInitIndex(index);
            setFinalIndex(index);

            availableDays[0] = days[index].ind;
            availableDays[1] = days[index].ind;

            setAvailabilityDayFnc(availableDays);

            setReadHover(true);
            return;
        }


        if (initIndex === index || finalIndex === index) {
            setInitIndex(-1);
            setFinalIndex(-1);

            availableDays[0] = null;
            availableDays[1] = null;

            setAvailabilityDayFnc(availableDays);

            setReadHover(false);
            for (let i = 0; i < days.length; i++) {
                days[i].ref.current.className = "";
            }
            return;
        }

        setFinalIndex(index);
        availableDays[1] = days[index].ind;

        setAvailabilityDayFnc(availableDays);

        setReadHover(false);
        addClassNames(initIndex, index);
    }

    function dayHover(e, day, index) {
        e.preventDefault();
        if (!readHover) return;
        addClassNames(initIndex, index);
    }

    function addClassNames(init, final) {
        for (let i = 0; i < days.length; i++) {
            days[i].ref.current.className = (isHighlighted(i, init, final) ? "gig-setup-day-select" : "") + (!editable ? " gig-day-not-selectable" : "");
        }
        days[init].ref.current.className += " init-day ";
        days[final].ref.current.className += " final-day ";
    }


    return (<div ref={mainContainer}>
        {days.map((day, index) =>
            <button
                ref={day.ref}
                key={index}
                disabled={ !editable }
                onClick={(e) => dayClick(e, day, index)}
                onMouseOver={(e) => editable && dayHover(e, day, index)}
            >
                {day.val}
            </button>
        )}
    </div>);
}