import { useEffect, useRef, useState } from 'react';
import NavBar from '../../components/navbar/navbar';
import './sessionProfile.css'

import TimePicker from '../../components/timeSelect/timePicker';
import { FaCheckCircle, FaInfoCircle, FaTimesCircle } from 'react-icons/fa'
import Preloader from '../../components/Preloader/preloader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { postRequest } from '../../requestAndLocalStorage/requestAndLocalStorage';
import { firebaseTools } from '../../DataContext/firebase1';
import { useData } from '../../DataContext/DataContext';
import NotFound from '../../Error/404Page';

import { GTime, encryptUrl } from '../../tools';

import { toast } from 'react-toastify';
import DatePickerCustom from '../../components/dateSelect/datePicker';

const SessionProfile = () => {
    const [timeDisabled, setTimeDisabled] = useState();
    const [isPageValid, setIsPageValid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [sessionData, setSessionData] = useState();
    const [commentData, setCommentData] = useState();

    // names and IDs
    const [userName, setUserName] = useState('');
    const [consultantName, setConsultantName] = useState('');
    const [consultantID, setConsultantID] = useState();
    const [userID, setUserID] = useState();
    const [consultantPhoneNumber, setConsultantPhoneNumber] = useState('');

    // profile pictures
    const [userProfile, setUserProfile] = useState('');
    const [consultantProfile, setConsultantProfile] = useState('');

    // time
    const [time, setTime] = useState(0);
    const [date, setDate] = useState(0);
    const [deltaTime, setDeltaTime] = useState(0);

    // links
    const [userLink, setUserLink] = useState('#');
    const [consultantLink, setConsultantLink] = useState('#');
    const [gigLink, setGigLink] = useState('#');

    // online activity
    const [consultantStatus, setConsultantStatus] = useState(false);
    const [userStatus, setUserStatus] = useState(false);

    const { currentUser, onlineClients, setSessionPageCallbackTrigger } = useData();

    const commentRef = useRef();
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setTimeDisabled(!(((sessionData?.Turn === 'C' && currentUser?.Source === 'consultant') ||
            (sessionData?.Turn === 'U' && currentUser?.Source === 'user')) && (sessionData.Status === "0")))
    }, [sessionData, currentUser])

    const getSessionData = () => {
        postRequest('/api/data/session/get_info', { sessionID: id })
            .then(response => {
                setIsLoading(false);

                if (!response.data[0]) return;

                setConsultantPhoneNumber(response.data[0].CPhoneNumber);
                setConsultantID(response.data[0].ConsultantID);
                setUserID(response.data[0].UserID);
                setConsultantLink(`/consultant/${response.data[0].CName.replace(' ', '-')}-${encryptUrl(response.data[0].ConsultantID)}`)
                setGigLink(`/gig/posted/${response.data[0].GigID}`)
                
                if (currentUser.Source === 'consultant') {
                    if (response.data[0].ConsultantID === currentUser.UID) {
                        setIsPageValid(true);
                        setConsultantProfile(currentUser.ProfilePic);
                    }
                }
                else if (currentUser.Source === 'user') {
                    if (response.data[0].UserID === currentUser.UID) {
                        setIsPageValid(true);
                        setUserProfile(currentUser.ProfilePic);
                    }
                }
                else {
                    return;
                }

                setConsultantName(response.data[0].CName);
                setUserName(response.data[0].UName);

                setDate(parseInt(response.data[0].Time));
                const tempDate = new Date(parseInt(response.data[0].Time));

                setTime((tempDate.getHours() + (tempDate.getHours() === 'PM' ? 12 : 0)) * 60 + tempDate.getMinutes());

                // =-=-=-=-=-=-=-=-= profile pics =-=-=-=-=-=-=-=-=-= //
                // gig thumbnail
                firebaseTools.getGIGProfilePic(response.data[0].GigID)
                    .then(thumbnailResponse => {
                        response.data[0].ProfilePic = thumbnailResponse;
                        setSessionData(response.data[0]);
                    })
                    .catch(error => console.error(error));
                if (currentUser.Source === 'consultant') {
                    firebaseTools.getUSRProfilePic(response.data[0].UserID)
                        .then(profilePic => setUserProfile(profilePic))
                        .catch(error => console.error(error));
                }
                else if (currentUser.Source === 'user') {
                    firebaseTools.getCONProfilePic(response.data[0].ConsultantID)
                        .then(profilePic => setConsultantProfile(profilePic))
                        .catch(error => console.error(error));
                }
            })
            .catch(err => console.error(err));
    }

    useEffect(() => {
        if (!currentUser?.Source) {
            setIsLoading(false);
            return;
        }

        if (currentUser?.Source === 'user') {
            setUserLink(currentUser.Link);
        }

        // get session data
        getSessionData();

        // get session comments
        getComments();

        setSessionPageCallbackTrigger((ID) => {
            if (parseInt(id)===parseInt(ID)) {
                getSessionData();
                getComments();
            }
        })
        // 5 minute interval
        // const commentFetchInterval = setInterval(() => {
        //     getComments();
        //     getSessionData();
        // }, 60 * 1000);

        return () => {
            setSessionPageCallbackTrigger(()=>{})
            // clearInterval(commentFetchInterval);
        }
    }, [])

    function formatDate(time) {
        const date = new Date(parseInt(time))
        // Get day, month, and year
        var day = date.getDate();
        var month = date.getMonth() + 1; // Month starts from 0
        var year = date.getFullYear();

        // Add leading zeros if necessary
        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;

        // Return formatted date string
        return day + '/' + month + '/' + year;
    }

    // function handling commenting
    async function postComment(e) {
        e?.preventDefault();
        if (!sessionData) return;

        if (commentRef.current) {
            if (!commentRef.current.value) return;
            if (!commentRef.current.value.trim()) return;
            if (commentRef.current.value.trim().length > 200) return;

            const time = await (new GTime()).getTime();

            postRequest('/api/data/session/comment/new', {
                SessionID: sessionData.ID,
                Comment: commentRef.current.value.trim(),
                TimeStamp: time,
                Type: (currentUser.Source === 'consultant') ? 'C' : 'U'
            })
                .then(response => {
                    getComments();
                })
                .catch(error => {
                    console.error(error);
                })
            commentRef.current.value = ""
        }
    }

    function getComments() {
        postRequest('/api/data/session/comment/get', { SessionID: id })
            .then(response => {
                setCommentData(response.data);
            })
            .catch(err => console.error(err));
    }

    // =-=-=-=-=-=-= status buttons -=-=-=-=-=-=-=-=-=-= //

    // rejecting/cancelling button
    function rejectSession(e) {
        e.preventDefault();
        postRequest('/api/data/session/cancel', { sessionID: id })
            .then(response => {
                toast.info('you have rejected this session');
                getSessionData();
            })
            .catch(error => {
                console.error(error);
            })
    }

    // accepting button
    function acceptSession(e) {
        e.preventDefault();
        postRequest('/api/data/session/confirm', { sessionID: id })
            .then(response => {
                toast.success('you have accepted this session');
                getSessionData();
            })
            .catch(error => {
                console.error(error);
            })
    }

    // rescheduling button
    async function rescheduleSession(e) {
        e.preventDefault();
        if (!sessionData) return;
        // calculate timestamp
        const timeStamp = new Date(date);
        timeStamp.setHours(0);
        timeStamp.setMinutes(time);

        const temp = { ...sessionData };
        temp.Turn = sessionData.Turn === 'C' ? 'U' : 'C';
        setSessionData(temp);

        postRequest('/api/data/session/update_time', {
            time: timeStamp.getTime(),
            ID: id,
            Turn: sessionData.Turn === 'C' ? 'U' : 'C'
        })
            .then(response => {
                toast.info('you have rescheduled this session')
                getSessionData();
                getComments();
            })
            .catch(error => {
                console.error(error);
            })
    }

    // reset session
    const resetSession = () => {
        postRequest('/api/data/session/reset', {sessionID: sessionData.ID})
            .then(() => {
                toast.error('session reset');
                getSessionData();
                getComments();
            })
            .catch((err) => {
                console.error("error", err)
            })
    }
    useEffect(() => {
        if (sessionData?.Status==='1') { // confirmed status
            if (currentUser.Source==='user' && (deltaTime < -5*60)) {
                resetSession();
                sessionData.Status='0';
            }
        }
    }, [deltaTime])

    const joinVideoChat = (e) => {
        e.preventDefault();

        const width = window.screen.width;
        const height = window.screen.height;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;
        // window.open('/session/v/'+id, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        window.open('/session/v/'+id, '_blank');
    }

    // UPDATING ONLINE STATUS
    useEffect(() => {
        setUserStatus(onlineClients.includes('user-'+userID));
        setConsultantStatus(onlineClients.includes('consultant-'+consultantID));
    }, [onlineClients, userID, consultantID]);


    if (isLoading) {
        return <Preloader />
    }

    if (!isPageValid) {
        return <NotFound />;
    }

    const formatDateHandler = (date) => {
        const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };

        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
        return formattedDate;
    }

    return (
        <>
            <NavBar />

            <div className='session-container'>
                <section className='session-section-left'>
                    <div className='session-status-join'>
                        <div className='session-status-title'>
                            <div className='session-status-wrapper'>
                                { sessionData?.Status === "0" && <p className='session-status-label session-status-pending'>Pending</p> }
                                { sessionData?.Status === "1" && <p className='session-status-label session-status-confirmed'>Confirmed</p> }
                                { sessionData?.Status === "2" && <p className='session-status-label session-status-ongoing'>Ongoing</p> }
                                { sessionData?.Status === "3" && <p className='session-status-label session-status-completed'>Completed</p> }
                                { sessionData?.Status === "4" && <p className='session-status-label session-status-rejected'>Rejected</p> }
                            </div>
                            <h3 className='session-header'><Link to={gigLink}>{sessionData?.Title} </Link></h3>
                        </div>
                        {
                            <button 
                                className="session-join-button" 
                                onClick={joinVideoChat}
                                disabled={!(sessionData?.Status=='1' || sessionData?.Status=='2')}
                            >
                                JOIN
                            </button>
                            // (sessionData?.Status === '1' || sessionData?.Status === '2') &&
                            // ((deltaTime < 15*60) ?
                            //     ((deltaTime < -5*60) ?
                            //         <button className="session-join-button" onClick={joinVideoChat}>Reschedule</button> :
                            //     <button className="session-join-button" onClick={joinVideoChat}>JOIN</button>) :
                            //     <></>)
                                
                        }
                        {/* <CountDownTimer
                            timeStamp={parseInt(sessionData?.Time)}
                            deltaTimeCallback={(e)=>{
                                setDeltaTime(e);
                            }}
                            show={(sessionData?.Status==='1'||sessionData?.Status==='2') && (deltaTime > 15*60)}
                        /> */}
                    </div>
                    <Link to={gigLink}>
                        { sessionData?.ProfilePic ?
                            <img className="session-thumbnail-img" src={sessionData?.ProfilePic} alt="thumbnail" />
                            : <div className="session-thumbnail-img image-preloader"></div>
                        }
                        {/* <img className='session-thumbnail-img' src={sessionData?.ProfilePic} alt='session-profile-pic' /> */}
                    </Link>
                    {/* </div> */}
                    <div className='session-time-container'>
                        <DatePickerCustom data={{ AvailableDay: sessionData?.AvailableDay }} disabled={timeDisabled} defaultValue={date} setDateCallback={(e) => setDate(e)} />
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            {date && <DatePicker disabled={timeDisabled} disablePast defaultValue={dayjs(date)} onChange={(e) => setDate(e.unix() * 1000)} />}
                        </LocalizationProvider> */}
                        <TimePicker disabled={timeDisabled} className={{
                            container: 'time-picker-container-session',
                            section: 'time-picker-section-session',
                            header: 'time-picker-header-session',
                        }} time={time}
                            callBack={setTime}
                        />
                    </div>
                    {
                        ((sessionData?.Turn === 'C' && currentUser?.Source === 'consultant') ||
                            (sessionData?.Turn === 'U' && currentUser?.Source === 'user')) && (sessionData.Status === "0") &&
                        <div className='session-button-div'>
                            <button className='session-approve' onClick={acceptSession}>Approve</button>
                            <button className='session-reschedule' onClick={rescheduleSession}>Reschedule</button>
                            <button className='session-cancel' onClick={rejectSession}>Cancel</button>
                        </div>
                    }
                </section>
                <section className='session-section-right'>
                    <section className='session-section-right-sub-div'>
                        <h1>Session Details</h1>
                        <div className='session-section-right-sub-div-div'>
                            <h2>Consultant</h2>
                            <Link to={consultantLink}>{sessionData?.CName}</Link>
                        </div>
                        <div className='session-section-right-sub-div-div'>
                            <h2>User</h2>
                            <Link to={userLink}>{sessionData?.UName}</Link>
                        </div>
                        <div className='session-section-right-sub-div-div'>
                            <h2>Time</h2>
                            <p> {sessionData?.Time && formatDateHandler(new Date(parseInt(sessionData?.Time)))}</p>
                        </div>

                    </section>
                    <section className='session-section-right-sub-div'>
                        <h1 className='discussion-header'>Discussion Panel</h1>
                        <div className='discussion-user-input'>
                            {
                                sessionData && (sessionData.Status === "0" || sessionData.Status === "1" || sessionData.Status === "2") &&
                                <div>
                                    <section className='each-discussion-user'>
                                        <Link to={currentUser.Link} className='active-client'>
                                            <img className='profile-img' src={currentUser?.ProfilePic} alt='currentuser-profile-pic'/>
                                        </Link>
                                        <div>
                                            <Link className='each-discussion-name' to={currentUser.Link}>{currentUser?.Name}</Link>
                                            <p className='each-discussion-date'>03/02/2022</p>
                                        </div>
                                    </section>
                                    <section className='each-comment-section'>
                                        <input className='textfield' placeholder='Your comment' ref={commentRef}
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter') {
                                                    postComment();
                                                }
                                            }}></input>
                                        <button
                                            onClick={postComment}
                                            className='discussion-submit-button'
                                        >Submit</button>
                                    </section>
                                </div>
                            }

                            <section className='discussion-section'>
                                {commentData?.map((data, index) => (
                                    <div key={index} className={'each-discussion ' + (data.Type === 'Y' ? 'each-discussion-success' : data.Type === 'N' ? 'each-discussion-warning' : data.Type === 'I' ? 'each-discussion-info' : '')}>
                                        <section className='each-discussion-user'>
                                            {data.Type === 'C' && <Link to={consultantLink} className={consultantStatus ? 'active-client' : 'inactive-client'}>
                                                <img className='profile-img' src={consultantProfile} alt='consultant-profile-pic'/>
                                            </Link>}
                                            {data.Type === 'U' && <Link to={userLink} className={userStatus ? 'active-client' : 'inactive-client'}>
                                                <img className='profile-img' src={userProfile} alt='user-profile-pic'/>
                                            </Link>}
                                            <div className='each-discussion-parent'>
                                                {
                                                    data.Type === 'C' ?
                                                        <Link to={consultantLink} className='each-discussion-name'>{consultantName}</Link> :
                                                            data.Type === 'U' ?
                                                                <Link to={userLink} className='each-discussion-name'>{userName}</Link> :
                                                            <p className='each-discussion-name'>System</p>
                                                }
                                                <p className='each-discussion-date'>{formatDate(data.TimeStamp)}</p>
                                            </div>
                                        </section>
                                        <p className='each-discussion-comment'>
                                            {data.Type === 'Y' ? <FaCheckCircle /> : data.Type === 'N' ? <FaTimesCircle /> : data.Type === 'I' ? <FaInfoCircle /> : ''}
                                            {data.Comment}
                                        </p>
                                    </div>
                                ))}
                            </section>
                        </div>
                    </section>
                </section>
            </div>
        </>
    )
}

export default SessionProfile;