import { useEffect, useRef, useState } from "react";
import Popup from "../Popup/popup"
import "./settings.css"
import { useData } from "../../DataContext/DataContext";
import PasswordSetter from "../passwordSetter/passwordSetter";
import { toast } from "react-toastify";
import { firebaseTools } from "../../DataContext/firebase1";
import { postRequest } from "../../requestAndLocalStorage/requestAndLocalStorage";
import { encryptPassword } from "../../tools";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faFloppyDisk, faVideoCamera } from "@fortawesome/free-solid-svg-icons";

export default function Setting({onClose}) {
    const [profilePic, setProfilePic] = useState();
    const [originalProfilePic, setOriginalProfilePic] = useState();

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordVerified, setNewPasswordVerified] = useState(false);
    const [resetTrigger, setResetTrigger] = useState();
    const { currentUser, reloadTrigger } = useData();
    const profileInputRef = useRef();

    useEffect(() => {
        setOriginalProfilePic(currentUser.ProfilePic);
        setProfilePic(currentUser.ProfilePic)
    }, []);


    // upload profile pic
    const handleImageUpload = async (e) => {
        try {
            const file = e.target.files[0];
            if (file) {
                if (file.size > 524880) {
                    toast.error("File size too large.")
                }
                else {
                    let url = URL.createObjectURL(file);
                    setProfilePic(url);
                }
            }
        }
        catch (err) {
            console.error(err);
        }
    };

    const confirmClick = async (e) => {
        if (!profileInputRef.current) return;
        if (!profileInputRef.current.value) return;
        
        const file = profileInputRef.current.files[0];
        if (file) {
            if (file.size > 5242880) {
                // setError('Please select an image file with a maximum size of 5 MB.');
                console.log('Please select an image file with a maximum size of 5 MB.');
                return;
            }
        
            // setUserImage(URL.createObjectURL(file));
            let url;
            switch (currentUser.Source) {
                case 'user': url = await firebaseTools.setUSRProfilePic(currentUser.UID, file); break;
                case 'consultant': url = await firebaseTools.setCONProfilePic(currentUser.UID, file); break;
                case 'enterprise': url = await firebaseTools.setENTProfilePic(currentUser.UID, file); break;
                default: break;
            }
            if (url) {
                toast.success("Profile Picture successfully updated.");
                setOriginalProfilePic(profilePic);
                reloadTrigger();
                onClose();
            }
            else {
                toast.error("Something went wrong.");
            }
        }
    }

    // PASSWORD FIELD
    const passwordUpdate = () => {
        if (currentPassword === newPassword) {
            toast.error("New Password cannot be old password.");
            return;
        }
        if (!newPasswordVerified) {
            toast.error("Confirm password did not match.");
            return;
        }

        postRequest('/api/data/resetPasswordWithPassword', {
            Type: currentUser.Source,
            Email: currentUser.Email,
            Password: encryptPassword(newPassword),
            Authentication: encryptPassword(currentPassword)
        })
            .then(response => {
                setCurrentPassword('');
                if (response.data.affectedRows === 1) {
                    toast.success("Password changed successfully.");
                    if (resetTrigger) resetTrigger();
                    onClose();
                }
                else {
                    toast.error("You entered incorrect password.");
                }
            })
            .catch(err => console.error(err));
    }


    return <div className="settings-main-wrapper">
        <button className="gig-cancel-button" onClick={onClose}>x</button>
        <div className="settings-main-container">
            <div className="settings-general-wrapper">
                <h2>General</h2>
                <div className="settings-general-container">
                    <div className="settings-profile-picture">
                        <label htmlFor="profile-input" className="profile-upload-label">
                            <img src={profilePic} className="settings-profile-pic" />
                        </label>
                        <br />
                        <input
                            id='profile-input'
                            type="file"
                            ref={profileInputRef}
                            onChange={handleImageUpload}
                            accept="image/*"
                        />
                        { originalProfilePic === profilePic ?
                            <label htmlFor="profile-input" className="settings-change-profile-button"><FontAwesomeIcon icon={faCamera} /></label>
                            :<button className=" settings-save-profile-button" onClick={ confirmClick }><FontAwesomeIcon icon={faFloppyDisk} /></button>
                        }
                    </div>
                    <div className="settings-general-details">
                        <div>
                            <h3>First Name</h3>
                            <input value={currentUser.Name.split` `[0]} disabled/>
                        </div>
                        <div>
                            <h3>Last Name</h3>
                            <input value={currentUser.Name.split` `[1]} disabled/>
                        </div>
                        <br />
                        <div>
                            <h3>Email</h3>
                            <input value={currentUser.Email} disabled/>
                        </div>
                        <div>
                            <h3>Phone</h3>
                            <input value={currentUser.Phone} disabled/>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="settings-privacy-wrapper">
                <h2>Privacy</h2>
                <div className="settings-privacy-container">
                    <div className="settings-privacy-current-password">
                        <h3>Old Password</h3>
                        <input type="password" onFocus={()=>{}} value={currentPassword} onInput={(e) => setCurrentPassword(e.target.value)} />
                    </div>
                    <PasswordSetter showLabel resetTrigger={setResetTrigger} setPasswordCallback={setNewPassword} setPasswordConfirmCallback={setNewPasswordVerified}/>
                    <button onClick={passwordUpdate} className="settings-change-password-button">Change</button>
                </div>
            </div>
        </div>
    </div>
}