import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import './chat.css';

import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';
import { firebaseTools } from '../../firebase1.js';

import { Icon } from 'react-icons-kit';
// import { ic_image_outline } from 'react-icons-kit/md/ic_image_outline';
// import { paperclip } from 'react-icons-kit/fa/paperclip';
// import { plus } from 'react-icons-kit/ionicons/plus';

import NavBar from '../../../components/navbar/navbar.js';
import MessageController from '../MessageContext/message.js';

// import Session from '../session/session.js'

import { encryptUrl } from '../../../tools.js';
import { GTime } from '../../../tools.js';
import Popup from '../../../components/Popup/popup.js';
// import GigDialog from './gigDialog';
import Preloader from '../../../components/Preloader/preloader.js';

import SendIcon from '../../../assets/send-icon.svg';

import NotFound from '../../../Error/404Page.js';
import { Tooltip } from '@mui/material';
import { useData } from '../../DataContext.js';
import { postRequest } from '../../../requestAndLocalStorage/requestAndLocalStorage.js';
// import MessagesController from '../MessagesController.js';
// import MessageCounter from '../MessageContext/MessageCounter.js';

var currentChatId = '';
const types = ["user", "consultant", "enterprise"];

// function to decode userTypes to words
function decodeType(type) {
    if (!type) return "";
    switch (type.toLowerCase()) {
        case "u": return "user";
        case "e": return "enterprise";
        case "c": return "consultant";
    }
    return "";
}
// function to encode userTypes to words
function encodeType(type) {
    switch (type.toLowerCase()) {
        case "user": return "u";
        case "enterprise": return "e";
        case "consultant": return "c";
    }
    return "";
}

// REACT COMPONENT START
const ChatList = () => {

    const { pathType, from, selectedGigId } = useParams();

    // ChatID
    const [activeChatId, setActiveChatId] = useState(null);
    const [currentUserId, setCurrentUserId] = useState(null);


    const [authorizedData, setAuthorizedData] = useState(false);
    const [isAuthorizedFB, setIsAuthorizedFB] = useState(true);
    const [contacts, setContacts] = useState([]);

    // usertypeId
    const [userId, setUserId] = useState({});
    const [consId, setconsId] = useState({});
    const [entId, setentId] = useState({});
    const [idAllData, setIdAllData] = useState({
        user: {},
        consultant: {},
        enterprise: {},
    });
    const IdRef = useRef(idAllData.consultant);
    const [currentList, setCurrentList] = useState({});

    // message input
    const [messageInput, setMessageInput] = useState('');
    const [messages, setMessages] = useState([]);

    // chat
    const [activeChatList, setActiveChatList] = useState(decodeType(pathType));
    const [currentController, setCurrentController] = useState(null);

    // dialogbox
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [showDetail, setShowDetail] = useState(false);

    // Gigdata
    const [selectedGig, setSelectedGig] = useState(null);
    const [gigData, setGigData] = useState([]);

    const [confirmeddata, setConfirmedData] = useState(null);
    const [initialRender, setInitialRender] = useState(false);


    const [initialLoaded, setInitialLoaded] = useState(false);
    const [preloaderActive, setPreloaderActive] = useState(false);
    const [show404, setShow404] = useState(false);

    const [mobileView, setMobileView] = useState(false);


    // const gigDialogRef = useRef(null);
    const [instantVideoChatEnabled, setInstantVideoChatEnabled] = useState(false);

    const chatBoxRef = useRef(null);

    const navigator = useNavigate();

    const [fromFirstMessage, setFromFirstMessage] = useState(true);

    const [lastChatHeight, setLastChatHeight] = useState(0);
    const { currentUser, currentUserData } = useData();

    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= RECEIVER =-=-=-=-=-=-=-=-=-=-=-=-=-=-=- //
    async function incomingMessageTrigger(id, value, Rtype) {
        const currentConsId = IdRef.current;
        if (!currentConsId) return;
        if (currentChatId === id) msgcompile(value);
        if (currentConsId[id]) {
            const temp = IdRef.current[id];
            temp.lastMessage = value[value.length - 1];

            setIdAllData((prev) => {
                let tempObj = Object.assign({}, prev);
                tempObj[Rtype][id] = temp;
                return tempObj;
            });
            setconsId({
                ...IdRef.current,
                [id]: temp,
            })

            // IdRef.current[id].lastMessage = value[value.length-1];

        }
    }
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- //

    useEffect(() => {
        if (!authorizedData) return;

        const getAllData = async () => {
            const temp_contact_data = { user: {}, consultant: {}, enterprise: {} };
            const temp = contacts;
            if (!contacts) return;
            const tempContacts = [...(temp ? temp : [])]
            if (from) tempContacts.push(pathType.toUpperCase() + from);

            for (let pseudoId of tempContacts) {
                let [FName, LName] = pseudoId.Name.split(' ');
                const temp_data =
                {
                    PhoneNumber: pseudoId.Phone,
                    FirstName: FName,
                    LastName: LName,
                    ProfilePic: '',
                    ChatController: new MessageController(
                        authorizedData.UID,
                        pseudoId.UID,
                        authorizedData.Source.toLowerCase(),
                        pseudoId.Type,
                        incomingMessageTrigger
                    ),
                    lastMessage: {time: 0}
                }
                // switch (pseudoId[0].toLowerCase()) {
                //     //         case "u": temp_contact_data.user[id] = temp_data; break;
                //     //         case "c": temp_contact_data.consultant[id] = temp_data; break;
                //     //         case "e": temp_contact_data.enterprise[id] = temp_data; break;
                // }
                switch (pseudoId.Type) {
                    case "user" : temp_contact_data.user[pseudoId.UID] = temp_data; break;
                    case "consultant" : temp_contact_data.consultant[pseudoId.UID] = temp_data; break;
                    case "enterprise" : temp_contact_data.enterprise[pseudoId.UID] = temp_data; break;
                }
            }
            setIdAllData(temp_contact_data);
            setconsId(temp_contact_data.consultant);

            //     let id = pseudoId.substr(1);
            //     // for rn consultant ko lagi matra kaam garxa
            //     let temp_data = {}
            //     var ref, pfp;
            //     switch (pseudoId[0].toLowerCase()) {
            //         case "u":
            //             ref = await firebaseTools.getUSRDocUsingDocID(id);
            //             pfp = await firebaseTools.getUSRProfilePic(id);
            //             break;
            //         case "c":
            //             ref = await firebaseTools.getCONDocUsingDocID(id);
            //             pfp = await firebaseTools.getCONProfilePic(id);
            //             break;
            //         case "e":
            //             ref = await firebaseTools.getENTDocUsingDocID(id);
            //             pfp = await firebaseTools.getENTProfilePic(id);
            //             break;
            //         default:
            //             setShow404(true);
            //             return;
            //     }
            //     const receiverRef = await ref.get();
            //     if (!receiverRef.exists) {
            //         setShow404(true);
            //         return;
            //     }
            //     const idData = receiverRef.data();

            //     temp_data =
            //     {
            //         PhoneNumber: idData.PhoneNumber,
            //         FirstName: idData.FirstName,
            //         LastName: idData.LastName,
            //         ProfilePic: pfp,
            //         ChatController: new MessageController(
            //             authorizedData.id,
            //             id,
            //             authorizedData.UserType,
            //             decodeType(pseudoId[0].toLowerCase()),
            //             incomingMessageTrigger
            //         ),
            //         lastMessage: {time: 0}
            //     }
            //     console.log(temp_data);

            //     switch (pseudoId[0].toLowerCase()) {
            //         case "u": temp_contact_data.user[id] = temp_data; break;
            //         case "c": temp_contact_data.consultant[id] = temp_data; break;
            //         case "e": temp_contact_data.enterprise[id] = temp_data; break;
            //     }
            // }
            // setIdAllData(temp_contact_data);
            // setconsId(temp_contact_data.consultant);
        }
        getAllData().then(()=>{
            setPreloaderActive(true);
        });
    }, [authorizedData, contacts]);


    // code after consId has been loaded with all the elements
    useEffect(() => {
        let type = decodeType(pathType);
        console.log('idalldata', idAllData, type);
        if (!type) {
            type = types[0];
            redirectChatClick(type);
        }
        if (initialLoaded === true) return;
        if (!idAllData[type][from]) return;

        if (from) {
            if (!idAllData[type][from]) return; // invalid case
            const currentObj = idAllData[type][from];

            var chatName = currentObj.FirstName;

            IdRef.current = idAllData[type];
            handleChat(from, chatName, type);
            setInitialLoaded(true);
        }
    }, [idAllData]);

    // initial loading...
    useEffect(() => {
        // new MessageCounter(currentUser, currentUserData);
        // if (!pathType) setShow404(true);
        postRequest('/api/data/contacts/consultant/', { ConsultantID: currentUser.UID })
            .then((response) => {
                setContacts(response.data);
            })
            .catch((error) => {
                console.error(error);
            })
        // const getCurrentUserId = async (user) => {
            try {
                // const profileDetails = user?.email ? await firebaseTools.getAuthorizedProfileDetails(user?.email) : {email: null};

                const profileDetails = currentUser;

                if (!profileDetails.Email) {
                    // 404 condition UNAUTHORIZED
                    // navigator("/login");
                    setShow404(true);
                    console.log("unauthorized");
                }
                const profileId = profileDetails.UID;
    
                setCurrentUserId(profileId);
                setAuthorizedData(profileDetails);
                setIsAuthorizedFB(!!profileId);
            }
            catch (err) {
                console.error(err);
                setShow404(true);
            }
        // };

        // firebase.auth().onAuthStateChanged(getCurrentUserId)
    }, [currentUser]);

    // SCROLL EFFECT
    useEffect(() => {
        // console.log("scroll-height", chatBoxRef?.current?.scrollHeight);
        // console.dir(chatBoxRef?.current?.scrollTop);
        let deltaScrolled = (chatBoxRef?.current?.scrollTop || 0) + chatBoxRef?.current?.offsetHeight + 10;

        if (deltaScrolled >= lastChatHeight)
            chatBoxRef?.current?.scrollTo(0, chatBoxRef?.current?.scrollHeight);
        setLastChatHeight(chatBoxRef?.current?.scrollHeight || 0);
    }, [messages]);

    // SET IdRef and currentList
    useEffect(() => {
        IdRef.current = idAllData[activeChatList];
        console.log(idAllData)
        console.log("Active chat list", activeChatList);
        console.log(IdRef.current)
        setCurrentList(IdRef.current)

    }, [idAllData]);

    const loadoffer = async (id) => {
        return IdRef.current[currentChatId].ChatController.allGigData[id];

    }

    const msgcompile = async (values) => {
        let messageArray = [];
        let pendingSessionId = {};
        
        IdRef.current[currentChatId].ChatController.setSeen();  // as the message has been seen / rendered

        if (IdRef.current[currentChatId].ChatController?.gigData)
            setGigData(IdRef.current[currentChatId].ChatController?.gigData);

        for (let index = 0; index < values.length; index++) {
            const obj = values[index];
            if (obj.sessionReq == null) {
                if (obj.type === 'sender') {
                    messageArray.push(
                        <div key={index} className="chat-message">
                            <span className="message-content">{obj.content}</span>
                        </div>
                    );
                } else {
                    messageArray.push(
                        <div key={index} className="receiver-chat-message">
                            <span className="receiver-message-content">{obj.content}</span>
                        </div>
                    );
                }
            // handle sessionRequest message
            } else if (obj.sessionReq) {
                // const gigId = obj.sessionReq.id;
                // const date = obj.sessionReq.date;
                // const time = obj.sessionReq.time;
                // const timeStamp = obj.sessionReq.timeStamp
                // const offerData = await loadoffer(gigId); // Retrieve the gig data
                // if (!offerData) {
                //     continue;
                // }

                // // sessionRequest validation
                // if (obj.sessionReq.status === "pending") {
                //     if (pendingSessionId[obj.sessionReq.id]) {
                //         const docID = `${obj.sessionReq.id}-${pendingSessionId[obj.sessionReq.id].sessionReq.timeStamp}`;
                //         console.log("need to delete this", docID)
                //         Session.deletePendingSession(docID)
                //     }
                //     pendingSessionId[obj.sessionReq.id] = obj;
                // }

                // const handleRejectClick = async () => {
                //     const docID = `${gigId}-${timeStamp}`;
                //     Session.deletePendingSession(docID);
                // }

                // const handleAcceptClick = async () => {
                //     const docID = `${gigId}-${timeStamp}`;
                //     const pendingdata = await Session.readPendingSession(docID);
                //     Session.addConfirmedSession(pendingdata);
                //     const confirmdata = await Session.readConfirmedSession(docID)
                //     setConfirmedData(confirmdata);
                //     Session.deletePendingSession(docID);
                // }

                // const handleRespondClick = async () => {
                //     setIsDialogOpen(true);
                //     const tGigData = IdRef.current[currentChatId].ChatController.allGigData;
                //     for (let gig of Object.keys(tGigData)) {
                //         if (gig === gigId) {
                //             setSelectedGig(gigId);
                //             navigator("./"+gigId);
                //         }
                //     }
                //     // setSelectedGig(gigId);

                //     setShowDetail(true);

                // }

                // messageArray.push(
                //     <div key={index} className={`attachment-${obj.type === 'sender' ? '' : 'receiver'}-chat-message`}>
                //         <div className={`${obj.type == 'sender' ? '' : 'receiver-'}attachment-card`}>
                //             <img src={offerData.image} alt={offerData.title} />
                //             <div className="attachment-details">
                //                 <div className="receiver-attachment-title">{offerData.title}</div>
                //             </div>
                //             <div className='session-description'>
                //                 <div className='date-time'>
                //                     <div className='date'>Date: {date}</div>
                //                     <div className='time'>Time: {time}</div>
                //                 </div>
                //                 <div className="receiver-attachment-price">Price: Rs. {offerData.price}</div>
                //             </div>
                //             {
                //                 obj.sessionReq.status === "confirmed" &&
                //                 <Link className="join-link-button" to={`/session/v/${gigId}-${date}-${time}`}>{obj.type === 'sender' ? 'Join' : 'Join'}</Link>
                //             }
                //             {
                //                 obj.type === "receiver" && obj.sessionReq.status === "pending" &&
                //                 <div className='attachment-buttons'>
                //                     <button className='accept-button' onClick={handleRejectClick}>Reject</button>
                //                     <button className='accept-button' onClick={handleRespondClick}>Respond</button>
                //                     {/* <buttton className='accept-button' onClick={handleAcceptClick}>Confirm</buttton> */}

                //                     <button className='accept-button' onClick={handleAcceptClick}>
                //                         {confirmeddata?.status === 'confirmed' ? 'Confirmed' : 'Confirm'}
                //                     </button>
                //                 </div>
                //             }
                //         </div>
                //         <span className="receiver-message-content">{obj.content}</span>
                //     </div>
                // );
            }
        }
        setMessages(messageArray);
    };






    const handleNavItemClick = (navItem) => {
        setActiveChatId(null); // Reset the active chat ID when switching chat lists
        setActiveChatList(navItem);

        IdRef.current = idAllData[navItem];
        setCurrentList(idAllData[navItem]);

        redirectChatClick(navItem);
    };

    const redirectChatClick = (type = "", chatId = "") => {
        if (activeChatId == chatId) { // closing selected button
            navigator("/messaging/" + encodeType(activeChatList));
            return;
        }
        navigator(`/messaging/${encodeType(type)}/${chatId}`)
        return chatId;
    }

    const handleChat = (chatId, chatName, type) => {
        // chat select
        if (chatId) {
            // change context
            currentChatId = chatId;
            setActiveChatId(chatId);

            setCurrentController(idAllData[type][chatId].ChatController);
            // idAllData[type][chatId].ChatController.setSeen();

            IdRef.current[chatId].ChatController.callTrigger();

            document.title = chatName;
        }
        // unselect chat
        else {
            setActiveChatId(null);
            currentChatId = null;
            setCurrentController(null);

            document.title = "Messages"
        }
        // }
    }

    const handleChatClick = (e, chatId, chatName, type) => {
        e.preventDefault();
        setInitialLoaded(false);

        handleChat(redirectChatClick(type, chatId), chatName, type);
    };

    const handleGigIconClick = (e) => {
        e.stopPropagation(); // Prevent the event from bubbling up to parent elements
        setInstantVideoChatEnabled(false);
        getGigId();
    };

    const instantVideoChatButton = (e) => {
        e.stopPropagation();
        setInstantVideoChatEnabled(true);
        getGigId();
    }

    const getGigId = async () => {
        setGigData(Object.values(currentController.gigData));
        setSelectedGig({});
        setShowDetail(false);
        setIsDialogOpen(true);
    };

    const handlefilesend = () => {
        console.log('Clicked file send');
    };

    const handleimagesend = () => {
        console.log('Clicked image send');
    };

    const handleMessageSend = () => {
        if (messageInput.trim() !== '') {
            const newMessage = {
                content: messageInput.trim(),
            };

            if (currentChatId == from) {
                if (fromFirstMessage) {
                    firebaseTools.addContacts(currentController.senderID, currentController.receiverID, authorizedData.UserType, activeChatList);
                    firebaseTools.addContacts(currentController.receiverID, currentController.senderID, activeChatList, authorizedData.UserType);
                    setFromFirstMessage(false);
                }
            }
            currentController.sendMessage(newMessage);

            // chatBoxRef?.current?.scrollTo(0, chatBoxRef?.current?.scrollHeight);
            setMessageInput('');
        }
    };

    const loadMore = (e) => {
        e.preventDefault();
        currentController.loadMore();
    };

    const getLastMessageDiv = (list, key) => {  // list = [user, enterprise, consultant], key = id of list :\
        console.log(idAllData);
        const lastMessage = idAllData[list][key].lastMessage;
        const messageSeen = idAllData[list][key].ChatController.seenReceiverMessage;

        if (lastMessage) {
            if (lastMessage.type == "sender") { // if last message was of sender (you)
                return (<div className='chat-last-message sender-last-message'>
                    you: {IdRef.current[key] && IdRef.current[key].lastMessage && IdRef.current[key].lastMessage.content}
                </div>)
            }
            else if (lastMessage.type == "receiver") {  // if last message was of receiver (seen logic must be implemented)
                return (<div className={'chat-last-message receiver-last-message ' + (!messageSeen ? "last-message-not-seen" : "")}>
                    { IdRef.current[key] && IdRef.current[key].lastMessage && IdRef.current[key].lastMessage.content }
                </div>)
            }
        }
        return <div className={'chat-last-message'}>loading...</div>
    }

    useEffect(()=>{
        if (Object.values(currentController?.gigData || {}).length > 0) {
            if (selectedGigId) {
                setSelectedGig(selectedGigId);
                setIsDialogOpen(true);
            }
        }
    }, [gigData]);


    // =-=-=-=-=-=-=-=-=-=-=-=-=- RESPONSIVE VIEW =-=-=-=-=-=-=-=-==-=-=-= //
    useEffect(() => {
        window.addEventListener("resize", onResize);
        onResize();
        return () => {
            window.removeEventListener("resize", onResize);
        }
    }, []);

    function onResize() {
        setMobileView(window.innerWidth < 1050);
    }
    // =-=-=-=-=-=-=-=-=-=--=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-==-- //
    
    
    if (show404) return <NotFound></NotFound>

    return (
        <div className="Messaging">
            {/* { isAuthorizedFB && <NotFound></NotFound> } */}
            { !preloaderActive && <Preloader></Preloader>}
            <NavBar data={authorizedData} signedIn={isAuthorizedFB} />
            <div className="chat-container">
                { (!mobileView || !activeChatId) && 
                    /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= SIDEBAR =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
                    <div className={"chat-list "+ (!mobileView && "chat-list-mobile-view")}>
                        {/* =-=-=-=-=-=-=-=-=-=-=-=-=--=-=-=-=-= SIDEBAR HEADER [ [MESSAGING]    [1][2] ] =-=-=-=-=-=-=-=-=-==-=-=- */}
                        <div className="message-header">
                            <h1 className="messaging-heading">Messaging</h1>
                            <nav className="messaging-nav-bar">
                                {/* NAV FOR [User, Consultant, Enterprise] */}
                                {
                                    authorizedData.Source && types.filter((val) => (val !== authorizedData.Source)).map((val, index) =>
                                        <button
                                            key={index}
                                            className={`nav-item ${activeChatList === val ? 'active' : ''}`}
                                            onClick={() => handleNavItemClick(val)}
                                        >
                                            {val}
                                        </button>
                                    )
                                }
                            </nav>
                        </div>
                        {/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= SIDEBAR - CONTACTLIST =-=-=-=-=-=-=-=-=-=-=-=-=- */}
                        <div className='chat-item-container'>
                            {/* {JSON.stringify(currentList)} */}
                        {
                            Object.keys(currentList) &&  Object.keys(currentList)
                            .sort((e1, e2)=> {
                                return currentList[e2]?.lastMessage?.time - currentList[e1]?.lastMessage?.time 
                            })
                            .map((key, index) => (
                                <div
                                    key={index}
                                    className={`chat-item ${activeChatId === key ? 'active' : ''}`}
                                    onClick={(e) => handleChatClick(e, key, currentList[key].FirstName, activeChatList)}
                                >
                                    <div className="chat-avatar">
                                        <img src={currentList && currentList[key].ProfilePic} alt={key.FirstName} />
                                    </div>
                                    <div className="chat-details">
                                        <div className="chat-sender">
                                            <div className="name">{currentList && `${currentList[key].FirstName} ${currentList[key].LastName}`}</div>
                                            {getLastMessageDiv(activeChatList, key)}
                                        </div>
                                    </div>
                                </div>
                            ))

                        }
                        </div>
                    </div>
                }

                { activeChatId && (
                    /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= CHAT BOX =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */
                    <div className="chat-outer-box">
                        <div className='Chat-heading'>
                            <div className='chat-heading-name'>
                                <img src={currentList[activeChatId].ProfilePic}></img>
                                <h2>{`${currentList[activeChatId].FirstName} ${currentList[activeChatId].LastName}`}</h2>
                            </div>
                            <div className="video-chat-container">
                                <button className="video-chat-button" onClick={instantVideoChatButton}>vchat</button>
                                {mobileView && <button onClick={()=>handleNavItemClick(activeChatList)}>Close</button>}
                            </div>
                        </div>
                        <div ref={chatBoxRef} className="chat-box blue-scrollbar">
                            <div className="chat-messages">
                                <div className="chat-message-container">
                                    {
                                        currentController.allLoaded ||
                                        <div className='load-more-container'>
                                            <button onClick={loadMore} className='load-more-button'>Load More</button>
                                        </div>
                                    }
                                    {messages}
                                </div>
                            </div>
                        </div>
                        <div className="chat-input">
                            <div className="icon-container">
                                <button className="icon-button" onClick={handleimagesend}>
                                    <p>img</p>
                                    {/* <Icon icon={ic_image_outline} className="icon" /> */}
                                </button>
                                <button className="icon-button" onClick={handlefilesend}>
                                    {/* <Icon icon={paperclip} className="icon" /> */}
                                    <p>paperclip</p>
                                </button>
                                {
                                    authorizedData.UserType !== "enterprise" && currentController.receiverType !== "enterprise" &&
                                    <Tooltip title="book a gig">
                                        <button className="icon-button" onClick={(e) => handleGigIconClick(e)}>
                                            <p>plus</p>
                                            {/* <Icon icon={plus} className="icon" /> */}
                                        </button>
                                    </Tooltip>
                                }
                            </div>
                            <div className="input-container">
                                <input
                                    type="text"
                                    placeholder="Type a message..."
                                    value={messageInput}
                                    onChange={(e) => { setMessageInput(e.target.value) }}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter")
                                            handleMessageSend(e);
                                    }}
                                />
                            </div>
                            <button className="Send-btn" onClick={handleMessageSend}>
                                <p>Send</p>
                                <img className='send-icon' src={SendIcon} />
                    
                            </button>
                        </div>
                        


                        {/* CALL GIG DIALOG */}
                        {/* { isDialogOpen && <>
                            <GigDialog 
                                // states
                                isOpen={isDialogOpen}
                                onClose={() => setIsDialogOpen(false)}
                                gigs={gigData}
                                // ref={gigDialogRef}
                                
                                showDetail={showDetail}
                                setShowDetail={setShowDetail}

                                instantVideoChat={instantVideoChatEnabled}
                                // user datas
                                authUserType={authorizedData.UserType}
                                currentUserId={currentUserId}
                                activeChatId={activeChatId}
                                initialSelectedGig={selectedGig}
                                
                                // chat related
                                addPendingSession = { Session.addPendingSession }
                                setSelectedGig    = { setSelectedGig }
                                sendMessage       = { (a,b="")=>currentController.sendMessage(a,b) }
                                
                                // urls
                                messageUrl    = { `/messaging/${pathType}/${from}` }
                                consultantUrl = { `/consultant/${IdRef.current[currentChatId].FirstName.toLowerCase()}-${IdRef.current[currentChatId].LastName.toLowerCase()}-${encryptUrl(IdRef.current[currentChatId].PhoneNumber)}`}
                            />
                        <div className='background-overlay'></div>
                        </>
                        } */}
                    </div>
                )}
                { (!activeChatId && !mobileView) && (
                    <div className='chat-outer-box empty-chat-box'>
                        <h1>No chat opened</h1>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ChatList;
