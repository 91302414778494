import React from 'react'

// import logo from '../assets/logo.png'
import logo from '../../../assets/logo_banner.png'
import { Link, useNavigate } from 'react-router-dom'

const Navigation = ({ showGetStarted=true }) => {
    const navigate = useNavigate();

    return (
        <>
            <nav className='navbar-container'>
                <section className='company-container'>
                    <img className='company-logo' src={logo} alt='company-logo' onClick={(e)=>navigate('/')}/>
                </section>

                <section style={{textAlign: 'right'}}>
                    { showGetStarted && <button className='navbar-button'  onClick={() => navigate("/userguide")} >Get Started</button>}
                    <Link className='navbar-login' to='/login'>Login</Link>
                </section>
            </nav>

        </>
    )
}

export default Navigation