import { useEffect, useRef, useState } from "react"
import SetupPage from "./components/setupPage";

export default function GigSetupThirdPage(props) {
    const [fields, setFields] = useState({});

    useEffect(() => {
        getFields();
    }, [props]);

    function getFields() {
        const fields = [];
        for (const field of Object.keys(props.data)) {
            if (field.includes("GP_")) fields[field] = props.data[field] || 0;
        }
        setFields(fields);
    }

    function changeHandler(e, field) {
        e.preventDefault();
        const temp = {...fields};
        temp[field] = (!fields[field]) ? 1 : 0;
        setFields(temp);
    }

    function nextPage(e) {
        e.preventDefault();
        props.nextPage(e, { ...fields });
        // if (ThirdPageList.AvailableTime[0] &&
        //     ThirdPageList.AvailableTime[1] &&
        //     ThirdPageList.AvailableDay[0] &&
        //     ThirdPageList.AvailableDay[1]) {  
        //         props.nextPage(e, {
        //             AvailableDay: `${ThirdPageList.AvailableDay[0]}${ThirdPageList.AvailableDay[1]}`,
        //             AvailableTime: encodeTime(ThirdPageList.AvailableTime[0]) + encodeTime(ThirdPageList.AvailableTime[1])
        //         });
        // }
        // else {
        //     alert("Please fill all the fields");
        // }
    }

    function previousPage(e) {
        e.preventDefault();
        props.previousPage(e, { ...fields })
        // if (ThirdPageList.AvailableTime[0] &&
        //     ThirdPageList.AvailableTime[1] &&
        //     ThirdPageList.AvailableDay[0] &&
        //     ThirdPageList.AvailableDay[1]) {
        //         props.previousPage(e, {
        //             AvailableDay: `${ThirdPageList.AvailableDay[0]}${ThirdPageList.AvailableDay[1]}`,
        //             AvailableTime: encodeTime(ThirdPageList.AvailableTime[0]) + encodeTime(ThirdPageList.AvailableTime[1])
        //         });
        // }
        // else {
        //     alert("Please fill all the fields");
        // }
    }

    return (
        <SetupPage next={nextPage} previous={previousPage} nextText={"Next"}>
            <h3>hello</h3>
            { fields && Object.keys(fields).map((field, index) => <div key={index}>
                <p>{field.split('_').slice(1).join(' ')}</p> : { fields[field] ? 
                    <input type="checkbox" checked onInput={(e) => changeHandler(e, field)} />:
                    <input type="checkbox" checked={false} onInput={(e) => changeHandler(e, field)} />
                }
            </div>)}
        </SetupPage>)
}