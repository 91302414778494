import { useEffect } from "react"
// import { postRequest } from "./requestAndLocalStorage/requestAndLocalStorage";
import { useData } from "./DataContext/DataContext";
import { useNavigate } from "react-router-dom";
// import Preloader from "./components/Preloader/preloader";
import LandingPage from "./pages/landing/LandingPage";

const Home = () => {
    // const [response, setResponse] = useState({});
    const { currentUser } = useData();
    const navigator = useNavigate();

    useEffect(() => {
        if (currentUser.Link) {
            navigator(currentUser.Link);
        }
        else {
            // navigator('/login');
            // <LandingPage />
        }
    }, [currentUser.Link, navigator]);

    return <LandingPage />
    // return <Preloader />
}

export default Home;