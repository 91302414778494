import { Link } from "react-router-dom";
// import { firebaseTools } from "../../firebase1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket, faCog, faVideoCamera } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
// import firebase from "firebase/compat/app";
// import AlgoliaSearchBar from "../../Algolia/algoliasearch";
// import Sidebar from "./sidebar";
import './navbar.css'
// import GTime from "../../globalTimer";
import video from '../../assets/video.svg'

// ASSETS
import logo from "../../assets/navbar/logo.png"
import { FaCheckCircle, FaInfoCircle, FaTimesCircle, FaRegBell, FaBell } from 'react-icons/fa'

// import hamburger from "../..//assets/navbar/hamburger.svg"

import { useData } from "../../DataContext/DataContext";
import Popup from "../Popup/popup";
import { logout } from "../../requestAndLocalStorage/requestAndLocalStorage";
import Setting from "../settings/settings";
// import Popup from "../../popup";
// import { useAuth } from "../../AuthContext";


export default function NavBar(props) {
    // const { currentUser, currentUserData, setMessageCounterTrigger } = useAuth();

    const profileDropDown = useRef();
    const [profileDropDownTrigger, setProfileDropDownTrigger] = useState(false);
    const navigate = useNavigate();

    // const greetings = ['Good Morning ', 'Good Afternoon ', 'Good Evening ', 'Good Night ']
    // const time = new Date().getHours();
    // const [greeting, setGreeting] = useState('');

    const [navSecondClass, setNavSecondClass] = useState('');
    const [user, setUser] = useState(null);
    const [numberSessions, setNumberSessions] = useState(0);
    const [userData, setUserData] = useState(null);
    const [notificationBellFill, setNotificationBellFill] = useState(false);
    const [notificationSessionFill, setNotificationSessionFill] = useState(false);

    const { currentUser, numberMessages, setCallBackTriggerCall, notifications } = useData();
    const [settingsPopupOpen, setSettingsPopupOpen] = useState(false);

    // const [sidebarOpen, setSidebarOpen] = useState(false);
    // const [messageLinkTo, setMessageLinkTo] = useState("");

    const [notificationOpen, setNotificationOpen] = useState(false);


    // const toggleSidebar = () => {
    //     setSidebarOpen(!sidebarOpen);
    // };

    // const handleTime = () => {
    //     if (time >= 0 && time < 12) {
    //         setGreeting(greetings[0]);
    //     } else if (time >= 12 && time < 18) {
    //         setGreeting(greetings[1]);
    //     } else if (time >= 18 && time < 21) {
    //         setGreeting(greetings[2]);
    //     } else if (time >= 21 && time < 0) {
    //         setGreeting(greetings[3]);
    //     }
    // }

    // async function getName(data) {
    //     try {
    //         setName(data.Name);
    //     } catch (e) {
    //         console.log('Error', e);
    //     }
    // }


    useEffect(() => {
        setUser(currentUser);
        setUserData(currentUser);

        setCallBackTriggerCall((n) => {
            setNumberSessions(n);
        });
    }, [currentUser])


    // useEffect(() => {
    //     // const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
    //     if (currentUser?.email) {
    //         setUser(currentUser);
    //         let data = currentUserData;
    //         setUserData(data);
    //         if (props.callBack) props.callBack(data);
    //         // getName(data);
    //         setMessageLinkTo(getMessageLink(data.UserType));
    //     } else {
    //         setUser(null);
    //         console.log("User data not found");
    //     }

    //     setMessageCounterTrigger((num) => {
    //         setNumberMessages(num);
    //     })

    //     // handleTime();
    //     // });

    //     // return () => unsubscribe();
    // }, [currentUserData, currentUser, props, setMessageCounterTrigger]);


    async function logoutTrigger() {
        logout();
        // firebaseTools.logout();
        navigate('/login');
    }

    function profilePageClick(e) {
        if (!profileDropDown.current) {
            setProfileDropDownTrigger(false);
            return;
        }

        if (profileDropDownTrigger) {
            profileDropDown.current.style.display = "none";
            setProfileDropDownTrigger(false);
        }
        else {
            profileDropDown.current.style.display = "block";
            setProfileDropDownTrigger(true);
        }
    }

    // const getMessageLink = (userType) => {
    //     if (userType === "consultant" || userType === "enterprise") return "/messaging/u";
    //     return "/messaging/c";
    // }


    // const handlemessageclick = () => {
    //     console.log("message to: ", messageLinkTo);
    //     navigate(messageLinkTo);
    // }


    // handle scroll event
    window.addEventListener("scroll", () => {
        if (window.scrollY > 15) {
            if (!navSecondClass) setNavSecondClass("navbar-scrolled-down")
        }
        else {
            if (navSecondClass) setNavSecondClass("")
        }
    });

    // Search
    // const handleSearch = () => {
    //     navigate(`/search/all/${encodeURIComponent(searchInput)}`);
    // }

    return (
        <>
            {/* upper navbar */}
            <nav className={"logo-nav "}>
                <Link to={'/'} className="home-link-logo">
                    <img className="logo" src={logo} alt="tackoom-logo" />
                    <h1 className="logo-header">tackoom</h1>
                </Link>

                <div className="navbar-middle-search">
                    {/* <AlgoliaSearchBar /> */}
                    {/* {!props.noSearch && <input className="search-bar" placeholder="search" />} */}
                    {/* {!props.noSearch && ( */}
                    {/* <input */}
                    {/* className="search-bar" */}
                    {/* placeholder="search" */}
                    {/* value={searchInput} // Bind the search input value to the state variable */}
                    {/* onChange={(e) => setSearchInput(e.target.value)} // Update the state variable on input change */}
                    {/* /> */}
                    {/* )} */}
                    {/* <button><FontAwesomeIcon icon={faSearch} onClick={handleSearch} /></button> */}
                    {/* <div className="greeting">
                        <img src='https://w7.pngwing.com/pngs/636/102/png-transparent-computer-icons-symbol-icon-design-morning-miscellaneous-logo-sunrise-thumbnail.png' alt='img'></img>
                        <p>{greeting}{name}</p>
                    </div> */}
                </div>

                <div className="navbar-right-buttons">
                    {currentUser.Source === 'user' || currentUser.Source === 'consultant' ? <>
                        <button className="navigation-button">
                            <div
                                onMouseEnter={() => setNotificationSessionFill(true)}
                                onMouseLeave={() => setNotificationSessionFill(false)}
                                style={{ position: 'relative', display: 'inline-block' }}

                            >
                                {/* <Link to={currentUser?.Source === 'user' ? "/user/session" : "/consultant/session"}>
                                    {!!numberSessions && <p className="new-message-counter-label">{numberSessions}</p>}
                                    {notificationSessionFill ?
                                        <FontAwesomeIcon className="profile-drop-down-session-icon" icon={faVideoCamera} />
                                        : <img className="navigation-session-empty-img" src={video} alt="notification-session-empty" />}
                                </Link> */}
                                <Link
                                    to={currentUser?.Source === 'user' ? "/user/session" : "/consultant/session"}
                                >
                                    {!!numberSessions && <p className="new-message-counter-label">{numberSessions}</p>}

                                    <FontAwesomeIcon
                                        className="profile-drop-down-session-icon"
                                        style={{
                                            position: 'absolute',
                                            transition: 'opacity 0.3s',
                                            opacity: notificationSessionFill ? 1 : 0,
                                            transform: 'translate(0, -2px)'
                                        }}
                                        icon={faVideoCamera}
                                    />
                                    <img
                                        className="navigation-session-empty-img"
                                        src={video}
                                        style={{
                                            transition: 'opacity 0.3s',
                                            opacity: notificationSessionFill ? 0 : 1,
                                        }}
                                        alt="notification-session-empty"
                                    />
                                </Link>
                            </div>
                        </button>
                        <button className="navigation-button" onClick={() => setNotificationOpen(true)}>
                            <div
                                onMouseEnter={() => setNotificationBellFill(true)}
                                onMouseLeave={() => setNotificationBellFill(false)}
                                style={{ position: 'relative', display: 'inline-block' }}
                                >
                                <FaRegBell style={{
                                    position: 'absolute',
                                    transition: 'opacity 0.3s',
                                    opacity: notificationOpen || notificationBellFill ? 0 : 1,
                                    fontSize: '23px',
                                    color: '#0952b3'
                                }} />
                                <FaBell style={{
                                    transition: 'opacity 0.3s',
                                    opacity: notificationOpen || notificationBellFill ? 1 : 0,
                                    fontSize: '23px',
                                    color: '#0952b3'
                                }} />
                            </div>
                            {!!notificationOpen &&
                                <Popup isOpen={notificationOpen} onClose={() => setNotificationOpen(false)} >
                                    <div className="navbar-notification-container">
                                        {notifications.map((notification, index) =>
                                            <Link
                                            to={"/session/" + notification.SessionID}
                                            className={"navbar-notification-div notification-" + (notification.Type === 'Y' ? 'success' : notification.Type === 'N' ? 'error' : notification.Type === 'I' ? 'info' : 'other')} key={index}
                                            >
                                                <div className="navbar-notification-icon">
                                                    {
                                                        notification.Type === 'Y' ? <FaCheckCircle /> :
                                                            notification.Type === 'N' ? <FaTimesCircle /> :
                                                                <FaInfoCircle />
                                                    }
                                                </div>
                                                <div className="navbar-notification-desc">
                                                    <h4>{notification.Title}</h4>
                                                    {
                                                        (notification.Type === 'C' || notification.Type === 'U') ?
                                                        <p>New Message</p>
                                                        : <p>{notification.Comment}</p>
                                                    }
                                                </div>
                                            </Link>
                                        )}
                                        <Link className={"navbar-notification-div"}>
                                            <h3>Account Successfully Registered</h3>
                                        </Link>
                                    </div>
                                </Popup>
                            }
                        </button>
                    </>
                    : <></>
                    }
                    {/* <button className="navigation-button" onClick={ ()=>currentUser && navigate(currentUser.Link) }>
                        <img src={chatIcon} alt="navbar-chaticon" onClick={handlemessageclick} />
                        {!!numberSessions && <p className="new-message-counter-label">{numberSessions}</p>}
                    </button> */}
                    {user?.UID &&
                        <div className="navigation-button">
                            <img src={userData?.ProfilePic} alt="navbar-profile-pic" className="navbar-profile-pic" onClick={profilePageClick} />
                            {/* {!!numberSessions && <p className="new-message-counter-label">{numberSessions}</p>} */}
                            <Popup isOpen={profileDropDownTrigger} onClose={profilePageClick} hideElements={false}>
                                <div className="profile-drop-down" ref={profileDropDown}>
                                    <Link to={userData?.Link} className="profile-drop-down-profile-div">
                                        <img src={userData?.ProfilePic} alt="drop-down-profile-pic" />
                                        <div className="profile-drop-down-profilepic-a">
                                            <p className="profile-drop-down-username">{userData?.Name}</p>
                                            <p className="profile-drop-down-source">{userData?.Source}</p>
                                        </div>
                                    </Link>
                                    {/* <Link to={userData?.Link}>Profile</Link> */}
                                    {/* <Link to={userData?.Link + '/dashboard'}>Dashboard</Link> */}
                                    <div className="profile-drop-down-menu">
                                        <button onClick={() => setSettingsPopupOpen(true)}><FontAwesomeIcon icon={faCog} className="profile-drop-down-settings-icon"/>Settings</button>
                                    </div>
                                    {/* {currentUser?.Source === 'user' && <Link to={"/user/session"}><FontAwesomeIcon className="profile-drop-down-session-icon" icon={faVideoCamera} />Sessions</Link>} */}
                                    {props.dashboard && <Link to={props.dashboard}>Dashboard</Link>}
                                    <div className="profile-drop-down-auth">
                                        {/* <Link to="/login">Switch Account</Link> */}
                                        <button onClick={logoutTrigger}><FontAwesomeIcon className="profile-drop-down-logout-icon" icon={faArrowRightFromBracket} />Logout</button>
                                    </div>
                                </div>
                            </Popup>
                        </div>
                    }
                    {!!(user?.UID) ||
                        <button className="navigation-button"><Link className="navigation-sign-in" to="/login">Sign In</Link></button>
                    }
                    {/* <Profile></Profile> */}
                </div>
            </nav>
            {/* settings popup */}
            {
                settingsPopupOpen &&
                <>
                    <Popup className="settings-popup" isOpen={settingsPopupOpen} onClose={() => setSettingsPopupOpen(false) }>
                        <Setting onClose={() => setSettingsPopupOpen(false)}></Setting>
                    </Popup>
                    <div className="background-overlay"></div>
                </> 
            }
            {/* solution for POSITION FIX */}
            <div className={"navbar-bg-color " + navSecondClass}></div>
            {!props.disableBgFiller && <div className="navbar-bg-filler"></div>}
            <div className="nav-filler"></div>
        </>);
}