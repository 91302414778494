
import firebase from 'firebase/compat/app';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faVideo, faVolumeHigh } from '@fortawesome/free-solid-svg-icons'
import 'firebase/compat/auth';
import "./video_chat.css"

import { useEffect, useRef } from "react";
import SessionDBTools from "./sessionDBTools";
import { Link, useNavigate, useParams } from "react-router-dom";
import { firebaseTools } from '../../../DataContext/firebase1';
import { useState } from 'react';
import { Rating } from '@mui/material';
import NotFound from '../../../Error/404Page';
// import Rating from '../ratingUI';

// required ASSETS'
// import Logo from "../../../assets/navbar/logo.svg"
// import VideoIcon from "../../../assets/video-icon.svg"
// import MicIcon from "../../../assets/mic-icon.svg"
import MuteIcon from "../../../assets/mute-mic-icon.svg"
import HangUpIcon from "../../../assets/hang-up-icon.svg"
import ChatIcon from "../../../assets/chat-icon.svg"
import Preloader from '../../../components/Preloader/preloader';
import { useData } from '../../../DataContext/DataContext';
import { getRequest, postRequest } from '../../../requestAndLocalStorage/requestAndLocalStorage';

const sessionTool = new SessionDBTools();
var initLoaded = false;

function VideoChat() {
    const { sessionId } = useParams();
    const [messageInput, setMessageInput] = useState("");
    const [messageList, setMessageList] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    // =-=-=-=-=-=-=-=-=-=-=- states =-=-=-=-=-=-=-=-=-=-=-=-=-= //
    const [isAuthorized, setIsAuthorized] = useState(true);
    const [initDataLoaded, setInitDataLoaded] = useState(false);
    const [joined, setJoined] = useState(false);
    const [connected, setConnected] = useState(false);
    const [disconnected, setDisconnected] = useState(false);

    const [currentMicState, setCurrentMicState] = useState(true);
    const [currentVidState, setCurrentVidState] = useState(true);
    const [chatOpen, setChatOpen] = useState(false);
    const [remoteMicOff, setRemoteMicOff] = useState(true);
    const [isConsultant, setIsConsultant] = useState(false);
    const [sessionEnded, setSessionEnded] = useState(false);
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= //

    
    // =-=-=-=-=-=-=-=-=-=-==-=-= DOC REF =-=-=-=-=-=-=-=-=-=-=-= //
    const [sessionRef, setSessionRef] = useState(null);
    const [sessionData, setSessionData] = useState({});
    const [gigRef, setGigRef] = useState(null);
    const [gigTitle, setGigTitle] = useState(null);
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- //

    const [lowBalanceWarning, setLowBalanceWarning] = useState(false);

    const timer = useRef();
    const messageBox = useRef();

    const localVideoRef = useRef();
    const remoteVideoRef = useRef();

    const [remoteVideoElement, setRemoteVideoElement] = useState();

    const localNoVideoRef = useRef();
    const remoteNoVideoRef = useRef();
    const localWaitingVideoRef = useRef();
    
    const videoCheckBox = useRef();
    const audioCheckBox = useRef();
    const phoneHangUp   = useRef();
    
    const [userProfilePic, setUserProfilePic] = useState('');
    const [remoteProfilePic, setRemoteProfilePic] = useState('');
    const [remoteId, setRemoteId] = useState('');

    const { currentUser } = useData();
    
    const [remoteStream, setRemoteStream] = useState(new MediaStream());

    // ------------------------------------------------------------------------------- | REVIEW |
    // var sessionRating = 0;
    const [sessionRating, setSessionRating] = useState(0);
    const reviewMsgRef = useRef();
    //-------------------------------------------------------------------------------------------
    

    
    // =-=-=-=-=-=--=-=-=-=-=-=-=-=-=-=-= USE EFFECTS =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- //
    // get session data
    useEffect(() => {
        if (!sessionId) return;

        postRequest('/api/data/session/get_info', {  sessionID: sessionId })
            .then(response => {
                setSessionData(response.data[0]);
            })
            .catch(error => {
                console.error(error);
            })
    }, []);

    // use session data
    useEffect(() => {
        if (!sessionData) return;
    }, [sessionData])


    useEffect(()=> {
        async function init() {
            const authData = currentUser;
            setUserProfilePic(authData.ProfilePic);
            // to get cameras before setup
            await handleNewStream();
            

            if (authData.Source === "consultant") {
                setIsConsultant(true);
                setRemoteProfilePic(await firebaseTools.getUSRProfilePic(sessionData.UserID))
                setRemoteId(sessionData.UserID);
            }
            else {
                setIsConsultant(false);
                setRemoteProfilePic(await firebaseTools.getCONProfilePic(sessionData.ConsultantID))
                setRemoteId(sessionData.ConsultantID);
            }


            // create new room
            // if (docRef.exists === true) {
                let room = await sessionTool.makeNewRoom({
                    docRef: {
                        exists: true,
                        data: sessionData,
                        id: sessionId,
                    },
                    id: authData.UID,
                    // stream: localStream,
                    streamCallBack: remoteStreamUpdate,
                    messageListener: handleNewMessage,
                    timerObj: timer.current,
                    onConnection: onConnection,
                    // clientRef: clientRef,
                    lowBalanceCallBack: lowBalanceCallBack,
                    hangUp: handleHangUp,
                    // price: sessionData.Price,
                }); // initiator


            setLoading(false);

            //     if (room === "unauthorized") setIsAuthorized(false);
            //     else setInitDataLoaded(true);
            // }

            
            initLoaded = true;
        }
        if (!sessionData || !Object.keys(sessionData).length) return;
        init();
        // firebase.auth().onAuthStateChanged(init);
        
        // videoCheckBox.current.addEventListener('change', handleNewStream);
        if (audioCheckBox.current)
            audioCheckBox.current.addEventListener('change', handleNewStream);
        
        setCurrentMicState(true);
        setCurrentVidState(true);

        // videoCheckBox.current.checked = false;
        if (audioCheckBox.current)
            audioCheckBox.current.checked = true;
        
        

    }, [initDataLoaded, sessionData]);
    // ----------------------------------------------------------------------------- //

    useEffect(()=>{
        sessionTool.timerObj = timer.current;
        // videoCheckBox.current.addEventListener('change', handleNewStream);
        if (audioCheckBox.current)
            audioCheckBox.current.addEventListener('change', handleNewStream);
        
        // setCurrentVidState(true);
        // setCurrentMicState(true);

        // videoCheckBox.current.checked = currentVidState;
        if (audioCheckBox.current)
            audioCheckBox.current.checked = currentMicState;
        
    }, [joined])
    // ----------------------------------------------------------------------------- //

    useEffect(()=>{
        renderStream();
    }, [connected])
    // =-=-=-=-=-=--=-=-=-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- //
    


    async function onConnection() {
        setConnected(true);
        console.log("hello worldasdkasjdasjkl");
        if (currentUser.Source !== 'user') return;

        postRequest('/api/data/session/start', {
            sessionID: sessionId
        })
            .then((response) => {
                console.log("session started");
            })
            .catch((err) => {
                console.log(err);
            })
    }

    // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- Create New Stream =-=-=-=-=-=-=-=-=-=-=-=-=-= //
    async function handleNewStream() {
        // let showVideo = videoCheckBox.current.checked;
        let showVideo = true;
        let showAudio = true;
        if (audioCheckBox.current)
            showAudio = audioCheckBox.current.checked;
        setCurrentMicState(showAudio);
        setCurrentVidState(showVideo);

        if (showVideo) {
            if (localVideoRef.current)
                localVideoRef.current.style.display = 'flex';
            if (localNoVideoRef.current)
                localNoVideoRef.current.style.display = 'none';
        }
        else {
            if (localVideoRef.current)
                localVideoRef.current.style.display = 'none';
            if (localNoVideoRef.current)
                localNoVideoRef.current.style.display = 'flex';
        }
      
        sessionTool.addStream(showVideo, showAudio).then(stream => {
            if (localVideoRef.current)
                localVideoRef.current.srcObject = stream
        }
        );
    }
    useEffect(()=>{
        handleNewStream();
    }, [joined])
    // =-=-=-=-=-=--=-=-=-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- //
    
    
    // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- Incoming Stream -=-=-=-=-=-=-=-=-=-=-=-=-=-= //
    function remoteStreamUpdate(stream) {        
        remoteStream.getTracks().forEach(track => {
            track.stop();
            // remoteVideoStream.removeTrack(track);
        });
        
        if (!remoteVideoRef.current) {
            setRemoteStream(stream);
            return; 
        }

        if (stream.getVideoTracks().length > 0) {
            remoteVideoRef.current.style.display = 'flex';
            remoteNoVideoRef.current.style.display = 'none';
        }
        else {
            remoteVideoRef.current.style.display = 'none';
            remoteNoVideoRef.current.style.display = 'flex';
        }

        let isMicOff = true;
        for (let track of stream.getAudioTracks()) {
            if (track) isMicOff = false;
            // console.log("incoming track: ", track)
        }
        setRemoteMicOff(isMicOff);
        remoteVideoRef.current.srcObject = stream;
        setRemoteStream(stream);
    }
    // =-=-=-=-=-=--=-=-=-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- //
    



    // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- Message Handlers =-=-=-=-=-=-=-=-=-=-=-=-=-= //
    function handleMessageSend(e) {
        e.preventDefault();
        let message = messageBox.current.value;
        if (!message.trim()) return; // handle empty message
        
        messageBox.current.value = "";
        handleNewMessage(message, false);
        if (connected)
            sessionTool.sendMsg(message);
    }
    function chatDivToggle(e) {
        e.preventDefault();
        setChatOpen(!chatOpen);
    }
    // ------------------------------------------------------------------------------ //

    function handleNewMessage(message, incoming=true) {
        setMessageInput("");
        setMessageList(prev=>[...prev, {message: message, incoming: incoming}]);
    }
    // =-=-=-=-=-=--=-=-=-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- //
    

    
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= DB-CALLBACK FUNCTIONS -=-=-=-=-=-=-=-=-=-=-=-= //
    function renderStream() {
        if (remoteVideoRef.current) {
            remoteVideoRef.current.srcObject = remoteStream;
        }
    }
    
    function lowBalanceCallBack() {
        setLowBalanceWarning(true);
    }

    function handleHangUp() {
        if (sessionTool.localStream) {
            sessionTool.localStream?.getTracks().forEach(track => {
                track.stop();
            });
        }
        // if (event)
        //     event.preventDefault();
        // sessionTool.leaveRoom();
        setDisconnected(true);
        sessionTool.leaveInterrupt();
    }
    function handleHangUpButton(event) {
        if (event) event.preventDefault();
    
        handleHangUp();
    }
    
    function clickedJoin() {
        setJoined(true);
        sessionTool.join();
        // setTimeout(sessionTool.join, 1000);
    }

    // useEffect(()=>{
    //     console.log("sessionRef = ", sessionRef);
    // }, [sessionRef]);
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- //
    
    
    
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= REVIEW CALLBACK FUNCTIONS =-=-=-=-=-=-=-=-=-=-=-= //
    function changeRating(event, changedRating) {
        setSessionRating(changedRating);
        // sessionRating = changeRating;
    }

    async function submitReview(e) {
        e.preventDefault();
        
        // error handling stuff
        if (sessionRating === 0 || reviewMsgRef.current.value.length === 0 ) {
            alert("some fields are incomplete");
            return;
        }
        
        // handle db write stuff

        postRequest('/api/data/session/reviews', {
            sessionID: sessionId,
            rating: sessionRating,
            review: reviewMsgRef.current.value
        })
            .then((response) => {
                setSessionEnded(true);
            })
            .catch((error) => console.error(error));
    }
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= //
    


    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= ON UNLOAD EVENT LISTENER =-=-=-=-=-=-=-=-=-=-= //
    function handleBeforeUnload(event) {
        event.preventDefault();
        // console.log(event);
        event.returnValue = '';
    }
    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
        // console.log("setting beforeunload event listener");

        return () => {
            sessionTool.leaveInterrupt();
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= //

    


    /*
     ::::::::   ::::::::    :::   :::   :::::::::   ::::::::  ::::    ::: :::::::::: ::::    ::: ::::::::::: :::::::: 
    :+:    :+: :+:    :+:  :+:+: :+:+:  :+:    :+: :+:    :+: :+:+:   :+: :+:        :+:+:   :+:     :+:    :+:    :+: 
    +:+        +:+    +:+ +:+ +:+:+ +:+ +:+    +:+ +:+    +:+ :+:+:+  +:+ +:+        :+:+:+  +:+     +:+    +:+         
    +#+        +#+    +:+ +#+  +:+  +#+ +#++:++#+  +#+    +:+ +#+ +:+ +#+ +#++:++#   +#+ +:+ +#+     +#+    +#++:++#++   
    +#+        +#+    +#+ +#+       +#+ +#+        +#+    +#+ +#+  +#+#+# +#+        +#+  +#+#+#     +#+           +#+    
    #+#    #+# #+#    #+# #+#       #+# #+#        #+#    #+# #+#   #+#+# #+#        #+#   #+#+#     #+#    #+#    #+#     
     ########   ########  ###       ### ###         ########  ###    #### ########## ###    ####     ###     ########       
    */

    /*
                                __  .__                 .__                  .___
     __ __  ____ _____   __ ___/  |_|  |__   ___________|__|_______ ____   __| _/
    |  |  \/    \\__  \ |  |  \   __\  |  \ /  _ \_  __ \  \___   // __ \ / __ | 
    |  |  /   |  \/ __ \|  |  /|  | |   Y  (  <_> )  | \/  |/    /\  ___// /_/ | 
    |____/|___|  (____  /____/ |__| |___|  /\____/|__|  |__/_____ \\___  >____ | 
            \/     \/                 \/                      \/    \/     \/ 
    */
    // if (!isAuthorized) {
    //     return <NotFound></NotFound>
    // }

    // if (!initDataLoaded) {
    //     return <Preloader></Preloader>
    // }

    /*
        .__       .__  __                                 
        |__| ____ |__|/  |_  ___________     ____   ____  
        |  |/    \|  \   __\ \____ \__  \   / ___\_/ __ \ 
        |  |   |  \  ||  |   |  |_> > __ \_/ /_/  >  ___/ 
        |__|___|  /__||__|   |   __(____  /\___  / \___  >
                \/           |__|       \//_____/      \/ 
    */
    if (!joined) {
        return (
        <div className="video-chat-startup">
            { loading && <Preloader /> }
            {/* {!initDataLoaded && <Preloader></Preloader>} */}
            <h1 className='session-title'>tackoom</h1>
            <div className='mega-container'>
                <div className="video-container-waiting">
                    <h1 className='session-gig-title'>{gigTitle}</h1>
                    <div className="local-video-container">
                        <div className='video-pfp' ref={localNoVideoRef}    >
                            <img src={userProfilePic} alt='user-profile-pic'/>
                        </div>
                        {/* <video ref={(videoElement)=> videoElement && (videoElement.srcObject = localStream)}/> */}
                        <video className="local-video-waiting" ref={localVideoRef} hidden autoPlay muted ></video>
                        <div className="button-container">
                            {/* <div>
                                <label htmlFor="video-checkbox" className={currentVidState ? "video-label-on" : ""}><img src={VideoIcon} className='video-icon'/></label> 
                                <input type='checkbox' name="video-checkbox" ref = {videoCheckBox} id='video-checkbox' />
                            </div> */}
                            {/* { isConsultant ||
                            <div>
                                <label htmlFor="audio-checkbox" className={currentMicState ? "audio-label-on" : ""}><img src={MicIcon} alt="MicIcon" className='mic-icon'/></label> 
                                <input type='checkbox' name="audio-checkbox" ref = {audioCheckBox} id='audio-checkbox' />
                            </div>
                            } */}
                        </div>
                    </div>
                </div>
                <div className="video-cta">
                    <h2>Join Now</h2>   
                    <button onClick={clickedJoin} className="join-button">Join</button>
                </div>
            </div>
        </div>)
    }

    /*
              .__    .___                    .__            __   
        ___  _|__| __| _/____  ____     ____ |  |__ _____ _/  |_ 
        \  \/ /  |/ __ |/ __ \/  _ \  _/ ___\|  |  \\__  \\   __\
         \   /|  / /_/ \  ___(  <_> ) \  \___|   Y  \/ __ \|  |  
          \_/ |__\____ |\___  >____/   \___  >___|  (____  /__|  
                      \/    \/             \/     \/     \/      
    */
   if (!disconnected) {

       return (
       <div className="video-chat">
           <div className='nav-bar'>
                <h1 className='session-title'>tackoom</h1>
               <div className='timer' ref={timer}></div>
           </div>
           <div className="streams">
               <div className="video-container">
                   <div className={"local-video-transition "+(connected ? "local-video-container" : "remote-video-container")}>
                       <div className='video-pfp' ref={localNoVideoRef}>
                           <img src={userProfilePic} alt='user-profile-pic'/>
                       </div>
                       <video className="local-video" ref={localVideoRef} hidden autoPlay muted ></video>
                   </div>
                   {
                       connected && 
                       <div className="remote-video-container">
                           <div className='video-pfp' ref={remoteNoVideoRef}>
                               <img src={remoteProfilePic} alt='remote-profile-pic'/>
                               {remoteMicOff && <img className='video-chat-mute-icon' src={MuteIcon} alt='mute-icon'></img>}

                           </div>
                           <video className="remote-video" ref={remoteVideoRef} hidden autoPlay ></video>
                       </div>
                   }
               </div>
               <div className={' messages-wrapper ' + (chatOpen ? "" : "messages-chat-closed")}>
                   <div className='message-container'>
                        <div>
                            {messageList && messageList.map((message, index) => 
                                <div key={index} className={message.incoming ? "incoming-message" : "outgoing-message"}>
                                    { message.message}
                                </div>
                            )}
                        </div>
                   </div>
                   <div className='message-input-container'>
                       <input 
                           type='text'
                           className='message-input'
                           ref={messageBox}
                           placeholder='Type your message here'
                           value={messageInput}
                           onChange={(e)=>{setMessageInput(e.target.value)}}
                           onKeyDown={(e) => {
                               if (e.key === "Enter")
                                   handleMessageSend(e);
                           }}
                       />
                   </div>
               </div>
            </div>
            <div className="button-container">
                {/* <div>
                    <label htmlFor="video-checkbox" className={currentVidState ? "video-label-on" : ""}><img src={VideoIcon} className='video-icon'/></label> 
                    <input type='checkbox' name="video-checkbox" ref = {videoCheckBox} id='video-checkbox' />
                </div> */}
                {/* <div>
                    <label htmlFor="audio-checkbox" className={currentMicState ? "audio-label-on" : ""}><img src={MicIcon} className='mic-icon'/></label> 
                    <input type='checkbox' name="audio-checkbox" ref = {audioCheckBox} id='audio-checkbox' />
                </div> */}
                <div>
                    <button className="hang-up" onClick={handleHangUpButton}><img src={HangUpIcon} alt='hangup-button' className='hang-up-icon'/></button>
                </div>
                <div>
                    <button className='session-chat-button' onClick={chatDivToggle}>
                    <img src={ChatIcon} alt='chat-icon' className='chat-icon'/>
                    </button>
                </div>
            </div>
            {remoteVideoElement}
            { lowBalanceWarning &&
                <div className='lowBalanceWarning popup'>
                    <h1>low balance warning</h1>
                    <p>please recharge your balance</p>
                    <button onClick={()=>setLowBalanceWarning(false)}>x</button>
                </div>
            }
       </div>)
   }

    /*
                        .___                              
      ____   ____    __| _/ ___________     ____   ____  
    _/ __ \ /    \  / __ |  \____ \__  \   / ___\_/ __ \ 
    \  ___/|   |  \/ /_/ |  |  |_> > __ \_/ /_/  >  ___/ 
     \___  >___|  /\____ |  |   __(____  /\___  / \___  >
         \/     \/      \/  |__|       \//_____/      \/ 
    */
    // if (isConsultant) {
    //     setSessionEnded(true);
    //     // navigate("/messaging/u/" + remoteId);
    // }

    return (<>
        {
            (sessionEnded || isConsultant) && <div className='session-ended'>
                <h1>Session Ended</h1>
                <button onClick={ ()=>navigate(`/session/${sessionId}`) }>
                    SESSION
                </button>
            </div>
        }
        { !sessionEnded && !isConsultant &&
        <div className='session-end-page-wrapper'>
            <div className='session-end-page'>
                <p>How did your session go?</p>
                {/* <Rating editable onInput={changeRating}></Rating> */}
                {/* <Rating onChange={changeRating}></Rating> */}
                <Rating 
                    onChange={changeRating}
                    precision={0.5}
                ></Rating>
                <div className='session-review'>
                    <p>Review</p>
                    <textarea ref={reviewMsgRef} placeholder='Write your review here.'></textarea>
                </div>
                <button onClick={submitReview}>Submit</button>
            </div>
        </div>
        }
    </>
    );
}


export default VideoChat;