import React, { useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import '../Register.css';

// // Logo
import logo from '../../../assets/navbar/logo.svg';

import { SHA256 } from 'crypto-js';
import { postRequest } from '../../../requestAndLocalStorage/requestAndLocalStorage';
import PasswordSetter from '../../../components/passwordSetter/passwordSetter';


const ResetPassword = () => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  // fields
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  // Errros
  const [passwordError, setPasswordError] = useState('');
  const [showPasswordCheckbox, setShowPasswordCheckbox] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    setPassword(e);
    setPasswordError('');
    setFieldErrors({});
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e);
    setPasswordError('');
    setFieldErrors({});
  };

  function encryptPassword(password) {
    const hash = SHA256(password)
    return hash.toString();
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const errors = {};

    // Check for empty fields
    if (password.trim() === '') {
      errors.password = 'Password is required';
    }

    // Password validation rules
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,}$/;
    if (!passwordRegex.test(password)) {
      errors.password = 'Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one numerical digit, and one special character (@#$%^&+=)';
    }

    // Password and Confirm Password must match
    if (!confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }
    
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    console.log(params);
    console.log(searchParams.get('email'))
    console.log(searchParams.get('OTP'))

    try {
      setIsLoading(true);
      // console.log(password, confirmPassword, params.token);
      if (!searchParams.get('email') || !searchParams.get('OTP')) throw new Error("No email or OTP");

      var request;
      if (params.token === 'user') {
        request = postRequest('/api/data/resetPasswordUser', {
          OTP: searchParams.get('OTP'),
          Email: searchParams.get('email'),
          Password: encryptPassword(password)
        });
        console.log(request);
      }
      else 
        request = postRequest('/api/auth/reset_password?token='+params.token, {
          Password: encryptPassword(password)
        })

      request.then(response => {
            console.log(response);
            setIsLoading(false);
            navigate('/', { replace: true });
        })
        .catch(err => {
            const error = { confirmPassword: "session expired" };
            setFieldErrors(error);
            setIsLoading(false);
            console.error(err);
        })

    //   postRequest('/api/auth/registration/user', {
    //     Email: email,
    //     Password: encryptPassword(password),
    //     Name: firstName + ' ' + lastName,
    //     Phone: phoneNumber.substring(4)
    //   })
    //     .then(response => {
    //       console.log(response)
    //       setIsLoading(false);
    //     })
    //     .catch(error => {
    //       const err = {};
    //       if (error.response.data === "Email is already in use") {
    //         err.email = "This email is already in use";
    //         setFieldErrors(err);
    //       }
    //       console.error(error);
    //       setIsLoading(false);
    //     });
      
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        errors.email = 'Email is already in use';
      } else if (error.code === 'auth/invalid-email') {
        errors.email = "Enter a valid email address"
      } else {
        console.error('Registration error', error);
        // Handle other registration errors
      }

      setFieldErrors(errors);
    }
  };

  const handleShowPasswordToggle = () => {
    setShowPasswordCheckbox((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="register-container">

      <div className="register-form-container">
        <div className="logo-container">
          <img src={logo} alt="Logos" className="logo" />
        </div>
        <h2>Change Password</h2>
        <form onSubmit={handleFormSubmit}>
          <PasswordSetter
            setPasswordCallback={ handlePasswordChange }
            setPasswordConfirmCallback={ handleConfirmPasswordChange }
          />
          {/* <div className="password-field">
            <input
              type={showPasswordCheckbox ? 'text' : 'password'}
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
            />
          </div> */}
          {/* {fieldErrors.password && <p className="error">{fieldErrors.password}</p>} */}
          {/* <div className="password-field">
            <input
              type={showPasswordCheckbox ? 'text' : 'password'}
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
          </div> */}
          {/* {fieldErrors.confirmPassword && <p className="error">{fieldErrors.confirmPassword}</p>} */}

          {/* <div className="show-password-checkbox">
            <label>
              Show Password
              <input
                type="checkbox"
                checked={showPasswordCheckbox}
                onChange={handleShowPasswordToggle}
              />
            </label>
          </div> */}

          <button className="register-button" type="submit" disabled={isLoading || !confirmPassword}>
            {isLoading ? (
              // <Icon className='loading-icon' icon={loading} size={20} />
              <p>loading</p>
            ) : (
              'Register'
            )
            }
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
