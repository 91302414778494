import React from 'react'
import rightArrow from '../../../assets/right-arrow.svg'
import leftArrow from '../../../assets/left-arrow.svg'

const FifthStep = ({ formData, setFormData, nextStep, prevStep }) => {
    return (
        <>
            <h3 className='step-header'>I am always eager to look into:</h3>
            <div className='form-container'>
                <div>
                    <input className='step-input' type="radio" id="form-scholarships" name="drone" value="form-scholarships" />
                    <label className='step-label' for="form-scholarships">Scholarships abroad</label>
                </div>
                <div>
                    <input className='step-input' type="radio" id="form-desired-cource" name="drone" value="form-desired-cource" />
                    <label className='step-label' for="form-desired-cource">Events regarding career opportunities</label>
                </div>
                <div>
                    <input className='step-input' type="radio" id="form-decide-college" name="drone" value="form-decide-college" />
                    <label className='step-label' for="form-decide-college">My choices in career</label>
                </div>
            </div>
            <div className='step-img-container'>
                <img onClick={prevStep} className='step-img' src={leftArrow} alt='right' />
                <img onClick={nextStep} className='step-img' src={rightArrow} alt='right' />
            </div>
        </>
    );
}

export default FifthStep;