import { useState } from "react"
import Slider from "../user/components/slider";
import RenderThumbnail from "../gig/thumbnail/getThumbnail";
import { useData } from "../../DataContext/DataContext";
import { toast } from "react-toastify";
import { getRequest, postRequest } from "../../requestAndLocalStorage/requestAndLocalStorage";
import Popup from "../../components/Popup/popup";
import { GTime } from "../../tools";
import { useNavigate } from "react-router-dom";
import DatePickerCustom from "../../components/dateSelect/datePicker";
import TimePicker from "../../components/timeSelect/timePicker";

export default function BookingPopup({time, gigs, onClose=()=>{}}) {
    const [selectedGig, setSelectedGig] = useState(false);
    const [gigProfile, setGigProfile] = useState();
    const [gigTitle, setGigTitle] = useState();
    const [bookConfirmation, setBookConfirmation] = useState();
    const { currentUser } = useData();
    const [currentUserEmail, setCurrentUserEmail] = useState();
    const navigator = useNavigate();

    const gigSelect = async (e) => {
        const gigID = e;
        setSelectedGig(gigID);
        for (const gig of gigs) {
            if (gig.ID == gigID) {
                setGigTitle(gig.Title);
                setGigProfile(gig.ProfilePic);
            }
        }
    }
    
    const bookClick = (e) => {
        e.preventDefault();
        console.log(selectedGig, time);
        if (!currentUser?.Source) unauthorizedBookingConfirmHandler();
        else authorizedBookingConfirmHandler();
    }

    
    async function unauthorizedBookingConfirmHandler() {
        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(currentUserEmail)) {
            toast.error("Invalid Email.");
            return;
        }
        await getRequest('/api/data/gig_unauthorized_request', {
            GigID: selectedGig,
            Email: currentUserEmail
        })
            .then(response => {
                toast.success("Email sent.");
                setBookConfirmation(false);
            })
            .catch(error => {
                setBookConfirmation(false);
            })
    }

    async function authorizedBookingConfirmHandler() {
        if (!(await validateTime())) return;

        if (!currentUser?.Source) {
            navigator('/login');
            toast.error("Login required");
            return;
        }

        postRequest('/api/data/session/create', {
            GigID: selectedGig,
            UserID: currentUser.UID,
            Time: time.getTime(),
        })
            .then(response => {
                if (response.data[0]) {
                    toast.success("Session created successfully");
                    toast.info("You are being redirected to session page");
                    navigator('/session/' + response.data[0].ID);
                }
            })
            .catch(err => {
                console.log(err);
                toast.error("Something went wrong");
            })
    }

    async function validateTime() {
        const tempDate = time;
    
        const currentDate = new Date(await (new GTime()).getTime());
        if (tempDate < currentDate) {
            toast.error("Invalid date");
            return false;
        }
        return true;
    }




    return <>
        <button className="gig-cancel-button booking-popup-cancel" onClick={onClose}>x</button>
        <div className="booking-popup-wrapper">
            <div className="booking-popup-container">
                <div className="booking-popup-time-wrapper">
                    <h3>Selected Time</h3>
                    <div className="booking-popup-time-container">
                        <DatePickerCustom defaultValue={time} disabled />
                        <TimePicker
                            time={time?.getHours()*60 + time?.getMinutes()}
                            className={{
                                container: 'time-picker-container-session',
                                section: 'time-picker-section-session',
                                header: 'time-picker-header-session',
                            }}
                            disabled
                        /> 
                    </div>
                </div>
                {/* { time.toString() } */}
                { Boolean(selectedGig) || <div>
                        <h3 className="booking-popup-gig-select-label">Select a gig to book</h3>
                        <Slider minWidth={230} >
                            { gigs && gigs.map((gig, index) => <RenderThumbnail key={index} data={gig} type="gig-selector" onClick={gigSelect} className='booking-gig-selector' />)}
                        </Slider>
                    </div>
                }
                { Boolean(selectedGig) && <div className="booking-popup-gig-information">
                    <h3>Selected Gig</h3>
                    <a href={`/gig/posted/${selectedGig}`} >
                        { gigProfile ?
                            <img className="booking-popup-gig-thumbnail" src={gigProfile} alt="thumbnail" />
                            : <div className="booking-popup-gig-thumbnail image-preloader"></div>
                        }
                    </a>
                    <a href={`/gig/posted/${selectedGig}`}><h3>{gigTitle}</h3></a>
                </div>}
            </div>
            {/* <button>Cancel</button> */}
            { Boolean(selectedGig) && 
                <button onClick={(e) => setBookConfirmation(true)} className="booking-popup-book-button">Book Session</button>
            }
        </div>

        {bookConfirmation && (
            <>
                <div className="background-overlay"></div>
                <Popup onClose={() => setBookConfirmation(false)} isOpen={bookConfirmation} className="gigpage-popup">
                    <button className="gig-cancel-button" onClick={() => setBookConfirmation(false)}>x</button>
                    <p>Do you want to confirm your booking?</p>
                    {
                        currentUser.Source ? <></> :
                        <div>
                            <p>Enter your email</p>
                            <input type='email' value={currentUserEmail} onChange={(e) => setCurrentUserEmail(e.target.value)} />
                        </div>
                    }
                    <button
                        className="gig-confirmation-button"
                        onClick={bookClick}
                    >
                        Confirm
                    </button>
                </Popup>
            </>
        )}
    </>
}