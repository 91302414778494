import { useEffect, useState } from "react";
import NavBar from "../../components/navbar/navbar";
import { getRequest, postRequest } from "../../requestAndLocalStorage/requestAndLocalStorage";
import { useData } from "../../DataContext/DataContext";
import { firebaseTools } from "../../DataContext/firebase1";
import { useNavigate } from "react-router-dom";
import NotFound from '../../Error/404Page';

import "./consultantSessions.css";
import GetGigComponent from "../../components/SessionContainer/sessionContainer";
import Preloader from "../../components/Preloader/preloader";
import EmptySessions from "../../assets/illustrations/Sessions-Not-Taken-Indication.png";
// import { getRemainingTimeString } from "../../tools";

const ConsultantSession = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [validPage, setValidPage] = useState(false);

    const [sessionList, setSessionList] = useState({});
    const [gigData, setGigData] = useState();

    const { currentUser, onlineClients } = useData();
    const [userList, setUserList] = useState([]);
    const [selectedUser, setSelectedUser] = useState();

    useEffect(() => {
        if (currentUser.Source !== "consultant") {
            setIsLoading(false);
        }

        postRequest('/api/data/session/get_all/consultant', { consultantID: currentUser.UID })
            .then(response => parseSessionData(response.data))
            .catch(err => console.error(err))

        getRequest('/api/data/gigs', { ConsultantID: currentUser.UID })
            .then(async response => {
                const gigs = {};
                for (const gig of response.data) {
                    gigs[gig.ID] = gig;
                    gigs[gig.ID].ProfilePic = await firebaseTools.getGIGProfilePic(gig.ID);
                }
                setGigData(gigs);
            })
            .catch(error => console.error(error))

    }, [currentUser]);

    useEffect(() => {
        const userListTemp = [...userList];
        for (const user of userListTemp) {
            if (onlineClients.includes(`user-${user[0]}`))
                user[3] = true
            else
                user[3] = false;
        }
        setUserList(userListTemp);
    }, [onlineClients]);

    const parseSessionData = async (data) => {
        const users = {};
        for (const session of data) {
            if (users[session.UID]) users[session.UID].push(session);
            else
                users[session.UID] = [session];
        }
        setSessionList(users);
        const userTable = [];   //  {   UID  |  UserName  |  ProfilePic  }
        for (const user of Object.keys(users)) {
            userTable.push([user, users[user][0].UserName, await firebaseTools.getUSRProfilePic(user), onlineClients.includes('user-' + user)]);
        }
        setUserList(userTable);
        setIsLoading(false);
        setValidPage(true);
    }

    const selectUser = (id) => {
        if (id === selectedUser) setSelectedUser(undefined);
        else setSelectedUser(id);
    }

    // // format date
    // function formatDate(time) {
    //     const date = new Date(parseInt(time))
    //     // Get day, month, and year
    //     var day = date.getDate();
    //     var month = date.getMonth() + 1; // Month starts from 0
    //     var year = date.getFullYear();

    //     // Add leading zeros if necessary
    //     day = day < 10 ? '0' + day : day;
    //     month = month < 10 ? '0' + month : month;

    //     // Return formatted date string
    //     return day + '/' + month + '/' + year;
    // }

    const renderSessions = (SStatus) => {
        const filteredSessions = (sessionList[selectedUser] || []).filter(data => data?.SStatus === SStatus).sort((a,b) => parseInt(a.Time) - parseInt(b.Time));

        if (filteredSessions.length < 1) {
            return <p className="consultant-session-status-p-no-session">No sessions</p>;
        }


        console.log(filteredSessions);

        return  filteredSessions.map((data) => (
            <div className="gig-wrapper" key={data.ID}>
                <GetGigComponent
                    gig={gigData[data.ID]}
                    handleSessionClick={() => navigate('/session/' + data.SID)}
                />
            </div>
        ));
    }

    if (isLoading) return <Preloader />
    if (!validPage) return <NotFound />
    return <>
        <NavBar />
        <div className="consultant-session-main-container">
            <div className="consultant-session-user-container">
                {(!userList || userList.length) === 0 ? <div>NO DATA</div> : userList?.map((user, index) => (
                    <div className={"consultant-session-users " + (user[0] === selectedUser ? "active-session-user" : "")} key={index} onClick={() => selectUser(user[0])}>
                        <div className={user[3] ? "active-client" : 'inactive-client'}>
                            <img src={user[2]} alt="user-img" />
                        </div>
                        <span className="consultant-session-username">{user[1]}</span>
                    </div>
                ))
                }
            </div>
            <div className="consultant-session-container-right">
                {(!userList || userList.length) === 0 ? <></> : userList?.filter(user => user[0]===selectedUser).map((user, index) => (
                    <div className="consultant-session-user-header" key={index} >
                        <div className={user[3] ? "active-client" : 'inactive-client'}>
                            <img src={user[2]} alt="user-img" />
                        </div>
                        <span className="consultant-session-username">{user[1]}</span>
                    </div>
                ))
                }
                <div className="consultant-session-data-container">
                    {/* {sessionList[selectedUser]?.length > 0 ? sessionList[selectedUser]?.map((data, index) => (
                        <div>
                            <div className="consultant-session-status" key={index}>
                                <p>Status: {data?.SStatus === "0" ? "Pending" :
                                    data?.SStatus === "1" ? "Confirmed" :
                                        data?.SStatus === "2" ? "Ongoing" :
                                            data?.SStatus === "3" ? "Completed" :
                                                data?.SStatus === "4" ? "Rejected" :
                                                    "ERROR"}
                                </p>
                                <p>{formatDate(data?.Time)}</p>
                            </div>
                            <GetGigComponent gig={gigData[data.ID]} handleSessionClick={() => navigate('/session/' + data.ID)} />
                        </div>
                    ))
                        : <div>Nothing to show</div>} */}
                    {sessionList[selectedUser]?.length > 0 ? (
                        <div>
                            <h3 className="consultant-session-status-p">Completed</h3>
                            <div className="consultant-session-status-container">
                                {renderSessions('3')}
                            </div>
                            <h3 className="consultant-session-status-p">Pending</h3>
                            <div className="consultant-session-status-container">
                                {renderSessions('0')}
                            </div>
                            <h3 className="consultant-session-status-p">Rejected</h3>
                            <div className="consultant-session-status-container">
                                {renderSessions('4')}
                            </div>
                            <h3 className="consultant-session-status-p">Ongoing</h3>
                            <div className="consultant-session-status-container">
                                {renderSessions('2')}
                            </div>
                            <h3 className="consultant-session-status-p">Confirmed</h3>
                            <div className="consultant-session-status-container">
                                {renderSessions('1')}
                            </div>
                        </div>
                    ) : <div className="consultant-session-empty-selection">
                        <img src={EmptySessions} />
                        <h3>Please select a user to view your sessions with them.</h3>
                    </div>}
                </div>
            </div>
        </div >
    </>
}

export default ConsultantSession;