import React from 'react'
import rightArrow from '../../../assets/right-arrow.svg'
import leftArrow from '../../../assets/left-arrow.svg'

const ThirdStep = ({ formData, setFormData, nextStep, prevStep }) => {
    return (
        <>
            <h3 className='step-header'> I can independently finance my studies</h3>
            <div className='form-container'>
                <div>
                    <input className='step-input' type="radio" id="form-yes" name="drone" value="enterprise" />
                    <label className='step-label' for="form-yes">Yes</label>
                </div>
                <div>
                    <input className='step-input' type="radio" id="form-no" name="drone" value="student" />
                    <label className='step-label' for="form-no">No</label>
                </div>
                <div>
                    <input className='step-input' type="radio" id="form-no" name="drone" value="student" />
                    <label className='step-label' for="form-no">Dependent On Family</label>
                </div>
            </div>
            <div className='step-img-container'>
                <img onClick={prevStep} className='step-img' src={leftArrow} alt='right' />
                <img onClick={nextStep} className='step-img' src={rightArrow} alt='right' />
            </div>
        </>
    );
}

export default ThirdStep