import React from 'react'
import rightArrow from '../../../assets/right-arrow.svg'
import leftArrow from '../../../assets/left-arrow.svg'

const FourthStep = ({ formData, setFormData, nextStep, prevStep }) => {
    return (
        <>
            <h3 className='step-header'> I rely on following for educational opportunities:</h3>
            <div className='form-container'>
                <div>
                    <input className='step-input' type="radio" id="form-canada" name="drone" value="form-canada" />
                    <label className='step-label' for="form-canada">Social Media</label>
                </div>
                <div>
                    <input className='step-input' type="radio" id="form-europe" name="drone" value="form-europe" />
                    <label className='step-label' for="form-europe">Teachers</label>
                </div>
                <div>
                    <input className='step-input' type="radio" id="form-india" name="drone" value="form-india" />
                    <label className='step-label' for="form-india">Parents</label>
                </div>
                <div>
                    <input className='step-input' type="radio" id="form-others" name="drone" value="form-others" />
                    <label className='step-label' for="form-others">Others</label>
                </div>
            </div>
            <div className='step-img-container'>
                <img onClick={prevStep} className='step-img' src={leftArrow} alt='right' />
                <img onClick={nextStep} className='step-img' src={rightArrow} alt='right' />
            </div>
        </>
    );
}

export default FourthStep;