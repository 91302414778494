import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
// import { }
import { getDatabase, ref, update, onValue, get, limitToLast, query, endBefore, orderByKey, limitToFirst, set, push, child, remove } from 'firebase/database'
import { doc, setDoc, getDoc, deleteDoc } from 'firebase/firestore';
import { GTime } from '../tools';

import EmptyProfile from "../assets/emptyPFP.jpg"



class FirebaseTools {
  // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= //
  constructor() {
    this.firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId:process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    };

    if (!firebase.apps.length) {
      this.app = firebase.initializeApp(this.firebaseConfig);
    }

    this.db = firebase.firestore();
    // this.messagesRef = firebase.database().ref('Messages');

    this.collectionROOM = this.db.collection('Room');


    this.error = "ERROR";

    this.messageLimiterSize = 100;
  }
  // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= //


   // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= //
   async getDocUsingType(collection, type, data) {
    let result = this.error;
    let curColl = undefined;

    switch (collection) {
      case "CSEC": curColl = this.collectionCSEC; break;
      default: return this.error;
    }
    if (type == "DocID") {
      result = await curColl.doc(data);
      if (result) return result;
    }
    await curColl
      .where(type, '==', data)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) result = querySnapshot.docs[0];
      })
      .catch((error) => console.error("ERROR: ", error));

    return result;
  }
  async getCSECDocUsingDocID(docID) { return await this.getDocUsingType("CSEC", "DocID", docID); }


  // =-=-=-=-=-=-=-=-=-=-=-=-=-=- IMAGE AND FILES FETCHING =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= //
  async getProfileImage(collection, ID) {
    let storageRef = firebase.storage().ref();
    let path = "";
    switch (collection) {
      case "CON": path = "Consultant/" + ID + "/Profile_Pic"; break;
      case "ENT": path = "Enterprise/" + ID + "/Profile_Pic"; break;
      case "USR": path = "User/" + ID + "/Profile_Pic"; break;
      case "GIG": path = "Gig/"  + ID + "/Thumbnail"; break;
    }
    var imageUrl;
    try {
      const imageRef = storageRef.child(path);
      try {
        imageUrl = await imageRef.getDownloadURL();
      }
      catch (e) {
        return EmptyProfile;
      }
    }
    catch (error) {
      // image not found
      throw EmptyProfile;
    }

    return imageUrl;
  }
  getENTProfilePic(ID) { return this.getProfileImage("ENT", ID); }
  getCONProfilePic(ID) { return this.getProfileImage("CON", ID); }
  getGIGProfilePic(ID) { return this.getProfileImage("GIG", ID); }
  getUSRProfilePic(ID) { return this.getProfileImage("USR", ID); }
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= //


  // =-=-=-=-=-=-=-=-=-=-=-=-=-=- IMAGE AND FILES UPLOADING -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= //
  async setProfileImage(collection, ID, file) {
    let storageRef = firebase.storage().ref();
    let path = "";
    switch (collection) {
      case "CON": path = "Consultant/" + ID + "/Profile_Pic"; break;
      case "ENT": path = "Enterprise/" + ID + "/Profile_Pic"; break;
      case "USR": path = "User/" + ID + "/Profile_Pic"; break;
      case "GIG": path = "Gig/"  + ID + "/Thumbnail"; break;
    }
    const imageRef = storageRef.child(path);
    await imageRef.put(file);
    const imageUrl = await imageRef.getDownloadURL();
    return imageUrl;
  }
  async setENTProfilePic(ID, file) { return await this.setProfileImage("ENT", ID, file); }
  async setCONProfilePic(ID, file) { return await this.setProfileImage("CON", ID, file); }
  async setGIGProfilePic(ID, file) { return await this.setProfileImage("GIG", ID, file); }
  async setUSRProfilePic(ID, file) { return await this.setProfileImage("USR", ID, file); }
  // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= //


  // -=-=-=-=-=-=-=-=-=-=-=-=- AUTHORIZATION PART -=-=-=-=-=-=-=-=-=-=-=-=-=--= //
  async getAuthorizedProfileDetails(email = "") {
    if (!email) { var email = this.getAuthorizedUser(); email = email.email; }
    // console.log("da", await this.getAuthorizedUser());
    if (!email) return {};
    // search through users first
    let tempRef = await this.getUSRDocUsingEmail(email);
    if (tempRef !== this.error) return await this.getDataFromRef("USR", tempRef);
    // search through consultants
    tempRef = await this.getCONDocUsingEmail(email);
    if (tempRef !== this.error) return await this.getDataFromRef("CON", tempRef);
    // search through enterprises
    tempRef = await this.getENTDocUsingEmail(email);
    if (tempRef !== this.error) return await this.getDataFromRef("ENT", tempRef);
  }
  // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= //



  // =-=-=-=-=-=-=-=-=-=-=-=-= SESSION TOOLS -=-=-=-=-=-=-=-=-=-=-=-=-=-=-= //
  async makeNewRoom(name) {
    var roomRef = this.collectionROOM.doc(name);
    if ((await roomRef.get()).exists) {
      return [roomRef, true];
    }
    roomRef.set({})
    return [roomRef, false];
  }
  // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= //
}




export const firebaseTools = new FirebaseTools();
// const app = initializeApp(firebaseTools.app);

export const realtimeDatabase = getDatabase(firebaseTools.app);
// initialize and export realtime database

export default firebaseTools.app;