import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

export default function DatePickerCustom({ data, defaultValue, setDateCallback, disabled }) {
    const [date, setDate] = useState(defaultValue);

    useEffect(() => {
        setDate(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        if (setDateCallback)
            setDateCallback(date);
    }, [date]);

    const dateOpen = () => {
        setTimeout(() => {
            const dayLabels = document.getElementsByClassName('css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel');
            // const dayLabels = document.getElementsByClassName('gig-message-button');
            for (let dayLabel of dayLabels) {
                const dom = document.createElement('span');
                dom.innerHTML = dayLabel.ariaLabel;
                dom.className = 'mui-day-label';
                dayLabel.appendChild(dom);
            }
            for (let i=parseInt(data.AvailableDay[1])%7; i!=(parseInt(data.AvailableDay[0])-1) % 7; i=(i+1) % 7) {
                const unavailableDays = document.querySelectorAll(`[aria-colindex='${i+1}']`);
                unavailableDays.forEach(unavailableDay => {
                    unavailableDay.classList.add('mui-unavailable-day');
                    const dom = document.createElement('span');
                    dom.innerHTML = 'unavailable';
                    dom.className = 'mui-unavailable-label';
                    unavailableDay.appendChild(dom);
                })
            }
        }, 10);
    }

    return <LocalizationProvider dateAdapter={AdapterDayjs}>
        {date ?
            <DatePicker
                onChange={(e) => setDate(e.unix() * 1000)}
                label="Enter Date"
                value={dayjs(date)}
                sx={{
                    my: 2,
                    color: 'blue',
                }}
                maxDate={dayjs().add(1, 'month')}
                onOpen={dateOpen}
                onMonthChange={dateOpen}
                onYearChange={dateOpen}
                disabled={disabled}
                disablePast
            /> :
            <DatePicker
                onChange={(e) => setDate(e.unix() * 1000)}
                label="Enter Date"
                sx={{
                    my: 2,
                    color: 'blue'
                }}
                maxDate={dayjs().add(1, 'month')}
                disabled={disabled}
                onOpen={dateOpen}
                disablePast
            />
        }
    </LocalizationProvider>;
}