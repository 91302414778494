import React, { useEffect, useState } from 'react';
import './timePicker.css';

function TimePicker(props) {
    const [hour, setHour] = useState(NaN);
    const [minute, setMinute] = useState(NaN);
    const [displayHour, setDisplayHour] = useState('');
    const [displayMin, setDisplayMin] = useState('');
    const [amPm, setAmPm] = useState('AM');

    useEffect(() => {
        if (!hour || !minute || !amPm) return;
        if (props.callBack) {
            if (hour === NaN || minute === NaN) {
                props.callBack(-1);
            }
            else {
                props.callBack((parseInt(hour)%12 + (amPm === 'PM' ? 12 : 0)) * 60 + parseInt(minute));
            }
        }
    }, [hour, minute, amPm]);

    useEffect(() => {
        if (!props.time) {
            setHour('');
            setMinute('');
            setDisplayHour('');
            setDisplayMin('');
            return;
        }
        const hour = parseInt(props.time / 60);
        if (hour % 12 === 0) {
            setHour(12);
            setDisplayHour(12);
        }
        else {
            setHour(hour % 12);
            setDisplayHour(hour % 12);
        }
        setMinute(props.time % 60);
        setDisplayMin(props.time % 60);
        setAmPm(hour >= 12 ? 'PM' : 'AM');
    }, [props.time])


    const hourChangeHandler = (e) => {
        const newHour = e.target.value;
        if (/^\d+$/.test(newHour) && parseInt(newHour, 10) >= 1 && parseInt(newHour, 10) <= 12) {
            setHour(newHour);
            setDisplayHour(newHour);
        } else if (newHour === '') {
            setHour(NaN);
            setDisplayHour('');
        } else {
            setHour(NaN);
            setDisplayHour('');
            console.log("Hour error")
        }

    };

    const minuteChangeHandler = (e) => {
        const newMinute = e.target.value;
        if (/^\d+$/.test(newMinute) && parseInt(newMinute, 10) >= 0 && parseInt(newMinute, 10) < 60) {
            setMinute(newMinute);
            setDisplayMin(newMinute);
        } else if (newMinute === '') {
            setMinute(NaN);
            setDisplayMin('');
        } else {
            setMinute(NaN);
            setDisplayMin('');
            console.log("Minute Error")
        }
    }

    return (
        <div className={props.className?.container}>
            <h1 className={props.className?.header}>Enter Time</h1>
            <section className={props.className?.section}>
                <input
                    disabled={props.disabled}
                    type="text"
                    value={displayHour}
                    onChange={hourChangeHandler}
                    className='time-picker-input'
                    placeholder='HH'
                />
                <span>:</span>
                <input
                    disabled={props.disabled}
                    type="text"
                    value={displayMin ? displayMin.toString().padStart(2, '0') : displayMin}
                    onChange={minuteChangeHandler}
                    className='time-picker-input'
                    placeholder='MM'
                />
                <div className='time-picker-meridian' >
                    <button
                        disabled={props.disabled}
                        className={`time-picker-meridian-button ${amPm === "AM" ? 'time-picker-meridian-button-active' : ''}`}
                        onClick={() => setAmPm('AM')}
                    >
                        AM
                    </button>
                    <button
                        disabled={props.disabled}
                        className={`time-picker-meridian-button ${amPm === "PM" ? 'time-picker-meridian-button-active' : ''}`}
                        onClick={() => setAmPm('PM')}
                    >
                        PM
                    </button>
                </div>
            </section>
        </div>
    );
}

export default TimePicker;
