import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

// import { DecryptPhoneNumber, EncryptPhoneNumber } from '../../decrypters';

// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';

import '../profiles.css';
import './enterprise_profile.css';
import './enterpriseprofile.css';

// import { FaPlus } from 'react-icons/fa';
import NotFound from '../../Error/404Page';
// import RenderThumbnail from '../../gigs/getThumbnail';
// import Navbar from '../../navbar/nav';
import NavigBar from '../../components/navbar/navbar';
// import Preloader from '../../../preloader';
import { Rating, Tooltip } from '@mui/material';
// import SessionReviewTool from '../../../session/sessionReview';

import addIcon from '../../assets/add-icon.png';
// import pencil from '../../../assets/pencil.png'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faEye } from '@fortawesome/free-solid-svg-icons'
import Footer from '../../components/footer/footer';
import Popup from '../../components/Popup/popup';

import { getRequest, postRequest } from '../../requestAndLocalStorage/requestAndLocalStorage';
import { decryptUrl, encryptUrl } from '../../tools';
import { firebaseTools } from '../../DataContext/firebase1';

import EmptyPFP from '../../assets/emptyPFP.jpg';
import RenderThumbnail from '../gig/thumbnail/getThumbnail';
import { useData } from '../../DataContext/DataContext';
import Preloader from '../../components/Preloader/preloader';
import { toast } from 'react-toastify';

// import ConstructionPopup from '../../../constructionPopup';
// import { useAuth } from '../../../AuthContext';


const EnterpriseProfile = () => {
  const { encrypted } = useParams();   // encrypted text fetched from path
  //   const [pathFirstName, pathLastName, pathPhoneNumber] = DecryptPhoneNumber(encrypted);
  const { currentUser, onlineClients } = useData();

  const [profileDBRef, setProfileDBRef] = useState('');
  const [name, setName] = useState('');
  const [valid, setValid] = useState(true);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userImage, setUserImage] = useState('');
  const [coverImage, setCoverImage] = useState('');
  const [bio, setBio] = useState('');
  const [members, setMembers] = useState('');
  const [memberStatus, setMemberStatus] = useState([]);

  const [userDescription, setUserDescription] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [error, setError] = useState('');
  const [enterprise, setEnterprise] = useState(false);
  const [profileID, setProfileID] = useState(0);

  const [mobileView, setMobileView] = useState(false);
  const [gigsComponent, setGigsComponent] = useState(false);
  const [consultantRating, setConsultantRating] = useState(0);

  
  
  // authorized data & state
  const [authorizedData, setAuthorizedData] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [isAuthorizedFB, setIsAuthorizedFB] = useState(true);
  
  
  const [posts, setPosts] = useState([]);
  const [newPost, setNewPost] = useState("");

  const [draftGig, setDraftGig] = useState([]);
  
  const [profRef, setProfRef] = useState({})
  const views = useRef(null);
  
  const [isLoaded, setIsLoaded] = useState(false)
  
  const navigate = useNavigate();
  const [viewCount, setViewCount] = useState(0);
  
  const [sessionReviews, setSessionReviews] = useState([]);
  const invitationRef = useRef();
  //   const { currentUser, currentUserData } = useAuth();
  
  
  /*
    ██ ███    ██ ██    ██ ██ ████████  █████  ████████ ██  ██████  ███    ██ 
    ██ ████   ██ ██    ██ ██    ██    ██   ██    ██    ██ ██    ██ ████   ██ 
    ██ ██ ██  ██ ██    ██ ██    ██    ███████    ██    ██ ██    ██ ██ ██  ██ 
    ██ ██  ██ ██  ██  ██  ██    ██    ██   ██    ██    ██ ██    ██ ██  ██ ██ 
    ██ ██   ████   ████   ██    ██    ██   ██    ██    ██  ██████  ██   ████ 
  */
  // invitation block
  const [invitation, setInvitation] = useState('');
  const [invitationError, setInvitationError] = useState('');
  const [addConsultantPopup, setAddConsultantPopup] = useState(false);


  useEffect(() => {
    setInvitationError('');
  }, [invitation, addConsultantPopup]);


  async function handleInvitation() {
    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(invitationRef.current.value.trim())) {
      console.error('invalid email request');
      return;
    }

    postRequest('/api/data/enterprise/send_invitation', {
      senderEmail: email.trim(),
      receiverEmail: invitation.trim()
    })
      .then(response => {
        toast.success("Invitation sent to consultant");
        setAddConsultantPopup(false);
      })
      .catch(err => {
        // setInvitationError('Consultant not found, ask them to create a consultanting account.');
        toast.error("Consultant not found");
        setInvitation('');
        invitationRef.current.value = '';
      });
  }


  const handleInvitationChange = (e) => {
    setInvitation(invitationRef.current.value);
  };

  /* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= */


  /*
    ██ ███    ██ ██ ████████ 
    ██ ████   ██ ██    ██    
    ██ ██ ██  ██ ██    ██    
    ██ ██  ██ ██ ██    ██    
    ██ ██   ████ ██    ██    
  */
  useEffect(() => {
    const decoded = decryptUrl(encrypted);
    if (!decoded) {
      setValid(false);
      return;
    }

    const name = decoded.slice(0, decoded.length-1).join(' ');
    
    if (name.toLowerCase() === currentUser.Name?.toLowerCase() && decoded[decoded.length-1] === currentUser.UID) {
      setAuthorized(true);
    }
    else {
      setAuthorized(false);
    }

    // main enterprise data
    getRequest(`/api/data/enterprise`, { Name: name, ID: decoded[decoded.length-1] })
      .then((response) => {
        const enterpriseData = response.data[0];

        if (!enterpriseData) return setValid(false);
        getGigs(enterpriseData.UID);
        getMembers(enterpriseData.UID);

        setName(name);
        setProfileID(enterpriseData.UID);
        setUserDescription(enterpriseData.Description);
        setEmail(enterpriseData.Email);
        setPhoneNumber(enterpriseData.PhoneNumber);
        
        // get enterprise profile picture
        firebaseTools.getENTProfilePic(enterpriseData.UID)
          .then((url) => {
            if (typeof(url) !== 'string') {
              setUserImage(EmptyPFP);
            }
            else {
              setUserImage(url);
            }
          })
          .catch((error) => {
            setUserImage(EmptyPFP);
          })        
      })
      .catch((error) => {
        setValid(false);
        console.error(error);
      })
  }, []);

  // get all the gigs for the current enterprise
  function getGigs(UID) {
    if (!UID) return;

    // getGigs
    getRequest('/api/data/enterprise/gig', { EnterpriseID: UID })
      .then(response => {
        setDraftGig(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // get all the members of the current enterprise
  function getMembers(UID) {
    getRequest('/api/data/enterprise/members', { EnterpriseID: UID })
      .then(async response => {
        const members = [];
        for (const member of response.data) {
          members.push({
            ...member,
            ProfilePic: await firebaseTools.getCONProfilePic(member.UID)
          })
        }
        setMembers(members);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // handle profile picture upload
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5242880) {
        setError('Please select an image file with a maximum size of 5 MB.');
        console.log('Please select an image file with a maximum size of 5 MB.');
        return;
      }

      setUserImage(URL.createObjectURL(file));
      let url = await firebaseTools.setENTProfilePic(profileID, file);

    }
  };

  
  // manually set component on change
  useEffect(() => {
    setGigsComponent(<GigsContent></GigsContent>)
  }, [draftGig, enterprise])
  
  useEffect(() => {
    // setConsultantRating((sessionReviews.reduce((prev, val) => prev + val.Rating, 0)) / sessionReviews.length)
  }, [sessionReviews])

  
  
  // for responsive UI
  function checkScreenWidth() {
    if (window.innerWidth < 768) {
      setMobileView(false);
    }
    else setMobileView(true);
  }

  useEffect(() => {
    window.addEventListener("resize", checkScreenWidth);
    checkScreenWidth();

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    }
  }, []);


  // ONLINE STATUS
  useEffect(() => {
    if (!members) return;
    const temp = [];
    members.forEach((member) => {
      temp.push({
        Name: member.Name,
        UID: member.UID,
        ProfilePic: member.ProfilePic,
        Status: onlineClients.includes('consultant-' + member.UID)
      })
    })
    setMemberStatus(temp);
  }, [onlineClients, members]);


  /*
     ██████  ██  ██████  ███████      ██████  ██████  ███    ███ ██████   ██████  ███    ██ ███████ ███    ██ ████████ 
    ██       ██ ██       ██          ██      ██    ██ ████  ████ ██   ██ ██    ██ ████   ██ ██      ████   ██    ██    
    ██   ███ ██ ██   ███ ███████     ██      ██    ██ ██ ████ ██ ██████  ██    ██ ██ ██  ██ █████   ██ ██  ██    ██    
    ██    ██ ██ ██    ██      ██     ██      ██    ██ ██  ██  ██ ██      ██    ██ ██  ██ ██ ██      ██  ██ ██    ██    
     ██████  ██  ██████  ███████      ██████  ██████  ██      ██ ██       ██████  ██   ████ ███████ ██   ████    ██    
  */
  function GigsContent() {
    return (
      <div className='gigs-content right-col'>
        {/* <div className='summary-container'>
          <div className='summary-section'>
            <h3 className='header'>Total Revenue</h3>
            <span className='total-revenue-span'>Rs. 15600.00</span><span className='revenue-span'> Rs. 1024.55 (Today)</span>
          </div>
          <div className='summary-section-bubble'>
            <h3 className='bubble-header'>Total Sessions</h3>
            <span className='bubble-span'>32</span>
          </div>
          <div className='summary-section-bubble'>
            <h3 className='bubble-header'>Scheduled Sessions</h3>
            <span className='bubble-span'>12</span>
          </div>
        </div> */}

        <div className='column contents'>
          <h2>Posted Gigs</h2>

          <div className='gigs-list'>
            {/* {posts.map((post, index) => ( */}
              {draftGig.map((post, index) =>  
                <RenderThumbnail
                  key={index}
                  data={{ ...post, OwnerImageURL: enterprise.ProfilePic }}
                  showENT={true}
                ></RenderThumbnail>
              )}
          </div>
        </div>
      </div>
    )
  }






  /*
    ███    ███  █████  ██ ███    ██      ██████  ██████  ███    ███ ██████   ██████  ███    ██ ███████ ███    ██ ████████ 
    ████  ████ ██   ██ ██ ████   ██     ██      ██    ██ ████  ████ ██   ██ ██    ██ ████   ██ ██      ████   ██    ██    
    ██ ████ ██ ███████ ██ ██ ██  ██     ██      ██    ██ ██ ████ ██ ██████  ██    ██ ██ ██  ██ █████   ██ ██  ██    ██    
    ██  ██  ██ ██   ██ ██ ██  ██ ██     ██      ██    ██ ██  ██  ██ ██      ██    ██ ██  ██ ██ ██      ██  ██ ██    ██    
    ██      ██ ██   ██ ██ ██   ████      ██████  ██████  ██      ██ ██       ██████  ██   ████ ███████ ██   ████    ██    
  */
  if (!valid) {
    // if (isLoaded) return (<Preloader />);
    return (<NotFound></NotFound>)
  }


  return (
    <>
      {isLoaded === true ? (
        <Preloader />
        // <p>loading</p>
      ) : (
        <>
          <NavigBar></NavigBar>
          <div className='row'>
            {/* first container */}
            <div className='col'>
              <div className='prof-container1'>
                <div className="profile-image active-client">
                  <img
                    className='profile'
                    src={userImage}
                    alt="User"
                    onClick={() => authorized && setShowPopup(true)}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
                {showPopup && authorized && (
                  <div className="upload-popup">
                    <h3>Upload Profile Picture</h3>
                    <input
                      type="file"
                      onChange={handleImageUpload}
                      accept="image/*"
                    />
                    <button onClick={() => setShowPopup(false)}>Close</button>
                  </div>
                )}

                <div className="username">
                  <p>{name}</p>
                </div>
                {/* <div className='bio'>Bio:</div> */}
                <div className='rating'>
                  {/* { sessionReviews.reduce((val)=>val.Review, 0) } */}
                  <Rating rerender={sessionReviews} value={consultantRating} precision={0.5} readOnly></Rating>
                  <p>{sessionReviews.length} Reviews</p>
                </div>
                <div className="joined-active-recommended">
                  {/* <p>Joined since: {signupDate.toDateString()}</p>
                <p>Active: {user ? 'Yes' : 'No'}</p> */}
                  {enterprise.FirstName &&
                    <>
                      {/* Works In: <Link className='link' to={enterprise.PhoneNumber ? `/enterprise/${enterprise.FirstName.toLowerCase()}-${enterprise.LastName.toLowerCase()}-${EncryptPhoneNumber(enterprise.PhoneNumber)}` : "#"}>
                        <div className='ent-cons-ref'><img src={enterprise.ProfilePic} width="20px" height="20px" /><p>{enterprise.FirstName + ' ' + enterprise.LastName}</p></div>
                      </Link> */}
                    </>
                  }

                </div>
                <div className="description">
                  <div className='desc'>
                    <h3>Description</h3>
                {/* <div className='desc-icon'>
                      <img src={pencil} alt='' />
                    </div> */}
                  </div>
                  <p> {userDescription} </p>
                </div>
                {/* {authorized || <Link className="get-in-touch" to={`/messaging/c/${profileID}`}>Send Message</Link>} */}
              </div>
              <div className='members'>
                <h3>Members</h3>
                <div className='members-wrapper'>
                  {memberStatus && memberStatus.map((obj, index) => (
                    <Link key={index} to={`/consultant/${obj.Name.replace(' ','-')}-${encryptUrl(obj.UID)}`} className='member'>
                      <div className={(obj.Status ? 'active-client' : 'inactive-client')}>
                        <img src={obj.ProfilePic} 
                        />
                      </div>
                      <div>
                        <div className='consultant-name'>{obj.Name}</div>
                        {/* <div>{obj.Description}</div> */}
                        <div>{obj.CompanyPosition}</div>
                      </div>
                    </Link>
                  ))}
                  {
                    authorized && <div className='member consultant-adder' onClick={()=>setAddConsultantPopup(true)}>
                      <div className='add-consultant-img'>
                        <img src={addIcon} alt='add consultant'></img>
                      </div>
                      <p className='consultant-name'>Add</p>
                    </div>
                  }
                </div>
              </div>

              {authorized && addConsultantPopup && 
                <Popup
                  isOpen={addConsultantPopup}
                  onClose={()=>setAddConsultantPopup(false)}
                  className="invitation"
                >
                  <div>
                    <Tooltip title="enter email of consultant" placement='bottom' disableInteractive>
                      <h3>Invite Consultant</h3>
                    </Tooltip>
                    <div className='consultant-invitation-section'>
                      <input className="consultant-email" placeholder='email of consultant' ref={invitationRef} onInput={handleInvitationChange} />
                      {
                        invitationError && <p className='error'>{invitationError}</p>
                      }
                      <button className="invite-btn" onClick={handleInvitation}>INVITE</button>
                    </div>
                  </div>
                </Popup>
              }
              { authorized && addConsultantPopup && 
                <div className='background-overlay'></div>
              }

              {mobileView || gigsComponent}
              <div className=''>
                <h3 className='review-title'>Reviews</h3>
                {
                  sessionReviews && sessionReviews.map((review, index) =>
                    <div className='review-block' key={index}>
                      <img src={review.ProfilePic} />
                      <div>

                        <p className='review-name'>{review.Name}</p>
                        <Rating defaultValue={review.Rating} readOnly></Rating>
                        <p>{review.Review}</p>
                      </div>
                    </div>
                  )
                }
                {
                  (!sessionReviews || Object.keys(sessionReviews).length === 0) &&
                  <p>No reviews yet</p>
                }
              </div>
            </div>
            {mobileView && gigsComponent}
          </div>

          {/* {authorized || <button className="get-in-touch">Get in touch</button>} */}
          {/* {authorized && <Link className="get-in-touch" to="./dashboard">Dashboard</Link>} */}
        </>
      )}
      <Footer showLinks={!isAuthorizedFB || authorizedData.UserType === "user"}></Footer>
      {/* <ConstructionPopup></ConstructionPopup> */}
    </>
  );

}
export default EnterpriseProfile;