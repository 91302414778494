// style
import "./slider.css"
// assets
import arrowLeft from '../../../assets/homepage/arrowLeft.svg'
import { useEffect, useState, useRef } from "react";

function Slider(props) {
    const [childWidth, setChildWidth] = useState(0);
    const [number, setNumber] = useState(0);
    const [leftDisable, setLeftDisable] = useState(true);
    const [rightDisable, setRightDisable] = useState(false);

    const childContainerRef = useRef(null);
    const prevButtonRef = useRef(null);
    const nextButtonRef = useRef(null);

    useEffect(() => {
        // setTimeout(resized, 5000);

        window.addEventListener("resize", resized);
        childContainerRef.current.addEventListener("scroll", () => scrolled());

        childContainerRef.current.scrollTo({
            left: 0,
            behavior: "smooth",
        });
        
        resized();
        setTimeout(resized, 2000);

        return () => {
            window.removeEventListener("resize", resized);
            childContainerRef.current?.removeEventListener("scroll", () => scrolled());
        };
    }, []);

    // useEffect(() => {
    //     scrolled();
    // }, [childWidth]);
    
    function scrolled() {
        const [childWidth, number] = getWidth();
        if (!childWidth[0]) return;
        const left = Math.round(childContainerRef.current.scrollLeft);

        // 2 px error
        if (left <= 2 ) {
            setLeftDisable(true);
            // prevButtonRef.current.classList.add('slider-button-disable');
        }
        else {
            setLeftDisable(false);
            // prevButtonRef.current.classList.remove('slider-button-disable');
        }
        // +2 for error
        if (left+2 >= childWidth*(props.children.length-number)) {
            setRightDisable(true);
            // nextButtonRef.current.classList.add('slider-button-disable');
        }
        else {
            setRightDisable(false);
            // nextButtonRef.current.classList.remove('slider-button-disable');
        }
    }

    function resized(e) {
        // setChildWidth(getWidth());
        scrolled();
    }



    // =-=-=-=-=-=-=-=-=-=-=-= GET WIDTH -=-=-=-=-=-=-=-=-=-=-= //
    function getWidth() {
        const width = Math.min(props.minWidth || 250, 250);
        const srcWidth = childContainerRef.current?.offsetWidth;
        if (!srcWidth) return [0, 0];
        let n = parseInt(srcWidth / width);
        if (srcWidth < srcWidth / n) {
            setNumber(1);
            setChildWidth(srcWidth);
            return [srcWidth, 1];
        }
        setNumber(n);
        setChildWidth(srcWidth / n);
        return [srcWidth / n, n];
    }

    // ==-=-=-=-=-=-=-=-=-=-=-=-=-=-= recent session scroll events -=-=-=-=-=-=-=-=-=-=-=-=-= //
    const handlePrevClick = () => {
        childContainerRef.current.scrollTo({
            left: childContainerRef.current.scrollLeft - childWidth,
            behavior: "smooth",
        });
    };

    const handleNextClick = () => {
        childContainerRef.current.scrollTo({
            left: childContainerRef.current.scrollLeft + childWidth,
            behavior: "smooth",
        });
    };
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= //



    return (<div className="slider-container">
        <button className="slider-left-button" disabled={leftDisable} onClick={handlePrevClick} ref={prevButtonRef} >
            <img src={arrowLeft} />
        </button>
        <div className="slider-children" ref={childContainerRef} >
            <div className="slider-screen">
                {props.children && props.children.map((child, index) => <div key={index} style={{ width: childWidth }}>
                    {child}
                </div>)}
            </div>
        </div>
        <button className="slider-right-button" disabled={rightDisable} onClick={handleNextClick} ref={nextButtonRef} >
            <img src={arrowLeft} style={{ transform: "rotate(180deg)" }} />
        </button>
    </div>)
}


export default Slider;