import { useData } from "../../DataContext/DataContext";
import CountDownTimer from "../CountDownTimer/countDownTimer";
import "./sessionCard.css";

const GetGigComponent = ({ gig, handleSessionClick }) => {
    const { currentUser } = useData();

    if (gig) {
        return (
            <div className='home-gig-card' onClick={() => handleSessionClick(gig)}>
                { gig?.ProfilePic ?
                    <img src={gig?.ProfilePic} alt={gig?.Title} />
                    : <div className="gig-thumbnail-img image-preloader"></div>
                }
                <h2>{gig?.Title}</h2>
                <div className='session-row-inner-div'>
                    <p>{gig?.Name}</p>
                    <p>{gig?.date}</p>
                </div>
                <div className='session-row-inner-div'>
                    {/* <p>Rs. {gig?.Price}</p> */}
                    {gig?.SStatus === '0' ?
                        <section className="countdown-timer-container"><span>PENDING</span></section>:
                        gig?.SStatus === '1' ?
                            <CountDownTimer timeStamp={gig?.Time}/>:
                            currentUser.Source!=='consultant' ?
                                <section className="countdown-timer-container"><span>RETAKE</span></section>
                            : <section className=""></section>
                        }
                </div>
                {/* <button onClick={() => handleSessionClick(gig)}>SESSION</button> */}
            </div>
        )
    }
    else {
        return (<div className='home-gig-card'>
            <h1>deleted gig</h1>
        </div>
        )
    }
}
export default GetGigComponent;