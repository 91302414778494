import React from 'react'
import NavBar from '../../../components/navbar/navbar';
import './consultant_dashboard.css'
// import { FaCalendar, FaEnvelopeOpen } from 'react-icons/fa'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    defaults
} from 'chart.js';
import { Line } from 'react-chartjs-2'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

defaults.maintainAspectRatio = false;
defaults.responsive = true;

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Revenue',
        },
    },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
    labels,
    datasets: [200, 400, 500],
};

const ConsultantDashboard = () => {
    return (
        <>
            <NavBar />
            <div className='dashboard-container'>
                <section className='dashboard-sidebar'>
                    <h4 className='sidebar-header'>Dashboard</h4>
                    <a href='/' className='sidebar-a'>Recent Searches</a>
                    <a href='/' className='sidebar-a'>My Gigs</a>
                    <a href='/' className='sidebar-a'>New Requests</a>
                    <a href='/calender' className='sidebar-a'>Calender</a>
                    <a href='/settings' className='sidebar-a'>Settings</a>
                    <div className='sidebar-customer'>
                        <h5 className='customer-header'>Customer Support</h5>
                        <p className='customer-p'>Tackoom's Customer Support team is just a call away to help you with anything</p>
                        <button className='customer-button'>Connect Now</button>
                        <a href='/' className='customer-terms'>Terms and Services</a>
                        <a href='/' className='customer-terms'>Privacy Policy</a>
                    </div>
                </section>
                <section className='dashboard-main'>
                    <section className='dashboard-summary'>
                        <div className='summary-section'>
                            <h3 className='header'>Total Revenue</h3>
                            <span className='total-revenue-span'>Rs. 15600.00</span><span className='revenue-span'> Rs. 1024.55 (Today)</span>
                        </div>
                        <div className='summary-section-bubble'>
                            <h3 className='bubble-header'>Total Sessions</h3>
                            <span className='bubble-span'>32</span>
                        </div>
                        <div className='summary-section-bubble'>
                            <h3 className='bubble-header'>Scheduled Sessions</h3>
                            <span className='bubble-span'>12</span>
                        </div>
                    </section>
                    <section className='dashboard-section'>
                        <div>
                            <div className='dashboard-inbox'>
                                <div className='inbox-top'>
                                    <h3 className='inbox-header'>Inbox</h3>
                                    <a href='/' className='view-span'>View Details</a>
                                </div>
                                <div>
                                    <p className='inbox-p'>Waiting for order#12345</p>
                                </div>
                                <div>
                                    <p className='inbox-p'>Customer support id#12345</p>
                                </div>
                                <div>
                                    <p className='inbox-p'>Waiting for order#12345</p>
                                </div>
                                <div>
                                    <p className='inbox-p'>Customer support id#12345</p>
                                </div>
                            </div>
                            <div className='dashboard-recent'>
                                <div className='recent-top'>
                                    <h3 className='recent-header'>Recent Activity</h3>
                                    <a href='/' className='view-span'>View Details</a>
                                </div>
                                <div>
                                    <p className='recent-p'>New Session Request</p>
                                    <button className='recent-button'>URGENT</button>
                                </div>
                                <div>
                                    <p className='recent-p'>Warning: New Login Location detected</p>
                                    <button className='recent-button'>URGENT</button>
                                </div>
                                <div>
                                    <p className='recent-p'>New gig "Career Counselling" added</p>
                                    <button className='recent-button'>NEW</button>
                                </div>
                                <div>
                                    <p className='recent-p'>Withdrawal successful from Nabil Bank</p>
                                    <button className='recent-button'>URGENT</button>
                                </div>
                            </div>
                        </div>
                        <div className='dashboard-chart'>
                            <Line options={options} data={data} />
                        </div>
                    </section>
                </section>
            </div>
        </>
    )
}

export default ConsultantDashboard