// import { useRef } from "react"
import SetupPage from "./components/setupPage";
import { useState } from "react";
import { useEffect } from "react";
// import { firebaseTools } from "../../../../firebase1";
import { firebaseTools } from "../../../DataContext/firebase1";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const SecondPageList = {
    Description: "",
}

export default function GigSetupSecondPage(props) {
    const [thumbnail, setThumbnail] = useState();

    const [SecondPageList, setSecondPageList] = useState({
        Description: props.data.Description || "",
    })

    useEffect(() => {
        async function getThumbnail() {
            if (props.data.Description) { // if the user has stored thumbnail
                // setKeywords(props.data.keywords)
                const temp = await firebaseTools.getGIGProfilePic(props.gigID);
                setThumbnail(temp);
            }
        }
        getThumbnail();
    }, []);

    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- thumbnail part =-=-=-=-=-=-=-=-=-=-=-= //
    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 5242880) {
                alert('Please select an image file with a maximum size of 5 MB.');
                return;
            }
            setThumbnail(URL.createObjectURL(file));
            let url = await firebaseTools.setGIGProfilePic(props.gigID, file);
        }
    }
    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= //

    function DescriptionChanged(e) {
        setSecondPageList({...SecondPageList, Description: e});
    }

    function nextPage(e) {
        e.preventDefault();
        if (SecondPageList.Description) {
            props.nextPage(e, SecondPageList);
        }
        else {
            alert("Please fill all the fields");
        }
    }

    function previousPage(e) {
        e.preventDefault();
        if (SecondPageList.Description) {
            props.previousPage(e, SecondPageList);
        }
        else {
            alert("Please fill all the fields");
        }
    }

    return (
        <SetupPage next={nextPage} previous={previousPage} nextText={"Next"}>
            <div className="gig-form-row">
                <label>Description</label>
                <p>Describe your facilities.</p> <br></br>
                <ReactQuill className="gig-description" value={SecondPageList.Description} onChange={DescriptionChanged} />
                {/* <textarea value={SecondPageList.Description} className="gig-description" name="description" onInput={DescriptionChanged}></textarea> */}
            </div>
            {/* =-=-=-=-=-=-=- THUMBNAIL =-=-=-=-=-=-=-= */}
            <div className="gig-form-row">
                <label>Thumbnail</label>
                <p>Add a thumbnail of your gig.</p> <br></br>
                <div className="thumbnail-container">
                    <img className="thumbnail-image" src={thumbnail} alt="thumbnail-img" />
                    <input
                        type="file"
                        onChange={handleImageUpload}
                        accept="image/*"
                        required
                    /> <br></br>
                </div>
            </div>
        </SetupPage>)
}