import React, { useState } from 'react';
import { Icon } from 'react-icons-kit';
import { loading } from 'react-icons-kit/ikons/loading'
import { Link, useNavigate } from 'react-router-dom';
import '../Register.css';
// // Logo
import logo from '../../../assets/navbar/logo.png';

import { postRequest } from '../../../requestAndLocalStorage/requestAndLocalStorage';

const ForgotPassword = () => {
  // fields
  const [email, setEmail] = useState('');
  // Errros
  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const errors = {};

    // Check for empty fields
    if (email.trim() === '') {
      errors.email = 'Email is required';
    }

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }
    try {
      setIsLoading(true);

      postRequest('/api/auth/forgot_pass', {
        Email: email
      })
        .then(response => {
          console.log(response)
          setIsLoading(false);
        })
        .catch(error => {
          const err = {};
          if (error.response.data === "Invalid email") {
            err.email = "No email found";
            setFieldErrors(err);
            console.log(error.response.data, err.email);
          }
          setIsLoading(false);
        });
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        errors.email = 'Email is already in use';
      } else if (error.code === 'auth/invalid-email') {
        errors.email = "Enter a valid email address"
      } else {
        console.error('Registration error', error);
        // Handle other registration errors
      }

      setFieldErrors(errors);
    }
  };

  return (
    <div className="register-container">

      <div className="register-form-container">
        <div className="logo-container">
          <img onClick={() => navigate('/')} src={logo} alt="Logos" className="logo" />
        </div>
        <h2>Reset Password</h2>
        <form onSubmit={handleFormSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
          {fieldErrors.email && <p className="error">{fieldErrors.email}</p>}

          <button className="register-button" type="submit" disabled={isLoading}>
            {isLoading ? (
              <Icon className='loading-icon' icon={loading} size={20} />
            ) : (
              'Send'
            )
            }
          </button>
        </form>
        <p>
          Already have an account? <Link to="/login">Sign in</Link>
        </p>
      </div>
    </div>
  );
};

export default ForgotPassword;
