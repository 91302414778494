import React from 'react';
import ReactDOM from 'react-dom/client';

import 'react-toastify/dist/ReactToastify.css';
import './index.css';

// import App from './App';
import LoginPage from './AuthController/loginPage';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RegisterPage from './AuthController/registration/user';
import ConfirmRegistration from './AuthController/registration/confirmRegistration';
import NotFound from './Error/404Page';
import Home from './tempHome';
import ForgotPassword from './AuthController/registration/forgotPassword/forgotPassword';
import ResetPassword from './AuthController/registration/forgotPassword/passwordConfirmation';
import RegisterConsultant from './AuthController/registration/consultant';
import ConsultantProfile from './pages/consultant/consultant_profile';
import DraftGigPage from './pages/gigRegistration/controller';
import RegisterEnterprise from './AuthController/registration/enterprise';
import EnterpriseProfile from './pages/enterprise/enterprise_profile';
import GigPage from './pages/gig/gig_profile';
import { DataProvider } from './DataContext/DataContext';
import EnterpriseInvitation from './pages/enterprise/consultantInvitation';
import UserProfile from './pages/user/userProfile';
import ChatList from './DataContext/messaging/messaging/chat';
import SessionProfile from './pages/session/SessionProfile';
import VideoChat from './pages/session/video_chat/videoChat';

import * as process from 'process';
import { ToastContainer } from 'react-toastify';
import ConsultantDashboard from './pages/consultant/dashboard/consultant_dashboard';
import UserGuide from './pages/landing/UserGuide';
import UserSession from './pages/userSessions/userSession';
import ConsultantSession from './pages/consultantSessions/consultantSession';
import GigRequestPage from './pages/gig/gigRequest';
import Booking from './pages/booking/booking';
import ScrollToTop from './CustomHooks/scrollToTop';

(window).global = window;
(window).process = process;
// (window).Buffer = [];

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ScrollToTop />
    <DataProvider>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        progress={undefined}
        theme='colored'
      />
      <Routes>
        <Route exact path='/' element={<Home />} />


        {/* 
           █████  ██    ██ ████████ ██   ██  ██████  ██████  ██ ███████  █████  ████████ ██  ██████  ███    ██ 
          ██   ██ ██    ██    ██    ██   ██ ██    ██ ██   ██ ██    ███  ██   ██    ██    ██ ██    ██ ████   ██ 
          ███████ ██    ██    ██    ███████ ██    ██ ██████  ██   ███   ███████    ██    ██ ██    ██ ██ ██  ██ 
          ██   ██ ██    ██    ██    ██   ██ ██    ██ ██   ██ ██  ███    ██   ██    ██    ██ ██    ██ ██  ██ ██ 
          ██   ██  ██████     ██    ██   ██  ██████  ██   ██ ██ ███████ ██   ██    ██    ██  ██████  ██   ████                                                                                                     
        */}
        <Route path='/login' element={<LoginPage />} />
        <Route path='/logout' element={<p>logout page</p>} />
        <Route path='/reset-password' element={<ForgotPassword />} />
        <Route path='/reset_password/confirmation/:token' element={<ResetPassword />} />

        <Route path='/registration/user' element={<RegisterPage />} />
        <Route path='/registration/consultant' element={<RegisterConsultant />} />
        <Route path='/registration/enterprise' element={<RegisterEnterprise />} />

        <Route path='/registration/confirmation/:token' element={<ConfirmRegistration />} />
        <Route path='/enterprise/invitation/:token' element={<EnterpriseInvitation />} />

        {/* 
          ██████  ███████ ██████      ██████   █████   ██████  ███████ ███████ 
          ██   ██ ██      ██   ██     ██   ██ ██   ██ ██       ██      ██      
          ██████  █████   ██████      ██████  ███████ ██   ███ █████   ███████ 
          ██      ██      ██          ██      ██   ██ ██    ██ ██           ██ 
          ██      ██      ██          ██      ██   ██  ██████  ███████ ███████ 
        */}
        <Route path='/userguide' element={<UserGuide />} />
        {/* BOOKING */}
        <Route path="/consultant/:encrypted/booking" element={<Booking />} />
        <Route path='/consultant/:encrypted' element={<ConsultantProfile />} />
        <Route path='/enterprise/:encrypted' element={<EnterpriseProfile />} />
        <Route path='/user/:encrypted' element={<UserProfile />} />
        <Route path='/user/session' element={<UserSession />} />
        <Route path='/consultant/session' element={<ConsultantSession />} />
        <Route path='/session/:id' element={<SessionProfile />} />

        <Route path='/dashboard' element={<ConsultantDashboard />} />

        <Route path='/session/v/:sessionId' element={<VideoChat />} />
        {/* 
           ██████  ██  ██████  
          ██       ██ ██       
          ██   ███ ██ ██   ███ 
          ██    ██ ██ ██    ██ 
           ██████  ██  ██████        
        */}
        <Route path='/gig/draft/:gigID' element={<DraftGigPage />} />
        <Route path='/gig/posted/:gigID' element={<GigPage />} />
        <Route path='/gig/request/' element={<GigRequestPage />} />
        {/*
           ██████ ██   ██  █████  ████████ 
          ██      ██   ██ ██   ██    ██    
          ██      ███████ ███████    ██    
          ██      ██   ██ ██   ██    ██    
           ██████ ██   ██ ██   ██    ██    
        */}
        {/* <Route path='/messaging/:pathType?/:from?' element={<ChatList />} /> */}
        <Route exact path="*" element={<NotFound />} />
      </Routes>
    </DataProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
