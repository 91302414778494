import { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify";
import { useData } from "../../../DataContext/DataContext";

export default function Timeline({ sessions, date, onClickCallback, verticalTimeline }) {
    const header = useRef();
    const body = useRef();
    const pointer = useRef();
    const marker = useRef();
    const timeContainer = useRef();
    const [time, setTime] = useState();
    const [width, setWidth] = useState(0);
    const { currentUser } = useData();

    useEffect(() => {
        drawGrid();
        // if (!sessions || sessions.length===0) return;
        if (!date) return;

        for (const session of (sessions || [])) {
            const Sdate = new Date(parseInt(session.SessionTime));
            if (date.toDateString() === Sdate.toDateString()) {
                renderSession(Sdate);
            }
        }
        timeUpdateFunction();
        const timeUpdate = setInterval(timeUpdateFunction, 1000);
        return () => {
            clearInterval(timeUpdate);
        }
    }, [sessions, date, verticalTimeline]);


    const timeUpdateFunction = () => {
        const currentTime = new Date();
        if (date.toDateString() === currentTime.toDateString()) {
            const currentWidth = (currentTime.getHours() * 60 + currentTime.getMinutes()) * 100 / (24*60);
            setWidth(currentWidth);
            let currentWidthComponent = marker.current.style.left || '0.00%';
            if (verticalTimeline) {
                currentWidthComponent = marker.current.style.top || '0.00%';
            }
            try {
                currentWidthComponent = parseFloat(currentWidthComponent.substring(0, currentWidthComponent.length - 1));
                if (currentWidthComponent < currentWidth) {
                    updateMarker(currentWidth);
                }
            }
            catch {
                return;
            }
        }
        else {
            setWidth(0);
        }
    }

    const renderSession = (Sdate) => {
        const session = document.createElement("div");
        session.className = "booking-timeline-session"+(verticalTimeline ? "-vertical" : "");
        const percentage = (Sdate.getHours() * 60 + Sdate.getMinutes()) / (24*60) * 100;
        if (verticalTimeline) session.style.top = `${percentage}%`;
        else session.style.left = `${percentage}%`;
        timeContainer.current.appendChild(session);
    }

    const drawGrid = () => {
        const divisions = 24;
        header.current.innerHTML = '';
        timeContainer.current.innerHTML = '';

        const dom = document.createElement("div");
        dom.className = "booking-timeline-division" + (verticalTimeline ? "-vertical" : "");
        for (let i=0; i<divisions; i++) {
            const copy = dom.cloneNode(true);
            copy.innerHTML = `${i}:00`;
            header.current.appendChild(copy);
        }
        dom.className = "booking-timeline-division booking-timeline-timeline"+(verticalTimeline ? "-vertical" : "");
        for (let i=0; i<divisions; i++) {
            const copy = dom.cloneNode(true);
            timeContainer.current.appendChild(copy);
        }
    }

    const onHover = (e) => {
        const rect = body.current.getBoundingClientRect();
        if (verticalTimeline) {
            var top;
            if (e.type === 'touchmove') top = e.touches[0].clientY - rect.top;
            else top = e.clientY - rect.top;

            const percentage = top / body.current.offsetHeight * 100;
            if (width < percentage) updateMarker(percentage);
            else updateMarker(width);
            return;
        }

        var left;
        if (e.type === 'touchmove') left = e.touches[0].clientX - rect.left;
        else left = e.clientX - rect.left;
        const percentage = left / body.current.offsetWidth * 100;
        
        if (width < percentage) {
            updateMarker(percentage);
        }
        else {
            updateMarker(width);
        }
    }

    const updateMarker = (percentage) => {
        var time = percentage / 100 * 24 * 60;
        if (time > 23*60) {
            time = 23*60;
            percentage = time * 100 / (24*60);
        }
        pointer.current.innerHTML = `${parseInt(time / 60)}:${parseInt(time % 60).toString().padStart(2, '0')}`;
        pointer.current.style.top = ''; pointer.current.style.left = '';
        marker.current.style.top = ''; marker.current.style.left = '';
        if (verticalTimeline) {
            pointer.current.style.top = percentage + "%";
            marker.current.style.top = percentage + "%";
        }
        else {
            pointer.current.style.left = percentage + "%";
            marker.current.style.left = percentage + "%";
        }
        
        setTime(time);
        if (!checkAvailability(time))
            marker.current.style.backgroundColor = "var(--yellow)";
        else
            marker.current.style.backgroundColor = "var(--blue-default)"
    }

    const checkAvailability = (time) => {
        if (!date) return false;
        const tempDate = new Date(date.getTime());
        tempDate.setHours(0);
        tempDate.setMinutes(time);
        const dateTime = tempDate.getTime();
        for (const session of (sessions || [])) {
            let timeStamp = parseInt(session.SessionTime);
            if (Math.abs(timeStamp - dateTime) < 60 * 60 * 1000)
                return false;
        }
        return true;
    }

    const onClick = (e) => {
        if (currentUser?.Source === "consultant" || currentUser?.Source === "enterprise") return;
        if (!date) return;
        const timeStamp = new Date(date.getTime());
        timeStamp.setHours(0);
        timeStamp.setMinutes(time);
        if (onClickCallback)
            onClickCallback(timeStamp);
        if (!checkAvailability(time))
            toast.warning("Consultant might be busy.");
    }

    return <div className={"booking-timeline" + (verticalTimeline ? "-vertical" : "")}>
        <h2>Select time for your session</h2>
        <div>
            <div className={"booking-timeline-header" + (verticalTimeline ? "-vertical" : "")} ref={header}></div>
            <div className={"booking-timeline-container" + (verticalTimeline ? "-vertical" : "")} ref={body} onMouseMove={onHover} onTouchMove={onHover} onClick={onClick}>
                <div className={"booking-timeline-pointer" + (verticalTimeline ? "-vertical" : "")} ref={pointer}></div>
                <div className={"booking-timeline-marker" + (verticalTimeline ? "-vertical" : "")} ref={marker}></div>
                <div className={"booking-timeline-time-container" + (verticalTimeline ? "-vertical" : "")} ref={timeContainer}></div>
                <div className="booking-timeline-time-restraint"
                    style={{
                        width: verticalTimeline ? "100%" : `${width}%`,
                        height: verticalTimeline ? `${width}%` : "100%"
                    }}></div>
            </div>
        </div>
    </div>   
}