import React, { useState } from 'react';
// import axios from 'axios';
import "./Login.css";
import { postRequest, setRefreshToken } from '../requestAndLocalStorage/requestAndLocalStorage';
// import './Login.css';
// Logo
import logo from '../assets/navbar/logo.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// icons
import { Icon } from 'react-icons-kit';
import { loading } from 'react-icons-kit/ikons/loading'
// Google Login
// import { GoogleLogin } from '@react-oauth/google';
// import { firebaseTools } from '../firebase1';
// import { EncryptPhoneNumber } from './decrypters';

// hasher
import { SHA256 } from 'crypto-js';
import { toast } from 'react-toastify';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

// const responseMessage = () => {
//   console.log('Successfully Logged in');
// };
// const errorMessage = (error) => {
//   console.log(error);
// };

const LoginPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // const gigID = searchParams.get('gigID');

  const [email, setEmail] = useState(searchParams.get('email') || '');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  // const [showPassword, setShowPassword] = useState(false);
  const [showPasswordCheckbox, setShowPasswordCheckbox] = useState(false);


  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // const handleGoogleLogin = (e) => {
  //   // navigate('/profile')
  // }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError('');
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError('');
  };
  const handleShowPasswordToggle = (e) => {
    setShowPasswordCheckbox((prevShowPassword) => !prevShowPassword);
  };

  function encryptPassword(password) {
    const hash = SHA256(password)
    return hash.toString();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email.trim() === '') {
      setEmailError('Email is required');
      return;
    }

    if (password.trim() === '') {
      setPasswordError('Password is required');
      return;
    }

    try {
      setIsLoading(true);

      postRequest('/api/auth/login', {
        Email: email,
        Password: encryptPassword(password),
      })
        .then(response => {
          setRefreshToken(response.data.refreshToken);
          toast.success('Logged in successfully');

          navigate('/');
          window.location.reload();
        })
        .catch(err => {
          if (err.response.data === "Invalid User" || err.response.data === 'Invalid Password') {
            toast.error('Invalid Credentials', { position: 'top-left' });
            // setEmailError("User not found");
          }
          else {
            console.error(err);
          }
          setIsLoading(false);
        });

    } finally {
      setIsLoading(false);
    }
  };
  // const handlegoback = () => {
  //   // navigate('/', { replace: true });
  // };

  const handleForgotPassword = () => {
    // Navigate to the forgot password page
    navigate('/reset-password');
  };

  return (
    <div className="login-container">

      <div className="login-form-container">
        <div className="logo-container">
          <img onClick={() => navigate('/')} src={logo} alt="Logo" className="logo" />
        </div>
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
          {emailError && <p className="error">{emailError}</p>}
          <div className='password-field login'>
            <input
              type={showPasswordCheckbox ? 'text' : 'password'}
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
            />
            {showPasswordCheckbox ? (<FaEye onClick={handleShowPasswordToggle} />) : (<FaEyeSlash onClick={handleShowPasswordToggle} />)}
          </div>
          {passwordError && <p className="error">{passwordError}</p>}
          <button type="submit" disabled={isLoading}>
            {isLoading ? (
              <Icon className='loading-icon' icon={loading} size={20} />
            ) : (
              'Login'
            )}
          </button>

          <div className="forgot-password-button">
            <button onClick={handleForgotPassword}>Forgot Password?</button>
          </div>

        </form>
        {/* <div className="separator">
          <span>or</span>
        </div>
        <div>
          <GoogleLogin onSuccess={handleGoogleLogin} onError={errorMessage} />
        </div> */}
        <br />
        <p className="login-nav">
          Not a member yet? <Link to="/registration/user">Create a new account</Link>
        </p>
        {/* <p className='login-nav'>
          * currently only available for beta users *
        </p> */}
        {/* <div className='back-button-wrapper'>
          <button className='back-button' onClick={handlegoback}>Back</button>
        </div> */}

      </div>
    </div>
  );
};

export default LoginPage;
