import React from "react";
// import Categories from '../../categories';
import "./footer.css"
// import 'leaflet/dist/leaflet.css'

import logo from "../../assets/logo_banner.png";
// import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";

// import Facebookicon from '../../assets/facebook.svg'
// import Instagramicon from "../../assets/instagram.svg"
// import Twittericon from "../../assets/twitter.svg"
// import Linkedinicon from "../../assets/linkedin.svg"

const Footer = ({ className, hideTop }) => {
    // const sortedCategories = (Categories) => {
    //     const sortedCategories = Object.entries(Categories).sort(([, a], [, b]) => Object.keys(b).length - Object.keys(a).length);
    //     return Object.fromEntries(sortedCategories);
    // }

    // const sortCategories = sortedCategories(Categories);

    // function FooterLinks(obj, depth = 1) {
    //     if (depth > 2) return;
    //     return (<>
    //         {Object.keys(obj).map((val, index) => <div className={"footer-wrapper"} key={index}>

    //             <div key={index} className={`footer-content-level-${depth}`}>{val}</div>

    //             {FooterLinks(obj[val], depth + 1)}
    //         </div>
    //         )}
    //     </>)
    // }

    return (
        <footer className={"footer-base-1 " + (className ? className : '')}>
            <div className="content">
                { hideTop ? <></> :
                    <div className="container">
                        <div className="footer-row">
                            <div className="footer-contents">
                                <div>
                                    <img src={logo} alt="company-logo" />
                                    <p>
                                        Proprietary Disclosure: The contents, and trademarks of this website including brand theme, components, proprietary information , algorithm and the application solely is belonging of Tackoom Digital Private Limited, a registered company under Government of Nepal , Ministry of Industry and Commerce, 2078 BS. Any violation through unauthorized personnel shall not be pertained.
                                    </p>
                                </div>
                                <div>
                                    <h1 className="footer-contact-details">Tackoom Digital Private Limited</h1>
                                    <p>New Baneshwor, Kathmandu, Nepal</p>
                                    <div className="tag-row">
                                        <span>marketing@tackoom.com | </span>
                                        <span>974-1805012</span>
                                    </div>
                                </div>
                            </div>
                            <div className="footer-map-container">
                                <iframe title="gmap-location" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3532.7101634734245!2d85.3425045!3d27.6953516!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1981e801c0a1%3A0x4590eeafaffc14f0!2sTackoom!5e0!3m2!1sen!2snp!4v1710744146161!5m2!1sen!2snp" className="footer-map" allowFullScreen="" loading="lazy"></iframe>
                            </div>
                        </div>
                    </div>
                }

                <div className="footer2-row copy-row">
                    <div className="footer-copyright-message">
                        © {new Date().getFullYear()}
                    </div>
                    <div className="btn-group social-group btn-group-icons">
                        <a target="_blank" rel="noreferrer" href="https://www.facebook.com/tackoom/">
                            <FaFacebookF />
                        </a>
                        <a target="_blank" rel="noreferrer" href="https://www.instagram.com/tackoom/">
                            <FaInstagram />
                        </a>
                        <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/tackoom-counseling/">
                            <FaLinkedin />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer;