import React from 'react'
import '../Landingpage.css'
import rightArrow from '../../../assets/right-arrow.svg'

const FirstStep = ({ formData, setFormData, nextStep }) => {
    return (
        <>
            <h3 className='step-header'>I am interested in scholarships:</h3>
            <div className='form-container'>
                <div>
                    <input className='step-input' type="radio" id="form-yes" name="drone" value="enterprise" />
                    <label className='step-label' for="form-yes">Yes</label>
                </div>
                <div>
                    <input className='step-input' type="radio" id="form-no" name="drone" value="student" />
                    <label className='step-label' for="form-no">No</label>
                </div>
            </div>
            <div className='step-img-container'>
                <div></div>
                <img className='step-img' src={rightArrow} onClick={nextStep}  alt='right' />
            </div>
        </>
    );
}

export default FirstStep