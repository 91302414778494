import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { firebaseTools } from "../../../DataContext/firebase1";
import { decryptUrl } from "../../../tools";

import "./getThumbnail.css";
import ImageLoader from "../../../components/ImageLoader/imageLoading";
import { Rating } from "@mui/material";
import { FaStar } from "react-icons/fa";
import { useData } from "../../../DataContext/DataContext";
import { toast } from "react-toastify";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faEye } from '@fortawesome/free-solid-svg-icons'

export default function RenderThumbnail({data, type="gig-list", onClick=()=>{}, className="", editView=false, draftGig=false}) {
    const [postStatus, setPostStatus] = useState('');
    const [exists, setExists] = useState(true);

    const [postedLink, setPostedLink] = useState('');
    const [draftLink, setDraftLink] = useState('');

    const [isLoaded, setIsLoaded] = useState(false)
    const { onlineClients } = useData();

    // const thumbnail = useRef();
    // const title = useRef();
    // const rating = useRef();
    // const ownerImage = useRef();
    // const ownerName = useRef();
    // const price = useRef();
    // const views = useRef();

    const [thumbnail, setThumbnail] = useState('');
    const [consultantPFP, setConsultantPFP] = useState('');
    const [title, setTitle] = useState([]);
    const [rating, setRating] = useState([]);
    const [ownerImage, setOwnerImage] = useState([]);
    const [ownerName, setOwnerName] = useState([]);
    const [price, setPrice] = useState([]);
    const [views, setViews] = useState(0);
    const navigate = useNavigate();
    const [onlineStatus, setOnlineStatus] = useState(0);

    useEffect(() => {
        if (onlineClients) {
            setOnlineStatus(onlineClients.includes('consultant-'+data.ConsultantID));
        }
    }, [onlineClients]);

    useEffect(() => {

        const getData = async () => {
            if (data) {
                // =-=-=-=-=-=-=-=-=-=-=-=-=-=-Required data fetching =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- //
                // let docData = (await (await firebaseTools.getGIGDocUsingDocID(props.data.ID)).get()).data();
                const docData = data;
                let thumbnailURL  = await firebaseTools.getGIGProfilePic(data.ID);
                let consultantURL = await firebaseTools.getCONProfilePic(data.ConsultantID);
                setThumbnail(thumbnailURL);
                setConsultantPFP(consultantURL);
                setIsLoaded(false);
            }
        };
        
        getData();
    }, []);

    if (!exists) {
        return (<></>);
    }

    const onThumbnailClick = (e) => {
        e.stopPropagation();
        navigate( postStatus ? postedLink : draftLink );
    }

    const editClick = (e) => {
        e.stopPropagation();
        navigate('/gig/draft/' + data.ID);
    }
    const cardClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (data.SessionID) {
            toast.info("You are being redirected to session page");
            return navigate(`/session/${data.SessionID}`)
        }
        if (type==="gig-list") {
            if (draftGig) editClick(e);
            else navigate(`/gig/posted/${data.ID}`)
        }
        else {
            onClick(data.ID);
        }
    }

    return (
        <button onClick={cardClick} className={"recommended-section-link " + className}>
            <div className="gig-thumbnail-recommendation">
                { thumbnail ?
                    <img className="gig-thumbnail-img" src={thumbnail} alt="gig-thumbnail-img" />
                    : <div className="gig-thumbnail-img image-preloader"></div>
                }
                <h5>{data.Title}</h5>
                <div className="gig-thumbnail-info-section">
                    <section className="gig-thumbnail-user-section">
                        <div className={(onlineStatus ? "active-client" : "inactive-client")}>
                            <img className="gig-consultant-pic" src={consultantPFP} alt="consultant-profile-pic" loading="lazy"/>
                        </div>
                        { !editView && <p className='gig-profile-name'>{data?.CName}</p> }
                    </section>
                    <section>
                        { editView && <p className="edit-link"><button  onClick={editClick}> Edit </button></p> }
                    </section>
                    <section className="gig-thumbnail-rating-section">
                        <p className='gig-rating-p'>{data?.Rating}</p>
                        <FaStar style={{color: '#faaf00'}}/>
                    </section>
                </div>
                { data.SessionID && <div className={"gig-thumbnail-booked-label " + (data.SessionStatus==='0' ? "gig-thumbnail-pending" : "gig-thumbnail-completed")}>BOOKED</div> }
            </div>
        </button>
        // <div className="link" onClick={(e) => onThumbnailClick(e)}>
        //     <div className="GigThumbnail">

        //         {/* <img ref={thumbnail}></img> */}
        //         {isLoaded ? (
        //             <div className="loading-placeholder">
        //                 <ImageLoader />
        //             </div>
        //         ) : (
        //             // <img ref={thumbnail} alt="" />
        //             <img src={thumbnail} alt="" />

        //         )}
        //         {/* <h2 ref={title}></h2> */}
        //         <h2>{title}</h2>
        //         <div className="gig-informations">
        //             <div className="gig-thumbnail-left">
        //                 {/* <p ref={rating}></p> */}
        //                 {/* { rating == undefined && <p>No Ratings Yet</p> }
        //                 { rating != undefined && <p>{ rating }</p> } */}
        //                 {/* <p className="gig-owner-data"> */}
        //                 {/* <img ref={ownerImage} className="thumbnail-profile-pic" /> */}
        //                 {/* <img src={ownerImage} className="thumbnail-profile-pic" /> */}
        //                 {/* <p ref={ownerName}></p> */}
        //                 {/* <p>{ownerName}</p> */}
        //                 {/* </p> */}
        //                 {/* <h3 ref={price}></h3> */}
        //                 <div className="view-price">
        //                     {rating != 0 && <Rating defaultValue={parseFloat(rating)} readOnly precision={0.5} />}
        //                     {rating != 0 || <p>No Ratings Yet</p>}
        //                     {/* <h4><FontAwesomeIcon icon={faEye} /> {views}</h4> */}
        //                     <h3>{price}</h3>
        //                 </div>
        //                 {/* <h4 ref={views}></h4> */}
        //             </div>
        //             <div className="gig-thumbnail-right">
        //                 { props.editView && <p className="edit-link" onClick={editClick}><button> Edit </button></p> }
        //                 {/* {props.editView && <Link to={draftLink} className="edit-link"><button>Edit</button></Link>} */}
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
}