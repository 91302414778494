import axios from "axios";
import { toast } from "react-toastify";

export async function postRequest(url, data={}) {
    const refreshToken = localStorage.getItem('refresh-token');
    // if (!!refreshToken) 
        return axios.post(url, { ...data, refreshToken: refreshToken });

    // authentication requires post request
    // if (!data || Object.keys(data).length===0) return {};   // forbidden request
    
    if (url.includes("/api/auth")) {
        return axios.post(url, data);
    }
    // if no refresh token, user is not authorized for post request so reject it
    return {};
}

export async function getRequest(url, data={}) {
    return axios.get(url, { params: data});
}

export function setRefreshToken(token) {
    localStorage.setItem('refresh-token', token);
}

export function logout() {
    localStorage.removeItem('refresh-token');
    toast.info('Successfully logged out');
}