import { React, forwardRef } from "react";
import "./setupPage.css";
import ProgressCheck from "./ProgressCheck";

// export default function SetupPage(props, ref) {
//     // const childElements = React.children.toArray(props.children);

//     // const wrappedChildren = childElements.map((child, index) => {
//     //     <div key={index}>{child}</div>
//     // })

//     return (<div className="page" ref={ref}>
//         <div>{props.children}</div>;
//         <div className="pageButtons">
//             <button>previous</button>
//             <button onClick={props.next}>Next</button>
//         </div>
//     </div>);
// }


const SetupPage = forwardRef((props, ref) => {
    return (
        <>
            <div className="gig-setup-page-wrapper" ref={ref}>
                <div className="gig-setup-page-progress">
                    <ProgressCheck />
                </div>
                <div className="gig-setup-page-step-container">
                    <div>{props.children}</div>
                    <div className="pageButtons">
                        {props.previous && <button onClick={props.previous}>Previous</button>}
                        <button onClick={props.next}>{props.nextText}</button>
                    </div>
                </div>
            </div>
        </>
    );
})

export default SetupPage;