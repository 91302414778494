import React, { useContext, useState, useEffect } from "react"
import { firebaseTools } from "./firebase1"
import Preloader from "../components/Preloader/preloader"
import { getRequest, postRequest } from "../requestAndLocalStorage/requestAndLocalStorage";
import { encryptUrl } from "../tools";
import { io } from 'socket.io-client';
import { toast } from "react-toastify";

const socket = io(
  process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEVELOPMENT_DOMAIN : process.env.REACT_APP_PRODUCTION_DOMAIN, {
  cert: process.env.NODE_ENV === "production" ? process.env.SSL_CERT : '',
  key: process.env.NODE_ENV === "production" ? process.env.SSL_KEY : '',
  autoConnect: false,
  reconnection: true,
  path: '/socket',
  transports: ['websocket', 'polling'],
  // reconnectionAttempts: 5,
});

const DataContext = React.createContext()

export function useData() {
  return useContext(DataContext)
}

export function DataProvider({ children }) {
  const [currentUser, setCurrentUser] = useState({})
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [onlineClients, setOnlineClients] = useState([]);
  const [notifications, setNotifications] = useState([]);  
  var callBackTrigger = ()=>{};
  var sessionPageCallbackTrigger = () => {};

  function setSessionPageCallbackTrigger(trigger) {
    sessionPageCallbackTrigger = trigger;
  }

  function setCallBackTriggerCall(trigger) {
    callBackTrigger = trigger;
  }

  const getNotifications = () => {
    if (currentUser.Source === 'user') {
        postRequest('/api/data/notification/user', { userID: currentUser.UID })
            .then(response => {
                setNotifications(response.data)
            })
            .catch(error => console.error(error));
    }
    else if (currentUser.Source === 'consultant') {        
        postRequest('/api/data/notification/consultant', { consultantID: currentUser.UID })
            .then(response => setNotifications(response.data))
            .catch(error => console.error(error));
    }
  }

  useEffect(() => {
    const categoriesReq = getRequest('/api/data/categories')
      .then(response => {
        setCategories(response.data)
      })
      .catch(error => console.error(error));

    const currentUserReq = load()
    
    Promise.all([categoriesReq, currentUserReq])
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.error(error)
      });

  }, [])


  async function load() {
    return postRequest('/api/auth/getCurrentUser')
      .then(async response => {
        const data = response.data;

        data.Link = `/${ data.Source }/${ data.Name.replace(' ', '-') }-${ encryptUrl(data.UID) }`

        if (data.Source === 'user') {
          data.ProfilePic = await firebaseTools.getUSRProfilePic(data.UID);
        }
        else if (data.Source === 'consultant') {
          data.ProfilePic = await firebaseTools.getCONProfilePic(data.UID);
        }
        if (data.Source === 'enterprise') {
          data.ProfilePic = await firebaseTools.getENTProfilePic(data.UID);
        }
        setCurrentUser(data);
      })
      .catch(error => {
        setCurrentUser({});
      });

  }

  function reloadTrigger() { load(); }


  useEffect(() => {
    if (!Object.keys(currentUser).length) return;

    var interval;
    const getNumberSession = () => {
      if (currentUser.Source === 'user')
        postRequest('/api/data/session/get_number_pending', { userID: currentUser.UID })
          .then((response) => {
            if (callBackTrigger)
              callBackTrigger(response.data[0].CurrentSessions);
          })
          .catch((err) => console.error(err));
        else if (currentUser.Source === 'consultant') {
          postRequest('/api/data/session/get_number_pending_consultant', { consultantID: currentUser.UID })
            .then((response) => {
              if (callBackTrigger)
                callBackTrigger(response.data[0].CurrentSessions);
            })
            .catch((err) => console.error(err));
        }

    }

    // if (currentUser.Source === 'user') {
      getNumberSession();
      interval = setInterval(getNumberSession, 60*1000);
    // }

    getNotifications();

    return () => {
      if (interval) clearInterval(interval);
    }
  }, [currentUser])
  
  
  /*
    ███████  ██████   ██████ ██   ██ ███████ ████████ 
    ██      ██    ██ ██      ██  ██  ██         ██    
    ███████ ██    ██ ██      █████   █████      ██    
         ██ ██    ██ ██      ██  ██  ██         ██    
    ███████  ██████   ██████ ██   ██ ███████    ██                                                      
  */
  useEffect(() => {
    // if (!Object.keys(currentUser).length) return;
    const authorized = Object.keys(currentUser).length > 0;    
    socket.connect();

    if (authorized)
      socket.emit('activeID', [currentUser.Source, currentUser.UID].join('-'));

    socket.on('online', (data) => {
      setOnlineClients(data);
    });

    socket.on("connect", ()=>{
      // console.log(socket.id);
    })

    socket.on('session-reschedule', (data) => {
      sessionPageCallbackTrigger(data);
      getNotifications();
      toast.info('Session rescheduled');
    })
    socket.on('session-accept', (data) => {
      sessionPageCallbackTrigger(data);
      getNotifications();
      toast.success('Session accepted');
    })
    socket.on('session-reject', (data) => {
      sessionPageCallbackTrigger(data);
      getNotifications();
      toast.error('Session rejected');
    })
    socket.on('session-new-comment', (data) => {
      sessionPageCallbackTrigger(data);
      getNotifications();
      toast.info('new comment on session: ' + data);
    })


    return () => {
      socket.disconnect();
    }
  }, [currentUser]);

  // useEffect(() => {
  //   console.log("messageCounter");
  //   setMessageCounterTrigger((number)=>{
  //     console.log("messageUpdate", number);
  //   })
  // }, [messageCounter])


  const value = {
    currentUser,
    setCallBackTriggerCall,
    setSessionPageCallbackTrigger,
    categories,
    load,
    onlineClients,
    notifications,
    reloadTrigger,
  }

  return (
    <DataContext.Provider value={value}>
      { loading && <Preloader/> }
      {!loading && children}
    </DataContext.Provider>
  )
}