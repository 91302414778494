
import React from 'react'
import commitmentGif from '../../../assets/commitment-gif.gif'

const Commitment = () => {
    return (
        <main className='commitment-container'>
            <section className='commitment-section'>
                <article className='commitment-article'>
                    <h1 className='commitment-article-heading'>Social Responsibility Commitment</h1>
                    With the significant responsibility of providing the students  with a career oriented counseling, distinguishing the vision from unauthorized counseling, Tackoom focuses on making sure that students do not fall into pitfalls.
                    It is our responsibility to look after students who are willing to seek for opportunities to study and grow , something that matches the exact vision of our founder. Tackoom is completely committed towards maintaining the <strong>Ethos Of Tackoom</strong> and 
                    operation to be scrutinized in the services.

                    <script src="https://platform.linkedin.com/badges/js/profile.js" async defer type="text/javascript"></script>
                    {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis quaerat et consequuntur animi accusamus voluptate aliquid impedit, fugiat expedita. Nulla hic dicta quam labore impedit. Aliquid nihil quasi soluta necessitatibus?
                    Fuga nostrum, totam optio assumenda rerum, sint sapiente ipsum nobis minus vel quis repellendus ad vero suscipit consequuntur eius ratione, delectus sequi. Sunt dignissimos doloremque magni pariatur inventore expedita qui.
                    Molestias debitis sed error expedita tenetur saepe sunt facere, reprehenderit unde. Facilis, labore dicta. Aliquid, ab? Excepturi dolorem distinctio ex, illo labore, numquam commodi eveniet beatae ratione incidunt voluptatibus mollitia!
                    <div className='commitment-div'>
                        <h4>Aayush Acharya</h4>
                        <span>CEO</span>
                    </div> */}
                </article>
                <img className='commitment-img' src={commitmentGif} alt='commitment-gif' />
            </section>
        </main>
    )
}

export default Commitment