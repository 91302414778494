import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import RenderThumbnail from '../../gig/thumbnail/getThumbnail'
import { useData } from '../../../DataContext/DataContext'
import { getRequest } from '../../../requestAndLocalStorage/requestAndLocalStorage'

const GigSort = () => {
    const [gigData, setGigData] = useState('')
    const [filteredData, setFilteredData] = useState('');
    const [type, setType] = useState('General');
    const subType = useRef();
    const { categories } = useData();

    useEffect(() => {
        const gigFetchHandler = async () => {
            getRequest('/api/data/gigs/random', { size: 20 })
                .then(response => {
                    setFilteredData(response.data || [])
                })
                .catch(err => {
                    console.error(err);
                })
        }
        gigFetchHandler()
    }, [categories])

    const sortButtonClicked = (e) => {
        e.preventDefault();
        // console.log(type.current.value)
        if (type === '---') return setFilteredData(gigData);
        if (subType.current.value === '---') return setFilteredData(gigData.filter(gig => (gig.Category1 === type)));

        setFilteredData(gigData.filter(gig => {
            return (gig.Category1 === type && gig.Category2 === subType.current.value);
        }))
    }

    return (
        <>
            <div className='sort-container'>
                <h1>From selected advisors, find the best for you!</h1>
                <p>Choose your criteria</p>
                {/* <div className='sort-selection'>
                    <select className='sort-select' value={type} onChange={(e) => setType(e.target.value)}>
                        
                        <option className='sort-option'>
                            {"General"}
                        </option>
                        {
                            Object.keys(categories).map((value, index) => 
                                <option className='sort-option' key={index}>
                                    {value}
                                </option>
                            )
                        }
                    </select>
                    <select className='sort-select' ref={subType}>
                        <option className='sort-option'>
                            {"General"}
                        </option>
                        {
                            type && type!=='General' && Object.keys(categories[type]).map((value, index) => 
                                <option className='sort-option' key={index}>
                                    {value}
                                </option>
                            )
                        }
                    </select>
                    <button className='gig-sort-button' onClick={sortButtonClicked}>Sort</button>
                </div> */}
                <div className='gigs-container'>
                    {filteredData?.length > 0 && filteredData?.map((data, index) => (
                        <RenderThumbnail data={data} key={index} />
                    ))}
                </div>
            </div>
        </>
    )
}

export default GigSort