import { useEffect, useState } from "react";
import { useData } from "../../../../DataContext/DataContext";

import "./categorySelector.css";
import { postRequest } from "../../../../requestAndLocalStorage/requestAndLocalStorage";
import Popup from "../../../../components/Popup/popup";
import Pagination from '@mui/material/Pagination';


const CategoriesSelector = () => {
    const { currentUser, categories } = useData();

    const [categoryList, setCategoryList] = useState([]);
    const [moreOption, setMoreOption] = useState(true);
    const [selected, setSelected] = useState(false);
    
    const [selectedNames, setSelectedNames] = useState();
    const [leafList, setLeafList] = useState([]);

    const [countSelected, setCountSelected] = useState(0);
    const limit = 10;

    useEffect(() => {
        postRequest('/api/data/categories/get_goals', { userID: currentUser.UID })
            .then(response => {
                const temp = [];
                for (let res of response.data) {
                    temp.push(res.CategoryID);
                }
                setSelected(temp);
            })
            .catch(err => console.error(err));
    }, [categories]);

    useEffect(() => {
        if (typeof (selected) !== 'object')
            return;

        const categoryAcc = new Set();
        const tempSelectedNames = new Set();
        const tempCategoryList = [];


        const traverseCategory = (obj) => {
            if (!obj) return;
            for (const level of Object.keys(obj)) {
                if (typeof (obj[level]) === 'number') {
                    tempCategoryList.push([level, obj[level]])
                    if (selected.includes(obj[level])) {
                        tempSelectedNames.add(level)
                    }
                    else {
                        categoryAcc.add(level)
                    }
                }
                else {
                    traverseCategory(obj[level]);
                }
            }
        }
        traverseCategory(categories);

        setLeafList(categoryAcc);
        setSelectedNames(tempSelectedNames)
        setCategoryList(tempCategoryList);
        updateCountSelected(categoryAcc);
    }, [selected]);


    const updateCountSelected = (list = []) => {
        let count = 0, temp = [];
        for (const record of (list.length ? list : categoryList)) {
            if (temp.includes(record[0])) continue;
            count += record[2];
            temp.push(record[0]);
        }
        setCountSelected(count);
    }

    const updateAdd = (categoryIndex) => {
        postRequest('/api/data/categories/add_goals', { list: [[currentUser.UID, categoryIndex]] })
            .then(response => null)
            .catch(error => console.error(error));
    }

    const updateDelete = (categoryIndex) => {
        postRequest('/api/data/categories/delete_goals', { list: [[currentUser.UID, categoryIndex]] })
            .then(response => null)
            .catch(error => console.error(error));
    }

    const addGoal = (goal) => {
        const tempLeaf = new Set(leafList);
        const tempNames = new Set(selectedNames);
        
        for (const goal_i of categoryList) {
            if (goal_i[0] === goal) {
                tempLeaf.delete(goal_i[0]);
                tempNames.add(goal_i[0]);
                updateAdd(goal_i[1]);
            }
        }
        
        setLeafList(tempLeaf);
        setSelectedNames(tempNames);
    }
    const removeGoal = (goal) => {
        const tempLeaf = new Set(leafList);
        const tempNames = new Set(selectedNames);
        
        for (const goal_i of categoryList) {
            if (goal_i[0] === goal) {
                tempLeaf.add(goal_i[0]);
                tempNames.delete(goal_i[0]);
                updateDelete(goal_i[1]);
            }
        }
        
        setLeafList(tempLeaf);
        setSelectedNames(tempNames);
    }


    return <div className="user-categories-selector">
        <h1 className="recommended-gig-header">Goals</h1>
        <div>
            <div className="user-categories-selector-container">
                { selectedNames && [...selectedNames]?.map((name, index) =>
                    <CategorySelector name={name} key={index} onClick={ removeGoal }/>
                )}
                {
                    selectedNames?.size < limit &&
                    <CategoryInput options={ leafList } callBack={ addGoal }/>
                }
            </div>
            <h2 className="recommended-gig-header2 recommended-gig-header">Recommended Goals</h2>
            <div className="user-goals-suggessions">
                {   [...leafList].slice(0, 10).map((value, index) => 
                        <div
                            className={"user-goals-suggession " + (selectedNames?.size < limit ? "" : "user-goals-disabled")}
                            key={index}
                            onClick={ () => (selectedNames?.size < limit) && addGoal(value)}
                        >
                            {value}
                        </div>
                    )
                }
            </div>
            {/* <Pagination count={10} onChange={(e,v) => console.log(v)}/> */}
            {/* {filterDuplcates(categoryList).map((category, index) =>
                <CategorySelector key={index} name={category[0]} value={category[1]} selected={category[2]} onClick={() => categoryClick(category[0])} />)
            } */}
            {/* {moreOption && <div className="user-categories-selector-more-option"><p onClick={() => setMoreOption(false)}>More ...</p></div>} */}
        </div>
    </div>
}


const CategorySelector = ({ name, selected = false, onClick = () => { } }) => {
    return <div className={"user-category-selector " + (selected ? "selected-category" : "")}>
        {name}<span className="user-category-delete" onClick={ () => onClick(name)}>x</span>
    </div>
}


const CategoryInput = ({options=[], callBack}) => {
    const [isOpen, setIsOpen] = useState(false);
    
    const [visibleOptions, setVisibleOptions] = useState([]);

    useEffect(() => {
        setVisibleOptions([...options]);
    }, []);

    const closeOption = () => {
        setIsOpen(false);
    }
    const openOption = () => {
        setIsOpen(true);
    }

    const filterOption = (e) => {
        // if (e.target.value=='') {
        //     setVisibleOptions([]);
        //     return;
        // }
        const temp = [];
        const filter = e.target.value.toUpperCase();
        for (const option of options) {
            if (option.toUpperCase().indexOf(filter) > -1) {
                temp.push(option);
            }
            if (temp.length >= 10) break;
        }
        console.log(temp);
        setVisibleOptions(temp);
    }

    return <div className="user-goals-input">
        <div onClick={(e) => {
                openOption();
            }}
            className="user-goals-input-field"
        >
            {
                isOpen ? 
                    <input onKeyUp={ (e) => filterOption(e) } onFocus={ (e) => filterOption(e) } autoFocus/>
                : <span>Add Goals...</span>
            }
            <button>+</button>
        </div>
        { isOpen ? 
            <Popup isOpen={isOpen} onClose={()=>closeOption()}>
                <div className="user-goals-options">
                    { visibleOptions?.map((option, index) =>
                        <div 
                            className="user-goals-option" 
                            key={index}
                            onClick={() => {
                                callBack && callBack(option);
                                closeOption();
                            }}
                        >{option}</div>
                    )}
                </div>
            </Popup>
            : <></>
        }
    </div>
}

export default CategoriesSelector;