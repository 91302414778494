import { useLocation, useNavigate, useParams } from "react-router-dom";
import NavBar from "../../components/navbar/navbar";
import { useEffect, useState } from "react";
import NotFound from "../../Error/404Page";
import { getRequest, logout } from "../../requestAndLocalStorage/requestAndLocalStorage";
import { useData } from "../../DataContext/DataContext";
import Preloader from "../../components/Preloader/preloader";
import "./gigRequest.css";

const GigRequestPage = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const gigID = searchParams.get('gigID');
    const email = searchParams.get('email');
    const navigator = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const { currentUser } = useData();

    useEffect(() => {
        console.log(searchParams);
        console.log(gigID, email);
        // search if not signed in
        if (!Object.keys(currentUser).length) {
            getRequest('/api/data/check_email_validaty', { Email: email })
                .then(response => {
                    if (!response?.data?.length) {
                        navigator(`/registration/user?email=${email}`);
                    }
                    else {
                        navigator(`/login?email=${email}`);
                    }
                })
                .catch(error => console.error(error));
        }
        else {
            // if signed in 
            if (currentUser.Email === email) {
                navigator(`/gig/posted/${gigID}`);
            }
            else {
                setIsLoading(false);
            }
        }
    }, []);
    
    const continueHandler = (e) => {
        e.preventDefault();
        navigator(`/gig/posted/${gigID}`);
    }

    const logoutHandler = (e) => {
        e.preventDefault();
        logout();
        navigator(`/login?email=${email}`);
    }


    if (!gigID || !email) {
        return <NotFound />
    }
    // if currentUser.Email not equals email
    if (isLoading) {
        return <Preloader />
    }
    return <>
        <NavBar />
        <div className="request-container">
            <div className="request-container-inner login-form-container">
                <p>You tried to book a gig using a different account.</p>
                <div className="request-response-container">
                    <button onClick={continueHandler} >Continue</button>
                    <button onClick={logoutHandler}>Logout</button>
                </div>
            </div>
        </div>
    </>
}

export default GigRequestPage;