import './userProfile.css'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
// import TopEnt from './App/functions/TopEnt';
import { firebaseTools } from '../../DataContext/firebase1';
import NavBar from '../../components/navbar/navbar';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faEye, faEyeSlash, faArrowRight, faArrowLeft, faWallet, faCalendar, faFile } from '@fortawesome/free-solid-svg-icons'
import { decryptUrl } from '../../tools';
import NotFound from '../../Error/404Page';
import RecommendedGigs from './components/recommendedGigs';
import Footer from '../../components/footer/footer';
// assets
import Slider from './components/slider';
// import ConstructionPopup from '../constructionPopup';
import Preloader from '../../components/Preloader/preloader';
import { useData } from '../../DataContext/DataContext';
import { getRequest, postRequest } from '../../requestAndLocalStorage/requestAndLocalStorage';
import CountDownTimer from '../../components/CountDownTimer/countDownTimer';
import GetGigComponent from '../../components/SessionContainer/sessionContainer';
import CategorySelector from './components/category/categorySelector';
// import { useAuth } from '../AuthContext';

import EmptyLikeIllustration from "../../assets/illustrations/Gigs-Not-Liked-Indication.png"



const UserProfile = () => {
    const { encrypted } = useParams()
    const { currentUser, categories } = useData();

    const [pathFirstName, pathLastName, pathPhoneNumber] = decryptUrl(encrypted);
    const [valid, setValid] = useState(true);


    const [showPreloader, setShowPreloader] = useState(true);


    // authorized data & state
    const [gigData, setGigData] = useState([]);
    const [likedGigs, setLikedGigs] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser.Source !== 'user') {
            setValid(false);
            return;
        }
        const temp = currentUser.Link.split('/');
        if (temp[temp.length - 1].toLowerCase() !== encrypted.toLowerCase()) {
            setValid(false);
            return;
        }

        // get liked gigs
        const likedGigsReq = postRequest('/api/data/gigs/liked', { userID: currentUser.UID })
            .then(response => setLikedGigs(response.data))
            .catch(err => console.error(err));

        const recommendedGigs = getRequest('/api/data/gigs/random', { size: 20 })
            .then(async response => {
                const tempGigs = [];
                for (let i = 0; i < response.data.length; i++) {
                    tempGigs.push({
                        ...response.data[i],
                    })
                }
                setGigData(tempGigs);
            })
            .catch(error => {
                console.error(error);
            });


        Promise.all([recommendedGigs, likedGigsReq])
            .then(() => {
                setShowPreloader(false);
            })
            .catch(() => {
                setShowPreloader(false);
            })
    }, [])


    const handleSessionClick = (gig) => {
        navigate(`/session/${gig.SID}`);
    }

    return (
        <>
            {!valid ? (
                <NotFound></NotFound>
            ) : (
                <div className="homepage-main">
                    {showPreloader && <Preloader />}
                    <NavBar></NavBar>
                    <div className="homepage-content">
                        <CategorySelector />

                        <div className='recommended-gigs'>
                            <RecommendedGigs gigs={gigData} header="Recommended Gigs" />
                        </div>

                        <div className='liked-gigs'>
                            <RecommendedGigs gigs={likedGigs} header="Liked Gigs" empty={EmptyLikeIllustration} />
                        </div>
                        {/* <div className='top-picks'>
                            <TopEnt />
                        </div> */}
                        <Footer showLinks />
                    </div>
                </div>
            )}
            {/* <ConstructionPopup /> */}
        </>
    );
}
export default UserProfile;