import { useEffect } from "react";
import { encryptUrl, getRemainingTimeString, GTime } from '../../tools';
import { useState } from "react";
// import { firebaseTools } from "../../firebase1";
import { Link, useNavigate, useParams } from "react-router-dom";

import NavBar from "../../components/navbar/navbar";
import "./gig_profile.css";

import NotFound from "../../Error/404Page";
// import Preloader from "../../preloader";
import { Rating } from "@mui/material";
// import SessionReviewTool from "../../session/sessionReview";
import Footer from "../../components/footer/footer";
import { getRequest, postRequest } from "../../requestAndLocalStorage/requestAndLocalStorage";
import { firebaseTools } from "../../DataContext/firebase1";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TimePicker from "../../components/timeSelect/timePicker";
import Popup from "../../components/Popup/popup";
import { useData } from "../../DataContext/DataContext";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faXmarkSquare, faArrowRightFromBracket, faVideoCamera } from "@fortawesome/free-solid-svg-icons";
import { FaThumbsUp } from "react-icons/fa";
// import { useAuth } from "../../AuthContext";

import parse from 'html-react-parser';
import emptyReviewsIllustration from "../../assets/illustrations/Reviews-Not-Available-For-Gig-indication.png";
import DaysSelector from "../../components/daysSelect/daysSelect";
import DatePickerCustom from "../../components/dateSelect/datePicker";



export default function GigPage() {
    const { gigID } = useParams();   // encrypted text fetched from path
    const navigator = useNavigate();
    const { currentUser, onlineClients } = useData();
    // const [pathFirstName, pathLastName, pathPhoneNumber] = decryptUrl(encrypted);
    const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 700);
    const [showDetails, setShowDetails] = useState(false);

    const [data, setData] = useState({});
    const [date, setDate] = useState(0);
    const [time, setTime] = useState(0);
    const [bookConfirmation, setBookConfirmation] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const [available, setAvailability] = useState(true);

    const [gigThumbnail, setGigThumbnail] = useState("");
    const [consultantPFP, setConsultantPFP] = useState("");
    const [consultantLink, setConsultantLink] = useState("");

    const [isLoaded, setIsLoaded] = useState(false)
    const [postedTime, setPostedTime] = useState("");
    const [consultantActive, setConsultantActive] = useState("");

    const [showDateTimeInput, setShowDateTimeInput] = useState(true);
    const [ongoingSessionID, setOngoingSessionID] = useState();

    const [likedGig, setLikedGig] = useState(false);
    const [likeButtonDisabled, setLikedButtonDisabled] = useState(true);

    const [currentUserEmail, setCurrentUserEmail] = useState('');
    const [preRequisites, setPrerequisites] = useState([]);

    // const [gigRefer, setGigRefer] = useState({});
    const [reviews, setReviews] = useState([]);

    // const [viewCount, setViewCount] = useState('')
    // const { currentUser, currentUserData } = useAuth();

    useEffect(() => {
        const handleResize = () => {
            const mobileSize = 774;
            setIsScreenSmall(window.innerWidth < mobileSize);

            if (window.innerWidth < mobileSize) {
                setShowDetails(false);
            } else {
                setShowDetails(true);
            }
        };

        window.scrollTo(0, 0);
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    useEffect(() => {
        getRequest('/api/data/gig/' + gigID)
            .then(response => {
                setData(response.data[0]);
                const tempPrerequisites = [];
                for (let preRequisite of Object.keys(response.data[0])) {
                    if (preRequisite.includes('GP_') && response.data[0][preRequisite]===1) {
                        tempPrerequisites.push(preRequisite.split('_').slice(1).join(' '));
                    }
                }
                setPrerequisites(tempPrerequisites);
                
                setConsultantLink(`/consultant/${response.data[0].CName.replace(' ', '-').toLowerCase()}-${encryptUrl(response.data[0].ConsultantID)}`)
                if (currentUser.Source === 'consultant' && response.data[0].ConsultantID === currentUser.UID) {
                    setAuthorized(true);
                }

                // get gig thumbnail
                firebaseTools.getGIGProfilePic(gigID)
                    .then((url) => {
                        setGigThumbnail(url);
                    })
                    .catch((error) => {
                        console.error(error);
                    })

                // get consultant's profile picture
                firebaseTools.getCONProfilePic(response.data[0].ConsultantID)
                    .then(url => {
                        setConsultantPFP(url);
                    })
                    .catch((error) => {
                        console.error(error);
                    })

                // get remaining time (fetching Global time)
                getRemainingTimeString(parseInt(response.data[0].CreationDate))
                    .then((time) => {
                        setPostedTime(time);

                        setInterval(async () => {
                            setPostedTime(await getRemainingTimeString(parseInt(response.data[0].CreationDate)));
                        }, 1000 * 60 * 5);
                    })
                    .catch(err => console.error(err));
            })
            .catch(err => {
                console.error(err);
            })

        getRequest('/api/data/reviews/gig', { ID: gigID })
            .then(async (response) => {
                for (let i = 0; i < response.data.length; i++) {
                    response.data[i].ProfilePic = await firebaseTools.getUSRProfilePic(response.data[i].UserID);
                }
                setReviews(response.data);
            })
            .catch((error) => {
                console.error(error);
            })

        if (currentUser?.Source === 'user' && currentUser?.UID) {
            postRequest('/api/data/session/get_ongoing_gig', { userID: currentUser.UID, gigID: gigID })
                .then((response) => {
                    if (response.data.length > 0) {
                        setShowDateTimeInput(false);
                        setOngoingSessionID(response.data[0].ID);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
            postRequest('/api/data/gig-like/get', { userID: currentUser.UID, gigID: gigID })
                .then((response) => {
                    setLikedButtonDisabled(false);
                    if (response.data.length) setLikedGig(true);
                    else setLikedGig(false);
                })
                .catch((error) => console.error(error));
        }
        else if (currentUser?.Source) {
            setShowDateTimeInput(false);
        }
    }, []);

    async function validateTime() {
        if (!date) {
            toast.error("Invalid Date");
            return;
        }
        if (!time) {
            toast.error("Invalid Time");
            return
        };    
        const tempDate = new Date(date);
        tempDate.setHours(0);
        tempDate.setMinutes(time);
    
        const currentDate = new Date(await (new GTime()).getTime());
        if (tempDate < currentDate) {
            toast.error("Invalid date");
            return false;
        }
        return true;
    }
    async function authorizedBookingConfirmHandler() {
        if (!(await validateTime())) return;
        const tempDate = new Date(date);

        if (!currentUser?.Source) {
            navigator('/login');
            toast.error("Login required");
            return;
        }

        postRequest('/api/data/session/create', {
            GigID: gigID,
            UserID: currentUser.UID,
            Time: tempDate.getTime(),
        })
            .then(response => {
                if (response.data[0]) {
                    toast.success("Session created successfully");                    
                    navigator('/session/' + response.data[0].ID);
                }
            })
            .catch(err => {
                toast.error("Something went wrong");
            })
    }

    async function unauthorizedBookingConfirmHandler() {
        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(currentUserEmail)) {
            toast.error("Invalid Email.");
            return;
        }
        await getRequest('/api/data/gig_unauthorized_request', {
            GigID: gigID,
            Email: currentUserEmail
        })
            .then(response => {
                toast.success("Email sent.");
                setBookConfirmation(false);
            })
            .catch(error => {
                setBookConfirmation(false);
            })
    }

    async function bookingConfirmHandler(e) {
        e.preventDefault();
        if (!currentUser?.Source) {
            unauthorizedBookingConfirmHandler();
        }
        else {
            authorizedBookingConfirmHandler();
        }
    }

    const renderDay = (day) => {
        if (!day) return;
        const days = ['', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return <>
            <DaysSelector data={{ AvailableDay: day }} className="gig-page-avaibalility" setAvailabilityDayFnc={() => {}} />
        </>
    }

    // online status of consultant
    useEffect(() => {
        if (data.ConsultantID)
            setConsultantActive(onlineClients.includes('consultant-' + data.ConsultantID));
    }, [onlineClients, data]);


    const renderTime = (time) => {
        if (!time) return;
        const renderTimePart1 = (time1) => {
            const hours = time1.substr(0, 2)
            const minutes = time1.substr(2)
            // return parseInt(hours)*60 + parseInt(minutes);
            return `${hours % 12 || 12}:${minutes}${hours >= 12 ? 'pm' : 'am'}`;
        }
        const time1 = renderTimePart1(time.substr(0, 4)), time2 = renderTimePart1(time.substr(4));
        return <div className="gig-page-available-time-container">
            <div className="time-picker-container-gig">{renderTimePart1(time.substr(0, 4))}</div> - <div className="time-picker-container-gig">{renderTimePart1(time.substr(4))}</div>
        </div>
        // return <div className="gig-page-available-time-container">
        //     <TimePicker
        //         className={{
        //             container: 'time-picker-container-gig',
        //             section: 'time-picker-section-session',
        //             header: 'time-picker-header-session',
        //         }}
        //         time={time1}
        //         disabled
        //     />
        //     -
        //     <TimePicker
        //         className={{
        //             container: 'time-picker-container-gig',
        //             section: 'time-picker-section-session',
        //             header: 'time-picker-header-session',
        //         }}
        //         time={time2}
        //         disabled
        //     />
        // </div>
    }

    const clickInstantBooking = async (e) => {
        e.preventDefault();
        const time = (await (new GTime()).getTime()) + 5 * 60 * 1000;
        setDate(time);
        const tempTime = new Date(time);
        setTime(tempTime.getHours() * 60 + tempTime.getMinutes());
    }

    const bookButtonHandler = async (e) => {
        e.preventDefault();
        if (currentUser?.Source === 'consultant') {
            toast.error("Consultants are not allowed to book a gig");
            return;
        }
        else if (currentUser?.Source === 'enterprise') {
            toast.error("Enterprises are not allowed to book a gig");
            return;
        }

        // if (!currentUser?.Source) {
        //     navigator('/login');
        //     toast.error("Login required");
        //     return;
        // }
        // if (currentUser?.Source === 'user' ) {
            if (await validateTime())
                setBookConfirmation(true);
        // }
    }

    const toggleDetailsVisibility = (e) => {
        if (isScreenSmall) {
            if (!showDetails) {
                setShowDetails(true);
            } else {
                bookButtonHandler(e)
            }
        } else {
            bookButtonHandler(e)
        }
    };

    const toggleLikeChecked = (e) => {
        const currentState = !likedGig;
        setLikedButtonDisabled(true);
        if (currentState) {
            postRequest('/api/data/gig-like/add', { userID: currentUser.UID, gigID: gigID })
                .then(response => {
                    setLikedButtonDisabled(false);
                    toast.info("Liked a gig");
                })
                .catch(error => setLikedButtonDisabled(false));
        }
        else {
            postRequest('/api/data/gig-like/remove', { userID: currentUser.UID, gigID: gigID })
                .then(response => {
                    toast.info("Unliked a gig");
                    setLikedButtonDisabled(false);
                })
                .catch(error => setLikedButtonDisabled(false));
        }
        setLikedGig(currentState);
    }

    const gotoConsultant = (e) => {
        e.preventDefault();
        navigator(`/consultant/${data.CName.replace(' ', '-').toLowerCase()}-${encryptUrl(data.ConsultantID)}`);
    }

    if (!available || !data || Object.keys(data) === 0) {
        return (<NotFound />)
    }
    return (
        <>
            {isLoaded === true ? (<p >loading </p>) : (
                <>
                    <NavBar />
                    {/* <Link to={`/messaging/c/${data.consultantId}`} className="gig-inquire-button">inquire</Link> */}
                    <div className="additional-informations-wrapper">
                        <div className="additional-informations">
                            <div className="gig-consultant-info-div">
                                <div className={"gig-pfp-border " + (consultantActive ? 'active-client' : 'inactive-client')}>
                                    {/* Fetch Here */}
                                    <Link to={consultantLink} className="gig-pfp-consultant-linker">
                                        <img className="gig-pfp active-client" src={consultantPFP} alt="consultant-profile-pic" />
                                    </Link>
                                </div>
                                <div>
                                    <Link to={consultantLink} className="gig-consultant-name">{data?.CName}</Link>
                                    <Link to={`/enterprise/${data?.EName?.replace(' ', '-').toLowerCase()}-${encryptUrl(data.EnterpriseID)}`} className="gig-consultant-enterprise">{data?.EName}</Link>
                                </div>

                                {/* {data.Price !== 0 ?
                                    <h2 className="gig-price">Rs. {data.Price}</h2> :
                                    <h4 className="gig-price-free">Free</h4>
                                } */}
                            </div>
                            {isScreenSmall && showDetails && <FontAwesomeIcon className="gig-booking-close-icon" onClick={() => setShowDetails(!showDetails)} icon={faXmarkSquare} />}
                            {/* { data.rating===0 && <h1>no ratings yet</h1> }
                                    { data.rating===0 ||
                                        <Rating defaultValue={data.rating} precision={0.5} readOnly></Rating>
                                    }
                                    <h2>{data.rating || getStars(5)}</h2> */}
                            {showDateTimeInput && showDetails && (
                                <>
                                    <div className="gig-consultant-datetime-div">
                                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            {date ?
                                                <DatePicker
                                                onChange={(e) => setDate(e.unix() * 1000)}
                                                label="Enter Date"
                                                value={dayjs(date)}
                                                sx={{
                                                    my: 2,
                                                    color: 'blue',
                                                }}
                                                maxDate={dayjs().add(1, 'month')}
                                                onOpen={dateOpen}
                                                onMonthChange={dateOpen}
                                                    onYearChange={dateOpen}
                                                    disablePast
                                                    /> :
                                                    <DatePicker
                                                    onChange={(e) => setDate(e.unix() * 1000)}
                                                    label="Enter Date"
                                                    sx={{
                                                        my: 2,
                                                        color: 'blue'
                                                    }}
                                                    maxDate={dayjs().add(1, 'month')}
                                                    onOpen={dateOpen}
                                                    />
                                                }
                                        </LocalizationProvider> */}
                                        <DatePickerCustom data={data} defaultValue={date} setDateCallback={(e) => setDate(e)} />
                                        <TimePicker
                                            className={{
                                                container: 'time-picker-container-session',
                                                section: 'time-picker-section-session',
                                                header: 'time-picker-header-session',
                                            }}
                                            time={time}
                                            callBack={setTime}
                                            />
                                    </div>
                                </>
                            )}
                            {showDateTimeInput && (
                                <div className="gig-booking-button-wrapper">
                                    <button className="gig-instant-booking-button" onClick={isScreenSmall ? (e) => { clickInstantBooking(e); setShowDetails(!showDetails) } : clickInstantBooking}>{isScreenSmall ? <FontAwesomeIcon icon={faVideoCamera} /> : 'Instant Booking'}</button>
                                    <button onClick={toggleDetailsVisibility} className="gig-message-button">
                                        Book
                                    </button>
                                </div>
                            )}
                            {!showDateTimeInput && currentUser?.Source === 'user' &&
                                <Link className="gig-message-button gig-check-my-session-button" to={ '/session/' + ongoingSessionID }>Check My Session</Link>
                            }
                            {authorized && <button className="gig-pfp-edit-button" onClick={(e) => navigator('/gig/draft/' + gigID)}><FontAwesomeIcon icon={faPen} /></button>}
                        </div>
                    </div>
                    <div className="gig-main">
                        <div className="gig-megacontainer">
                            <div className="gig-container">
                                <div className="gig-informations">
                                    <h1 className="gig-title">{data.Title}</h1>
                                    <div className="gig-time-rating">
                                        <Rating value={data.Rating || 0} precision={0.5} readOnly></Rating>
                                        <p className="gig-time">{postedTime}</p>
                                    </div>
                                    { gigThumbnail ?
                                        <img className="gig-thumbnail" src={gigThumbnail} alt="thumbnail" />
                                        : <div className="gig-thumbnail image-preloader"></div>
                                    }
                                    <div className="gig-description-container">
                                        <div className="gig-about-header">
                                            <h2>About This Gig</h2>
                                            {
                                                currentUser.Source === "user" && 
                                                <button className="gig-like-button" onClick={toggleLikeChecked} disabled={likeButtonDisabled} style={{ color: likedGig ? '#0952B3' : 'gray' }}>
                                                    <FaThumbsUp />
                                                </button>
                                            }
                                        </div>
                                        <div className="gig-descriptions gig-description1">{parse(data.Description || "")}</div>
                                        <section>
                                            <h2>Availability</h2>
                                            <div className="gig-descriptions">
                                                <div><h3>Days</h3> {renderDay(data.AvailableDay)}</div>
                                                <div><h3>Schedule</h3><a href={`${consultantLink}/booking`}><h3>Check Schedule</h3></a></div>
                                                {/* <div><h3>Time</h3> {renderTime(data.AvailableTime)}</div> */}
                                            </div>
                                        </section>
                                    </div>
                                    <section>
                                        <h2>Prerequisite</h2>
                                        <div className="gig-descriptions">
                                            { preRequisites.map((prerequisite, index) => <div className="gig-prerequisite" key={index}>
                                                <p>{prerequisite}</p>
                                            </div>)}
                                        </div>
                                    </section>
                                </div>
                            </div>

                            <div className="gig-reviews">
                                <h2>Reviews</h2>
                                {
                                    reviews && reviews.map((review, index) =>
                                        <div className='review-block' key={index}>
                                            <img className="review-pfp" src={review.ProfilePic} alt="review-profile-pic" />
                                            <div>
                                                <p>{review.ConsultantName}</p>
                                                <p>{review.Name}</p>
                                                <Rating defaultValue={review.Rating} precision={0.5} className="gig-review-rating" readOnly></Rating>
                                                <p className="gig-review-text">{review.Review}</p>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    (!reviews || reviews.length) === 0 && <div className="empty-reviews-illustration">
                                        <img src={emptyReviewsIllustration} />
                                        <p>No reviews yet</p>
                                    </div>
                                }
                            </div>
                        </div>
                        {/* <div className="div-filler"></div> */}
                    </div>
                    {bookConfirmation && (
                        <>
                            <div className="background-overlay">
                            </div>
                            <Popup onClose={() => setBookConfirmation(false)} isOpen={bookConfirmation} className="gigpage-popup">
                                <button className="gig-cancel-button" onClick={() => setBookConfirmation(false)}>x</button>
                                <p>Do you want to confirm your booking?</p>
                                {
                                    currentUser.Source ? <></> :
                                    <div>
                                        <p>Enter your email</p>
                                        <input type='email' value={currentUserEmail} onChange={(e) => setCurrentUserEmail(e.target.value)} />
                                    </div>
                                }
                                <button
                                    className="gig-confirmation-button"
                                    onClick={bookingConfirmHandler}
                                >
                                    Confirm
                                </button>
                            </Popup>
                        </>
                    )}
                    <Footer showLinks={false}></Footer>
                </>
            )}
        </>
    )
}