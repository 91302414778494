import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

import './404page.css'

// import NavigBar from "./navbar/nav";

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import gif from '../assets/404Error.gif';
import Button from '@mui/material/Button';
// import Footer from "../footer/footer";

export default function NotFound() {
    const [isAuthorized, setIsAuthorized] = useState(true);

    // useEffect(() => {
    //     const auth = firebase.auth().onAuthStateChanged(setAuth);
    //     function setAuth(user) {
    //         if (user === null) {
    //             setIsAuthorized(false);
    //         } else {
    //             setIsAuthorized(true);
    //         }
    //     }
    // }, [])

    return (
        // Not attributed yet
        <>
            {/* <NavigBar /> */}
            <Stack
                direction={{
                    xs: 'column',
                    sm: 'column',
                    md: 'row'
                }}
                alignItems='center'
                justifyContent='space-evenly'
                paddingTop={1}
                paddingX={{
                    xs: 3,
                    md: 4
                }}
                sx={{
                    width: '100%',
                    height: '78.2%'
                }}
            >
                <Box sx={{
                    zIndex: '-2',
                }}>
                    <img className="gif" src={gif} alt="error-gif" />
                </Box>
                <Box>
                    <Box sx={{
                        mb: 2,
                        fontSize: {
                            xs: 19,
                            sm: 19,
                            md: 28
                        },
                        color: '#0952b3',
                        textShadow: '0px 4px 4px rgba(0, 0, 0, 0.10)',
                    }}
                    >
                        Oops! You ventured into space</Box>
                    <Box sx={{
                        mb: {
                            xs: 1,
                            sm: 2,
                            md: 4
                        },
                        fontSize: {
                            xs: 14,
                            sm: 14,
                            md: 18
                        },
                        opacity: '70%'
                    }}>We couldn't find the resource you were looking for</Box>
                    <Box sx={{
                        mb: {
                            sm: 1,
                            md: 2
                        },
                        fontSize: {
                            xs: 15,
                            sm: 18,
                            md: 20
                        },
                        color: '#0952b3'
                    }}>Get back on track</Box>
                    <Stack direction='row'>
                        <Link to="/">
                            <Button
                                variant="outlined"
                                sx={{
                                    mt: 1,
                                    border: '2px solid #0952b3',
                                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.10)',
                                    fontWeight: 800,
                                    color: '#0952b3',
                                    opacity: '80%',
                                    '&:hover': { backgroundColor: '#0952b3', color: 'white' }
                                }}
                            >Home</Button>
                        </Link>
                        {isAuthorized || <Link to='/register'>
                            <Button
                                variant="outlined"
                                sx={{
                                    m: 1,
                                    border: '2px solid #0952b3',
                                    fontWeight: 800,
                                    color: '#0952b3',
                                    opacity: '80%',
                                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.10)',
                                    '&:hover': { backgroundColor: '#0952b3', color: 'white' }
                                }}
                            >Sign In</Button>
                        </Link>}
                    </Stack>
                </Box>
            </Stack >
            {/* <Footer /> */}
        </>
    )
}