import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

// import { DecryptPhoneNumber, EncryptPhoneNumber } from '../../decrypters';

// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';

// import { firebaseTools } from '../../../firebase1';

import './consultantProfile.css';
import '../profiles.css';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// import { FaPlus } from 'react-icons/fa';
import NotFound from '../../Error/404Page';
// import RenderThumbnail from '../../gigs/getThumbnail';
import RenderThumbnail from "../gig/thumbnail/getThumbnail";
// import Navbar from '../../navbar/nav';
import NavigBar from '../../components/navbar/navbar';
// import Preloader from '../../../preloader';
import { Rating } from '@mui/material';
import parse from 'html-react-parser';

// import SessionReviewTool from '../../../session/sessionReview';

import addIcon from '../../assets/add-icon.png';
import EmptyProfile from '../../assets/emptyPFP.jpg';
import pencil from '../../assets/pencil.png'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faEye } from '@fortawesome/free-solid-svg-icons'
import Footer from '../../components/footer/footer';
import { getRequest, postRequest } from '../../requestAndLocalStorage/requestAndLocalStorage';
import { decryptUrl, encryptUrl } from '../../tools';
import { firebaseTools } from '../../DataContext/firebase1';
import { useData } from '../../DataContext/DataContext';
import GetGigComponent from '../../components/SessionContainer/sessionContainer';
import Slider from '../user/components/slider';
import { toast } from 'react-toastify';
import Preloader from '../../components/Preloader/preloader';
import Popup from '../../components/Popup/popup';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import emptySessionsIllustration from "../../assets/illustrations/Sessions-Not-Taken-Indication.png"
// import ConstructionPopup from '../../../constructionPopup';
// import { useAuth } from '../../../AuthContext';


const ConsultantProfile = () => {
  const { encrypted } = useParams();   // encrypted text fetched from path
//   const [pathFirstName, pathLastName, pathPhoneNumber] = DecryptPhoneNumber(encrypted);

  const [profileDBRef, setProfileDBRef] = useState('');
  const [name, setName] = useState('');
  const [valid, setValid] = useState(true);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userImage, setUserImage] = useState('');
  const [coverImage, setCoverImage] = useState('');
  const [bio, setBio] = useState('');
  const [userDescription, setUserDescription] = useState('');
  const [userDescriptionEditable, setUserDescriptionEditable] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [error, setError] = useState('');
  const [enterprise, setEnterprise] = useState(false);
  const [profileID, setProfileID] = useState(0);

  const [tempProfile, setTempProfile] = useState();
  const profileInputRef = useRef();

  const [mobileView, setMobileView] = useState(false);
  const [gigsComponent, setGigsComponent] = useState(false);
  const [consultantRating, setConsultantRating] = useState(0);

  // authorized data & state
  const [authorizedData, setAuthorizedData] = useState(false);
  const [authorized, setAuthorized] = useState(true);
  const [isAuthorizedFB, setIsAuthorizedFB] = useState(true);

  // session data
  const [pendingSession, setPendingSession] = useState([]);
  const [pendingSessionContainer, setPendingSessionContainer] = useState(<></>);

  const [posts, setPosts] = useState([]);
  const [newPost, setNewPost] = useState("");

  const [draftGig, setDraftGig] = useState([]);

  const [profRef, setProfRef] = useState({})
  const views = useRef(null);

  const [isLoaded, setIsLoaded] = useState(false)

  const navigate = useNavigate();
  const [viewCount, setViewCount] = useState(0);

  const [sessionReviews, setSessionReviews] = useState([]);

  const { currentUser, onlineClients } = useData();
  const [onlineStatus, setOnlineStatus] = useState(false);



  const handlePostChange = (e) => {
    setNewPost(e.target.value);
  };

  // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= POST A GIG -=-=-=-=-=-=-=-=-=-=-=-=-=-= //
  const handleAddPost = async () => {
    postRequest('/api/auth/registration/gig', {
      Title: "I will",
      AvailableTime: "09001300",
      AvailableDay: "16",
      Price: "30",
      Description: "About this gig",
      ConsultantID: profileID
    })
      .then(response => {
        toast.success("Successfully created a new gig");
        getGigs(profileID, name);
      })
      .catch(error => {
        if (error.response.data==='duplicate entries') {
          toast.error('Cannot create duplicate gigs');
        }
        else {
          toast.error('Something went wrong');
        }
        console.error(error);
      })


    // const newGigID = await firebaseTools.buildGig(profileID);
    // console.log("successfully created new gig", profileID);
    // profileDBRef.ref.update({
    //   Gigs: firebase.firestore.FieldValue.arrayUnion(newGigID),
    // });

    // navigate(`./gig/draft/${newGigID}`);

  };
  // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-= //

  useEffect(() => {
    const decoded = decryptUrl(encrypted);
    if (!decoded) {
      setValid(false);
      setIsLoaded(true);
      return;
    }

    const name = [decoded[0], decoded[1]].join(' ');

    if (name.toLowerCase() === currentUser.Name?.toLowerCase() && decoded[2] === currentUser.UID) {
      setAuthorized(true);
    }
    else {
      setAuthorized(false);
    }


    getRequest(`/api/data/consultant`, { Name: name, ID: decoded[2] })
      .then((response) => {
        const consultantData = response.data[0];

        if (!consultantData) {
          setValid(false);
        }
        const gigLoader     = getGigs(consultantData.UID, name);
        const sessionLoader = getSessions(consultantData.UID);
        
        setName(name);
        setProfileID(consultantData.UID);
        setUserDescription(consultantData.Description);
        setEmail(consultantData.Email);
        setPhoneNumber(consultantData.PhoneNumber);
        
        // getting the image
        firebaseTools.getCONProfilePic(consultantData.UID)
        .then((url) => {
          if (typeof(url) !== 'string') {
            setUserImage(EmptyProfile);
            setTempProfile(EmptyProfile);
          }
          else {
            setUserImage(url);
            setTempProfile(url);
          }
          })
          .catch((error) => {
            setUserImage(EmptyProfile);
            setTempProfile(EmptyProfile);
          })
          
        getRequest('/api/data/consultant/reviews', { UID: consultantData.UID })
          .then(async (data) => {
            let rating = 0;
            for (const data_i of data.data) {
              data_i.ProfilePic = await firebaseTools.getUSRProfilePic(data_i.UserID);
              rating += data_i.Rating;
            }
            setConsultantRating(rating / data.data.length);
            setSessionReviews(data.data);
          })
          .catch((error) => {
            console.error(error);
          })
        Promise.all([gigLoader, sessionLoader]).then(()=>{
          setIsLoaded(true);
        })

        // getting enterprise
        if (consultantData.EnterpriseID) {
          getRequest('/api/data/enterprise/uid', { UID: consultantData.EnterpriseID })
            .then (async (response) => {
              if (response.data.length !== 1) return;
              response.data[0].ProfilePic = await firebaseTools.getENTProfilePic(consultantData.EnterpriseID);
              response.data[0].ID = consultantData.EnterpriseID;
              setEnterprise(response.data[0]);
            })
            .catch((error) => {
              console.error(error);
            });
          }
        }
      )
      .catch((error) => {
        setValid(false);
        console.error(error);
      })
  }, []);

  async function getSessions(profileID) {
    if (currentUser && currentUser.Source==='consultant' && currentUser.UID===profileID) {
      return await postRequest('/api/data/session/get_pending_consultant', { consultantID: currentUser.UID })
        .then(async response => {
          for(let i=0; i<response.data.length; i++) {
            response.data[i].ProfilePic = await firebaseTools.getGIGProfilePic(response.data[i].ID);
          }
          setPendingSession(response.data);
        })
        .catch(error => {
          console.error(error);
        })
    }
  }

  async function getGigs(UID, name) {
    if (!UID) return;

    // getGigs
    getRequest('/api/data/gigs', { ConsultantID: UID })
      .then(response => {
        response.data.forEach(gig => {
          gig.CName = name
          gig.ConsultantID = UID
        });
        setDraftGig(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // lister for window size
  useEffect(() => {
    window.addEventListener("resize", checkScreenWidth);
    checkScreenWidth();

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    }
  }, []);

  // upload profile pic
  const handleImageUpload = async (e) => {
    try {
      const file = e.target.files[0];
      if (file) {
        if (file.size > 524880) {
          toast.error("File size too large.")
        }
        else {
          setTempProfile(URL.createObjectURL(file));
        }
      }
    }
    catch (err) {

    }
  };
  const saveDescription = async (e) => {
    setUserDescriptionEditable(false);
    postRequest('/api/data/consultant/update_description', { Description: userDescription, UID: profileID })
      .then((response) => {
        toast.success('Successfully Updated Description.');
      })
      .catch((err) => {
        toast.error("Something went wrong.");
      })
  }

  const confirmClick = async (e) => {
    if (!profileInputRef.current) return;
    if (!profileInputRef.current.value) return;
    
    const file = profileInputRef.current.files[0];
    if (file) {
      if (file.size > 5242880) {
        setError('Please select an image file with a maximum size of 5 MB.');
        console.log('Please select an image file with a maximum size of 5 MB.');
        return;
      }

      // setUserImage(URL.createObjectURL(file));
      let url = await firebaseTools.setCONProfilePic(profileID, file);
      setShowPopup(false);
      setUserImage(url);
    }
  }

  const cancelClick = async (e) => {
    setTempProfile(userImage);
    setShowPopup(false);
  }

  function checkScreenWidth() {
    if (window.innerWidth < 768) {
      setMobileView(false);
    }
    else setMobileView(true);
  }

  useEffect(() => {
    setGigsComponent(<GigsContent></GigsContent>)
  }, [draftGig, enterprise, pendingSession])


  useEffect(() => {
    // setConsultantRating((sessionReviews.reduce((prev, val) => prev + val.Rating, 0)) / sessionReviews.length)
  }, [pendingSession])

  
  // online status of consultant
  useEffect(() => {
    setOnlineStatus(onlineClients.includes('consultant-'+profileID))
  }, [onlineClients, profileID]);



  function GigsContent() {
    return (
      <div className='gigs-content right-col'>
        {/* <div className='summary-container'>
          <div className='summary-section'>
            <h3 className='header'>Total Revenue</h3>
            <span className='total-revenue-span'>Rs. 15600.00</span><span className='revenue-span'> Rs. 1024.55 (Today)</span>
          </div>
          <div className='summary-section-bubble'>
            <h3 className='bubble-header'>Total Sessions</h3>
            <span className='bubble-span'>32</span>
          </div>
          <div className='summary-section-bubble'>
            <h3 className='bubble-header'>Scheduled Sessions</h3>
            <span className='bubble-span'>12</span>
          </div>
        </div> */}
        {authorized && <>
          <div>
            <h2>Pending Sessions</h2>
            <div className='consultant-session-list'>
              <div className='recent-sessions'>
                <div className='sessions-container'>
                  {
                    (pendingSession && pendingSession.length > 0) ? 
                      <Slider>
                        {pendingSession && pendingSession.map((gig, index) =>
                          <GetGigComponent gig={gig} key={index} handleSessionClick={(gig) => navigate('/session/'+gig.SID)}></GetGigComponent>
                        )}
                      </Slider>
                    :
                      <div className='empty-session-container'>
                        <img src={emptySessionsIllustration} />
                        <p>No sessions yet</p>
                      </div>
                  }
                </div>
              </div> 
            {/* ) : <></>} */}
              {/* {pendingSession.map((post, index) =>  
                <RenderThumbnail
                  key={index}
                  data={{ ...post, OwnerImageURL: enterprise.ProfilePic }}
                  showENT={true}
                  showPosted={false}
                  showDraft={true}
                ></RenderThumbnail>
              )} */}
            </div>
          </div>
          
          <div>
            <h2>Draft Gig</h2>
            <div className='gigs-list'>
              {draftGig.filter(post=>post.Status==='0').map((post, index) =>  
                <RenderThumbnail
                  key={index}
                  data={{ ...post, OwnerImageURL: enterprise.ProfilePic }}
                  draftGig
                ></RenderThumbnail>
              )}
            </div>
          </div>
        </> 
        }
        <div className='column contents'>
          <h2>Posted </h2>

          <div className='gigs-list'>
            {authorized &&
              <Link className='gigs-column' onClick={handleAddPost}>
                <div className='post-form'>
                  <div className='add-icon'>
                    <img src={addIcon} alt='Add' />
                  </div>
                  <p>Add </p>
                </div>
              </Link>
            }
            {/* {posts.map((post, index) => ( */}
              {draftGig.filter(post => post.Status==='1').map((post, index) =>  
                <RenderThumbnail
                  key={index}
                  data={{ ...post, OwnerImageURL: enterprise.ProfilePic }}
                  showENT={true}
                  showPosted={false}
                  showDraft={true}
                  editView={authorized}
                ></RenderThumbnail>
              )}
            {/* //   <RenderThumbnail
            //     key={index}
            //     data={enterprise.ProfilePic ?
            //       { ...post, OwnerImageURL: enterprise.ProfilePic } :
            //       { ...post, OwnerImageURL: userImage }
            //     }
            //     showENT={!!enterprise.FirstName}
            //     showPosted={true}
            //     showDraft={false}
            //     editView={authorized}
            //   ></RenderThumbnail>
            ))} */}


          </div>
        </div>
      </div>
    )
  }


  if (!isLoaded) {
    return <Preloader />
  }

  if (!valid) {
    // if (isLoaded) return (<Preloader />);
    return (<NotFound></NotFound>)
  }


  return (
    <>
      <NavigBar></NavigBar>
      <div className='row'>
        {/* first container */}
        <div className='col'>
          <div className='prof-container1'>
            <div className={`consultant-${(onlineStatus ? 'online' : 'offline')}-status`}>
              { onlineStatus ? <>
                  online
                </>: <>
                  offline
                </>
              }
            </div>
            <div className={"profile-image " + (onlineStatus ? 'active-client' : 'inactive-client')}>
              <img
                className='profile'
                src={userImage}
                alt="User"
                onClick={() => authorized && setShowPopup(true)}
                style={{ cursor: 'pointer' }}
              />
            </div>
            {showPopup && authorized && (
              <Popup isOpen={showPopup} onClose={ cancelClick }>
                <div className="upload-popup">
                  <h3 className='upload-popup-label'>Upload Profile Picture</h3>
                  <div className='upload-popup-container'>
                    <label htmlFor='profile-input' className='profile-upload-label'>
                      <img src={tempProfile} />
                    </label>
                    <input
                      id='profile-input'
                      type="file"
                      onChange={handleImageUpload}
                      accept="image/*"
                      ref={ profileInputRef }
                    />
                    <div className='profile-input-button-container'>
                      { userImage === tempProfile ?
                         <label htmlFor='profile-input' className='upload-profile-pic-button'>Upload Profile Picture</label>
                        :<button onClick={ confirmClick }>Save Profile Picture</button>
                      }
                    </div>
                    <button className="gig-cancel-button" onClick={ cancelClick  }>x</button>
                  </div>
                </div>
              </Popup>
              // <div className'
            )}
            {showPopup && authorized && (
              <div className='background-overlay'></div>
            )}

            <div className="username">
              <p>{name}</p>
            </div>
            {/* <div className='bio'>Bio:</div> */}
            <div className='rating'>
              {/* { sessionReviews.reduce((val)=>val.Review, 0) } */}
              <Rating rerender={sessionReviews} value={consultantRating} precision={0.5} readOnly></Rating>
              <p>{sessionReviews.length} Reviews</p>
            </div>
            <div className="joined-active-recommended">
              {/* <p>Joined since: {signupDate.toDateString()}</p>
            <p>Active: {user ? 'Yes' : 'No'}</p> */}
              {enterprise.Name &&
                <>
                  Works In: <Link className='link' to={enterprise.ID ? `/enterprise/${enterprise.Name.replace(' ', '-').toLowerCase()}-${encryptUrl(enterprise.ID)}` : "#"}>
                    <div className='ent-cons-ref'><img src={enterprise.ProfilePic} width="20px" height="20px" /><p>{enterprise.Name}</p></div>
                  </Link>
                </>
              }

            </div>
            <div className="description">
              <div className='desc'>
                <h3>Description</h3>
                {
                  authorized && 
                  <div className='description-icon'>
                    { userDescriptionEditable ? 
                      <FontAwesomeIcon icon={faFloppyDisk} onClick={saveDescription} />
                      :<img src={pencil} alt='' onClick={ () => setUserDescriptionEditable(true) }/>
                    }
                  </div>
                }
              </div>
              { userDescriptionEditable ? 
                <ReactQuill className="consultant-description" value={userDescription} onChange={setUserDescription} />
                : <div className='consultant-description-rendered'>{ parse(userDescription) }</div>
              }
            </div>
            <div className="description">
              <Link to={`/consultant/${encrypted}/booking`} className='consultant-booking-button'>Book Session</Link>
            </div>
            {/* {authorized || <Link className="get-in-touch" to={`/messaging/c/${profileID}`}>Send Message</Link>} */}
          </div>
          {mobileView || gigsComponent}
          <div className=''>
            <h3 className='review-title'>Reviews</h3>
            {
              sessionReviews && sessionReviews.map((review, index) =>
                <div className='review-block' key={index}>
                  <img src={review.ProfilePic} />
                  <div>

                    <p className='review-name'>{review.UName}</p>
                    <Rating defaultValue={review.Rating} readOnly></Rating>
                    <p>{review.Review}</p>
                  </div>
                </div>
              )
            }
            {
              (!sessionReviews || Object.keys(sessionReviews).length === 0) &&
              <p>No reviews yet</p>
            }
          </div>
        </div>
        {mobileView && gigsComponent}
      </div>

      {/* {authorized || <button className="get-in-touch">Get in touch</button>} */}
      {/* {authorized && <Link className="get-in-touch" to="./dashboard">Dashboard</Link>} */}
      <Footer showLinks={!isAuthorizedFB || authorizedData.UserType === "user"}></Footer>
      {/* <ConstructionPopup></ConstructionPopup> */}
    </>
  );

}
export default ConsultantProfile;