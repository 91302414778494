import { DatePicker, LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

export default function Calendar({ markers, dateChanged, staticCalendar }) {
    useEffect(() => {
        markerUpdate();
        dateChanged(new Date());
    }, [markers])

    const markerUpdate = () => {
        setTimeout(() => {
            if (!markers || markers.length === 0) return;
            for (let marker of markers) {
                const date = new Date(parseInt(marker));
                date.setHours(0); date.setMinutes(0); date.setSeconds(0); date.setMilliseconds(0);
                const calendarObj = document.querySelector(`[data-timestamp="${date.getTime()}"]`)
                if (calendarObj) {
                    calendarObj.classList.add('calendar-session-indicator');
                }
            }
        }, 10);
    }

    return  <LocalizationProvider dateAdapter={AdapterDayjs}>
        { staticCalendar ? 
            <StaticDatePicker
                onMonthChange={markerUpdate} 
                onYearChange={markerUpdate} 
                className="booking-calendar"
                onChange={(e) => {
                    dateChanged(new Date(e))
                    markerUpdate()
                }}
                defaultValue={dayjs(new Date())}
                disablePast
                orientation="portrait"
                />:
                <DatePicker
                onOpen={markerUpdate}
                onMonthChange={markerUpdate} 
                onYearChange={markerUpdate} 
                className="booking-calendar"
                onChange={(e) => {
                    dateChanged(new Date(e))
                    markerUpdate()
                }}
                defaultValue={dayjs(new Date())}
                orientation="portrait"
                disablePast
            />
        }
    </LocalizationProvider>
}