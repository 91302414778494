import { useEffect, useRef, useState } from "react"
import SetupPage from "./components/setupPage";
// import ScrollSelectPage from "../../scrollSelect/scrollSelectPage";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import "./thirdPage.css"
import dayjs from "dayjs";
import { Hidden } from "@mui/material";
import DaysSelector from "../../../components/daysSelect/daysSelect";

export default function GigSetupFourthPage(props) {
    const time = useRef();
    const day = useRef();

    const ThirdPageList = {
        AvailableTime: ["", ""],
        AvailableDay: ["", ""],
    }

    useEffect(() => {
        ThirdPageList.AvailableTime[0] = props.data.AvailableTime[0];
        ThirdPageList.AvailableTime[1] = props.data.AvailableTime[1];

        ThirdPageList.AvailableDay[0] = props.data.AvailableDay[0];
        ThirdPageList.AvailableDay[1] = props.data.AvailableDay[1];
    }, [props.AvailableTime, props.AvailableDay]);

    function breakTime(time) {
        if (!time) return undefined;
        var [hrs, mins] = time.split(":");
        return [parseInt(hrs), parseInt(mins[0]), parseInt(mins[1])]
    }

    function setAvailabilityTimeFnc(val) {
        let arr = ["", ""];
        arr[0] = `${val[0]}:${val[1]}${val[2]}`;
        arr[1] = `${val[3]}:${val[4]}${val[5]}`;
        ThirdPageList.AvailableTime[0] = arr[0];
        ThirdPageList.AvailableTime[1] = arr[1];
    }

    function setAvailabilityDayFnc(val) {
        ThirdPageList.AvailableDay[0] = val[0];
        ThirdPageList.AvailableDay[1] = val[1];
    }

    function manageTimeInput(time, index) {
        ThirdPageList.AvailableTime[index] = time["$H".padStart(2, '0')] + ":" + time["$m"];
    }

    function encodeTime(time) {
        return time.split(':').map((a,b)=>a.padStart(2,'0')).join('');
    }
    function nextPage(e) {
        e.preventDefault();
        if (ThirdPageList.AvailableTime[0] &&
            ThirdPageList.AvailableTime[1] &&
            ThirdPageList.AvailableDay[0] &&
            ThirdPageList.AvailableDay[1]) {  
                props.nextPage(e, {
                    AvailableDay: `${ThirdPageList.AvailableDay[0]}${ThirdPageList.AvailableDay[1]}`,
                    AvailableTime: encodeTime(ThirdPageList.AvailableTime[0]) + encodeTime(ThirdPageList.AvailableTime[1])
                });
        }
        else {
            alert("Please fill all the fields");
        }
    }

    function previousPage(e) {
        e.preventDefault();
        if (ThirdPageList.AvailableTime[0] &&
            ThirdPageList.AvailableTime[1] &&
            ThirdPageList.AvailableDay[0] &&
            ThirdPageList.AvailableDay[1]) {
                props.previousPage(e, {
                    AvailableDay: `${ThirdPageList.AvailableDay[0]}${ThirdPageList.AvailableDay[1]}`,
                    AvailableTime: encodeTime(ThirdPageList.AvailableTime[0]) + encodeTime(ThirdPageList.AvailableTime[1])
                });
        }
        else {
            alert("Please fill all the fields");
        }
    }

    return (
        <SetupPage next={nextPage} previous={previousPage} nextText={"Next"}>
            <div className="gig-form-row">
                <div className="gig-setup-get-available-time">
                    <label>Select Available Time</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['TimePicker']}>
                            <p className="from-label">From</p>
                            <TimePicker
                                sx={{
                                    overflow: "hidden",
                                }}
                                defaultValue={dayjs("0000-00-00T" + (props?.data?.AvailableTime ? (props.data.AvailableTime[0] || "07:00") : "07:00"))}
                                onChange={(e) => manageTimeInput(e, 0)} />
                        </DemoContainer>
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['TimePicker']}>
                            <p className="to-label">To</p>
                            <TimePicker
                                sx={{
                                    overflow: "hidden",
                                }}
                                defaultValue={dayjs("0000-00-00T" + (props?.data?.AvailableTime ? (props.data.AvailableTime[1] || "17:00") : "17:00"))}
                                onChange={(e) => manageTimeInput(e, 1)} />
                        </DemoContainer>
                    </LocalizationProvider>
                </div>
                <div className="gig-setup-get-available-date">
                    <label>Select Available Day</label>
                    <DaysSelector data={props.data} setAvailabilityDayFnc={setAvailabilityDayFnc} editable />
                </div>
            </div>
        </SetupPage>)
}