import { useState } from "react";
import { useParams } from "react-router-dom";
import { postRequest } from "../../requestAndLocalStorage/requestAndLocalStorage";

const EnterpriseInvitation = () => {
    const { token } = useParams();
    const [ error, setError ] = useState('');
    const [ buttonDisabled, setButtonDisabled ] = useState(false);


    const acceptInvitation = () => {
        setButtonDisabled(true);
        postRequest(`/api/data/enterprise/confirm_invitation?token=${token}&&confirm=true`)
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                setButtonDisabled(false);
                console.error(error);
            })
        }
        
        const rejectInvitation = () => {
            setButtonDisabled(true);
            postRequest(`/api/data/enterprise/confirm_invitation?token=${token}&&confirm=true`)
                .then(response => {
                    console.log(response);
                })
                .catch(error => {
                    setButtonDisabled(false);
                    console.error(error);
                })

    }


    return (<>
        {
            error && <p className="error">{error}</p>
        }
        You have been invited to join the enterprise, confirm?
        <button onClick={acceptInvitation} disabled={buttonDisabled}>yes</button>
        <button onClick={rejectInvitation} disabled={buttonDisabled}>no</button>
    </>)

}

export default EnterpriseInvitation;